import React, { Component } from "react";
import "./styles.css";
import AblationSiteDetailsPanel from "./AblationSiteDetailsPanel";
import AblationPlacementModeSelectionPanel from "./AblationPlacementModeSelectionPanel";
import AblationSiteManualPlacementPanel from "./AblationSiteManualPlacementPanel";
import AblationProfileSelectionPanel from "./AblationProfileSelectionPanel";
import AASPOptionsPanel from "./AASPOptionsPanel";
import AblationSiteListingPanel from "./AblationSiteListingPanel";
import { legacy_setAblationProfileHelper } from "./set_ablation_profile";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { setCurrentTreatmentPlan } from "../../CreateTPlan_1Upload/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TargetSetController from "../../__shared__/Viewers/Structures/Targets/TargetSetController";
import TargetSetModel from "../../__shared__/Viewers/Structures/Targets/TargetSetModel";
import MRIModel from "../../__shared__/Viewers/Structures/MRI/MRIModel";

export const ablationWorkflowPanelOptions = {
    ABLATION_PROFILE_SELECTION_PANEL: "ABLATION_PROFILE_SELECTION_PANEL",
    ABLATION_PLACEMENT_MODE_SELECTION_PANEL:
        "ABLATION_PLACEMENT_MODE_SELECTION_PANEL",
    ABLATION_SITE_DETAILS_PANEL: "ABLATION_SITE_DETAILS_PANEL",
    ABLATION_SITE_MANUAL_PLACEMENT_PANEL:
        "ABLATION_SITE_MANUAL_PLACEMENT_PANEL",
    ABLATION_SITE_LISTING_PANEL: "ABLATION_SITE_LISTING_PANEL",
    AASP_OPTIONS_PANEL: "AASP_OPTIONS_PANEL",
};

class AblationWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePanel:
                ablationWorkflowPanelOptions.ABLATION_PROFILE_SELECTION_PANEL,
            ablationProfile: null,
            ablationUnderEditDetails: null,
        };

        this.setActivePanel = this.setActivePanel.bind(this);
        this.setAblationProfile = this.setAblationProfile.bind(this);
        this.setAblationUnderEditDetails =
            this.setAblationUnderEditDetails.bind(this);
    }

    componentDidMount() {
        if (
            TreatmentPlan.hasTargets(
                this.props.currentTreatmentPlan.TreatmentPlan
            )
        ) {
            let uuid =
                this.props.currentTreatmentPlan.SavedData.AblationProfileUUID;

            this.setState({
                activePanel:
                    ablationWorkflowPanelOptions.ABLATION_SITE_LISTING_PANEL,
            });

            this.setAblationProfile(uuid);
        }
    }

    setActivePanel(panelOption) {
        this.setState({ activePanel: panelOption });
    }

    setAblationProfile(uuid) {
        legacy_setAblationProfileHelper(this, uuid).then((ablationProfile) => {
            this.setState({ ablationProfile: ablationProfile });
        });
    }

    setAblationUnderEditDetails(details) {
        this.setState({
            ablationUnderEditDetails: {
                adjustmentTargetID: details.adjustmentTargetID,
                adjustmentTargetIndex: details.adjustmentTargetIndex,
            },
        });
    }

    getPanel(activePanel) {
        switch (activePanel) {
            case ablationWorkflowPanelOptions.ABLATION_PROFILE_SELECTION_PANEL: {
                return (
                    <AblationProfileSelectionPanel
                        setAblationProfile={this.setAblationProfile}
                        onFinished={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_PLACEMENT_MODE_SELECTION_PANEL
                            );
                        }}
                    />
                );
            }
            case ablationWorkflowPanelOptions.ABLATION_PLACEMENT_MODE_SELECTION_PANEL: {
                return (
                    <AblationPlacementModeSelectionPanel
                        targetSetController={this.props.targetSetController}
                        targetSetModel={this.props.targetSetModel}
                        onClose={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_PROFILE_SELECTION_PANEL
                            );
                        }}
                        onAutomaticPlacementClick={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.AASP_OPTIONS_PANEL
                            );
                        }}
                        onManualPlacementClick={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_SITE_LISTING_PANEL
                            );
                        }}
                    />
                );
            }
            case ablationWorkflowPanelOptions.ABLATION_SITE_DETAILS_PANEL: {
                return (
                    <AblationSiteDetailsPanel
                        targetID={
                            this.state.ablationUnderEditDetails
                                .adjustmentTargetID
                        }
                        targetIndex={
                            this.state.ablationUnderEditDetails
                                .adjustmentTargetIndex
                        }
                        mriModel={this.props.mriModel}
                        targetSetController={this.props.targetSetController}
                        targetSetModel={this.props.targetSetModel}
                        changeTargetColorHandler={this.changeTargetColorHandler}
                        onClose={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_SITE_LISTING_PANEL
                            );
                        }}
                    />
                );
            }
            case ablationWorkflowPanelOptions.ABLATION_SITE_MANUAL_PLACEMENT_PANEL: {
                return (
                    <AblationSiteManualPlacementPanel
                        targetSetController={this.props.targetSetController}
                        targetSetModel={this.props.targetSetModel}
                        ablationProfile={this.state.ablationProfile}
                        onClose={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_SITE_LISTING_PANEL
                            );
                        }}
                    />
                );
            }
            case ablationWorkflowPanelOptions.ABLATION_SITE_LISTING_PANEL: {
                return (
                    <AblationSiteListingPanel
                        ablationSitesLoaded={this.props.ablationSitesLoaded}
                        targetSetController={this.props.targetSetController}
                        targetSetModel={this.props.targetSetModel}
                        onClose={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_PLACEMENT_MODE_SELECTION_PANEL
                            );
                        }}
                        onViewDetailsClick={(details) => {
                            this.setAblationUnderEditDetails(details);
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_SITE_DETAILS_PANEL
                            );
                        }}
                        onAddAblationSiteClick={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_SITE_MANUAL_PLACEMENT_PANEL
                            );
                        }}
                        setStep5OptionalTaskSatisfied={
                            this.props.setStep5OptionalTaskSatisfied
                        }
                    />
                );
            }
            case ablationWorkflowPanelOptions.AASP_OPTIONS_PANEL: {
                return (
                    <AASPOptionsPanel
                        targetSetController={this.props.targetSetController}
                        targetSetModel={this.props.targetSetModel}
                        onClose={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_PLACEMENT_MODE_SELECTION_PANEL
                            );
                        }}
                        onComplete={() => {
                            this.setActivePanel(
                                ablationWorkflowPanelOptions.ABLATION_SITE_LISTING_PANEL
                            );
                        }}
                        ablationProfile={this.state.ablationProfile}
                    />
                );
            }
            default:
                return null;
        }
    }

    render() {
        return this.getPanel(this.state.activePanel);
    }
}

AblationWorkflow.propTypes = {
    currentTreatmentPlan: PropTypes.object,
    mriModel: PropTypes.instanceOf(MRIModel),
    targetSetController: PropTypes.instanceOf(TargetSetController),
    targetSetModel: PropTypes.instanceOf(TargetSetModel),
    ablationSitesLoaded: PropTypes.bool,
    setStep5OptionalTaskSatisfied: PropTypes.func,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        planuuid: state.CreatePlanReducer.planuuid,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore once */
const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AblationWorkflow);
