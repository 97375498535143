import React, { Component } from "react";
import "./styles.css";
import { setUserData } from "../../Login/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { profileSettingsFields, profileEmailMessages } from "../constants";
import {
    EMPTY_STRING,
    MAX_EMAIL_LENGTH,
    imageSize,
    clientErrorMessages,
    SUCCESS,
} from "../../../constants";
import blankUser from "../../../assets/blank_user.svg";
import AHAlphaField from "../../__shared__/StyleGuide/AHAlphaField";
import AHButton from "../../__shared__/StyleGuide/AHButton";
import EditButton from "../../__shared__/EditButton";
import { getUploadProfilePictureURL } from "../helpers";
import { putFileS3XML } from "../../CreateTPlan_1Upload/helpers";
import { rootStore } from "../../../redux/store";
import { setErrorState } from "../../../redux/error_banner/actions";
import { getUser, updateUser } from "../../Login/helpers";
import { isEmpty } from "../../../helpers/helpers";
import PopupBar from "../../__shared__/PopupBar";

class ProfileInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newUsername: EMPTY_STRING,
            showNewUsernameTextField: false,
        };
        this.fileInputRef = React.createRef();
        this.toggleNewUsernameEdit = this.toggleNewUsernameEdit.bind(this);
        this.handleNewUsernameSubmit = this.handleNewUsernameSubmit.bind(this);
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getAccountInfoPanel = this.getAccountInfoPanel.bind(this);
    }

    toggleNewUsernameEdit() {
        if (this.state.showNewUsernameTextField) {
            this.setState({
                newUsername: EMPTY_STRING,
            });
        }
        this.setState({
            showNewUsernameTextField: !this.state.showNewUsernameTextField,
        });
    }

    handleNewUsernameSubmit() {
        if (this.state.newUsername !== EMPTY_STRING) {
            let updatedProfile = this.props.userData;
            let currentUsername = updatedProfile.email;
            updatedProfile.email = this.state.newUsername;

            updateUser(
                this.props.userData.username,
                this.props.authToken,
                updatedProfile
            )
                .then((payload) => {
                    if (!(!isEmpty(payload) && payload.status === SUCCESS)) {
                        updatedProfile.email = currentUsername;
                        this.props.setUserData(updatedProfile);
                        if (isEmpty(payload)) {
                            throw new Error(
                                clientErrorMessages.FAILED_TO_UPDATE_USER
                            );
                        }
                    } else {
                        updatedProfile.username = this.state.newUsername;
                        this.props.setUserData(updatedProfile);
                        this.setState({
                            newUsername: EMPTY_STRING,
                        });
                        this.toggleNewUsernameEdit();
                    }
                })
                .catch((e) => {
                    this.setState({
                        newUsername: EMPTY_STRING,
                    });
                    rootStore.dispatch(setErrorState(true, String(e)));
                });
        } else {
            rootStore.dispatch(
                setErrorState(true, profileEmailMessages.EMPTY_STRING)
            );
        }
    }

    openFileDialog() {
        this.fileInputRef.current.click();
    }

    onChange(label, value) {
        const files = value.files;
        const scope = this;
        const userData = this.props.userData;
        const authToken = this.props.authToken;

        switch (label) {
            case profileSettingsFields.PROFILE_PICTURE: {
                if (files.length > 0) {
                    let imageFile = files[0];
                    if (imageFile.size < imageSize.PROFILE_PIC_MIN) {
                        this.setState({
                            popup: clientErrorMessages.PROFILE_PICTURE_TOO_SMALL,
                        });
                        return;
                    }

                    if (imageFile.size > imageSize.PROFILE_PIC_MAX) {
                        this.setState({
                            popup: clientErrorMessages.PROFILE_PICTURE_TOO_LARGE,
                        });
                        return;
                    }

                    getUploadProfilePictureURL({
                        userUuid: userData.uuid,
                        imageType: imageFile.type,
                        imageSize: imageFile.size,
                        authToken: authToken,
                    })
                        .then((res) => {
                            putFileS3XML(
                                res.signedurl,
                                imageFile,
                                null,
                                true
                            ).then(() => {
                                getUser(userData.username, authToken).then(
                                    (payload) => {
                                        if (
                                            !isEmpty(payload) &&
                                            payload.status === SUCCESS
                                        ) {
                                            let updatedProfile = payload.user;
                                            updatedProfile.profilePictureUrl =
                                                payload.user.profilePictureUrl +
                                                "?" +
                                                Date.now();
                                            scope.props.setUserData(
                                                updatedProfile
                                            );
                                        } else {
                                            throw new Error(
                                                clientErrorMessages.FAILED_TO_UPDATE_USER
                                            );
                                        }
                                    }
                                );
                            });
                        })
                        .catch(() => {
                            rootStore.dispatch(
                                setErrorState(
                                    true,
                                    clientErrorMessages.PROFILE_PICTURE_UPLOAD_FAILED
                                )
                            );
                        });
                }
                break;
            }
            case profileSettingsFields.USERNAME: {
                this.setState({
                    newUsername: value,
                });
                break;
            }
            default:
                break;
        }
    }

    getAccountInfoPanel(userData) {
        let profilePicUrl = this.props.userData.profilePictureUrl
            ? this.props.userData.profilePictureUrl
            : blankUser;

        return (
            <div id="account-info-container">
                {this.state.popup && (
                    <PopupBar
                        text={this.state.popup}
                        closePopup={this.closePopup}
                    />
                )}
                <div
                    className="account-info-child-container"
                    id="top-bio-container"
                >
                    <div id="profile-picture-container">
                        <button
                            id="profile-pic-ul-btn"
                            className="profile-image-upload-button"
                            onClick={this.openFileDialog}
                        >
                            <img
                                src={profilePicUrl}
                                className="profile-image"
                                alt="profile"
                            />
                        </button>
                        <input
                            id="input-styles"
                            ref={this.fileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={(event) =>
                                this.onChange(
                                    profileSettingsFields.PROFILE_PICTURE,
                                    event.target
                                )
                            }
                        />
                    </div>
                    <div id="top-bio-container-user-info">
                        <div>Full Name:</div>
                        <div>
                            {userData &&
                                `${userData.firstName} ${userData.lastName}`}
                        </div>
                        <div>Institutional Affiliation:</div>
                        <div>
                            {userData != null && userData.institution
                                ? userData.institution
                                : "N/A"}
                        </div>
                        <div>Email:</div>
                        {this.state.showNewUsernameTextField ? (
                            <div id="top-bio-container-change-email-container">
                                <AHAlphaField
                                    label={profileSettingsFields.USERNAME}
                                    value={this.state.newUsername}
                                    placeholder={"New Email"}
                                    onChange={this.onChange}
                                    type={"text"}
                                    maxLength={MAX_EMAIL_LENGTH}
                                    showLabel={false}
                                    className="top-bio-container-new-email"
                                    id="profile-info-new-email-field"
                                />
                                <AHButton
                                    isOutline={false}
                                    isSecondary={true}
                                    isDynamic={false}
                                    text={"Cancel"}
                                    onClick={this.toggleNewUsernameEdit}
                                    id="top-bio-container-cancel"
                                />
                                <AHButton
                                    isOutline={false}
                                    isSecondary={false}
                                    isDynamic={false}
                                    text={"Submit"}
                                    onClick={this.handleNewUsernameSubmit}
                                    id="top-bio-container-submit"
                                />
                            </div>
                        ) : (
                            <div className="flex items-center">
                                {userData != null && userData.email}
                                <EditButton
                                    className="mt-1"
                                    alt="Edit"
                                    onClick={this.toggleNewUsernameEdit}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="account-info-child-container">
                    <div id="account-info-uuid-header">Unique User ID:</div>
                    <div className="user-select-text" id="account-info-uuid">
                        {userData.uuid}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const userData = this.props.userData;

        return this.getAccountInfoPanel(userData);
    }
}

ProfileInfoPage.propTypes = {
    userData: PropTypes.object,
    authToken: PropTypes.string,
    setUserData: PropTypes.func,
};

ProfileInfoPage.defaultProps = {
    userData: {},
    authToken: EMPTY_STRING,
    setUserData: () => {},
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setUserData: (userData) => dispatch(setUserData(userData)),
    };
};

export default connect(null, mapDispatchToProps)(ProfileInfoPage);
