import * as TreatmentPlan from "./tplan/tplan";
import * as THREE from "three";
import { getBoundingCone, getPHISignedURLS3 } from "./backend_api";
import {
    clientErrorMessages,
    EMPTY_STRING,
    pageLoadStates,
    refFrames,
    sceneOrientations,
    structureColorPalette,
    volumeNames,
} from "../constants";
import StructureModel from "../components/__shared__/Viewers/Structures/PatientAnatomy/StructureModel";
import { rootStore } from "../redux/store";
import { setErrorState } from "../redux/error_banner/actions";
import { getMRIURLsHelper } from "./threejs_helpers";
import BiopsyCoreModel from "../components/__shared__/Viewers/Structures/PatientAnatomy/BiopsyCoreModel";
import BoundingCone from "../components/__shared__/Viewers/Structures/Other/BoundingCone";
import ProstateAxes from "../components/__shared__/Viewers/Structures/Other/ProstateAxes";
import {
    legacy_setAblationProfileHelper,
    setAblationProfileHelper,
} from "../components/CreateTPlan_4EditMargin/AblationWorkflow/set_ablation_profile";
import {
    CUSTOM_TARGET_NAMES,
    LC_MESH_NAMES,
} from "../components/__shared__/Viewers/Structures/CustomTargets/consts";
import { getSignedUrl, toArray } from "./helpers";
import ProstateModel from "../components/__shared__/Viewers/Structures/PatientAnatomy/ProstateModel";
import ROIModel from "../components/__shared__/Viewers/Structures/PatientAnatomy/ROIModel";
import { DEFAULT_TICK_PERCENT } from "../components/__shared__/SliceControl/consts";

export function legacy_loadProstate(
    scope,
    plan,
    ids,
    createBoundingCone,
    createAxes
) {
    let tplan = plan.TreatmentPlan;

    if (TreatmentPlan.hasCoreStructure(tplan)) {
        return getPHISignedURLS3({
            ...ids,
            uris: toArray(
                TreatmentPlan.getStructureInFrame(
                    TreatmentPlan.getCoreStructure(tplan),
                    refFrames.MRI
                ).URI
            ),
        })
            .then((payload) => payload.json())
            .then((json) => {
                let url = getSignedUrl(json.payload.signedurls);
                let prostateStructure = new ProstateModel();

                prostateStructure.mriViewer =
                    scope.patientAnatomyModel.mriViewer;
                prostateStructure.meshViewer =
                    scope.patientAnatomyModel.meshViewer;

                prostateStructure
                    .initializeView(url, {
                        name: volumeNames.MR_PROSTATE,
                        color: structureColorPalette.PROSTATE,
                        opacity: 0.4,
                        visibility: true,
                    })
                    .then(() => {
                        scope.patientAnatomyModel.prostate = prostateStructure;

                        if (createBoundingCone) {
                            legacy_loadBoundingCone(scope, plan);
                        }
                        if (createAxes) {
                            let prostateAxes = new ProstateAxes();
                            prostateStructure.initProstateAxes(prostateAxes);
                        }
                    });
            })
            .catch(() => {
                scope.setState({ loadState: pageLoadStates.FAILED });
            });
    }
}

export function loadProstate({
    plan,
    ids,
    patientAnatomyModel,
    targetSetController,
    createBoundingCone,
    createAxes,
    setLoadState,
}) {
    const tPlan = plan.TreatmentPlan;
    if (TreatmentPlan.hasCoreStructure(tPlan)) {
        return getPHISignedURLS3({
            ...ids,
            uris: toArray(
                TreatmentPlan.getStructureInFrame(
                    TreatmentPlan.getCoreStructure(tPlan),
                    refFrames.MRI
                ).URI
            ),
        })
            .then((payload) => payload.json())
            .then((json) => {
                const url = getSignedUrl(json.payload.signedurls);
                const prostateStructure = new ProstateModel();

                prostateStructure.mriViewer = patientAnatomyModel.mriViewer;
                prostateStructure.meshViewer = patientAnatomyModel.meshViewer;
                prostateStructure
                    .initializeView(url, {
                        name: volumeNames.MR_PROSTATE,
                        color: structureColorPalette.PROSTATE,
                        opacity: 0.4,
                        visibility: true,
                    })
                    .then(() => {
                        patientAnatomyModel.prostate = prostateStructure;
                        if (createBoundingCone) {
                            loadBoundingCone({
                                plan,
                                patientAnatomyModel,
                                targetSetController,
                            });
                        }
                        if (createAxes) {
                            const prostateAxes = new ProstateAxes();
                            prostateStructure.initProstateAxes(prostateAxes);
                        }
                    });
            })
            .catch(() => {
                setLoadState(pageLoadStates.FAILED);
            });
    }
}

export function loadAllProstateSegmentations(
    scope,
    plan,
    ids,
    createAxes,
    onLoadCallback = undefined
) {
    let tplan = plan.TreatmentPlan;
    let saveData = plan.SavedData;

    let patientAnatomyModel;
    if (scope.patientAnatomyModel) {
        patientAnatomyModel = scope.patientAnatomyModel;
    } else if (scope.props.patientAnatomyModel) {
        patientAnatomyModel = scope.props.patientAnatomyModel;
    }

    if (TreatmentPlan.hasCoreStructure(tplan)) {
        let aiSegmentationURI;
        if (
            saveData.AIEditedProstateURI &&
            saveData.AIEditedProstateURI !== EMPTY_STRING
        ) {
            aiSegmentationURI = saveData.AIEditedProstateURI;
        } else if (
            saveData.AIProstateURI &&
            saveData.AIProstateURI !== EMPTY_STRING
        ) {
            aiSegmentationURI = saveData.AIProstateURI;
        }

        let uploadedSegmentationURI;
        if (
            saveData.UploadedEditedProstateURI &&
            saveData.UploadedEditedProstateURI !== EMPTY_STRING
        ) {
            uploadedSegmentationURI = saveData.UploadedEditedProstateURI;
        } else if (
            saveData.UploadedProstateURI &&
            saveData.UploadedProstateURI !== EMPTY_STRING
        ) {
            uploadedSegmentationURI = saveData.UploadedProstateURI;
        } else if (
            TreatmentPlan.getStructureInFrame(
                TreatmentPlan.getCoreStructure(tplan),
                refFrames.MRI
            ) &&
            TreatmentPlan.getStructureInFrame(
                TreatmentPlan.getCoreStructure(tplan),
                refFrames.MRI
            ).URI !== EMPTY_STRING &&
            TreatmentPlan.getStructureInFrame(
                TreatmentPlan.getCoreStructure(tplan),
                refFrames.MRI
            ).URI !== aiSegmentationURI
        ) {
            uploadedSegmentationURI = TreatmentPlan.getStructureInFrame(
                TreatmentPlan.getCoreStructure(tplan),
                refFrames.MRI
            ).URI;
        }

        if (
            uploadedSegmentationURI &&
            uploadedSegmentationURI !== EMPTY_STRING
        ) {
            if (!patientAnatomyModel.prostateUploaded) {
                getPHISignedURLS3({
                    ...ids,
                    uris: toArray(uploadedSegmentationURI),
                })
                    .then((payload) => payload.json())
                    .then((json) => {
                        let url = getSignedUrl(json.payload.signedurls);
                        let prostateStructure = new ProstateModel();

                        prostateStructure.mriViewer =
                            patientAnatomyModel.mriViewer;
                        prostateStructure.meshViewer =
                            patientAnatomyModel.meshViewer;

                        prostateStructure
                            .initializeView(url, {
                                name: volumeNames.MR_PROSTATE_USER_UPLOAD,
                                color: structureColorPalette.PROSTATE,
                                opacity: 0.3,
                            })
                            .then(() => {
                                patientAnatomyModel.prostateUploaded =
                                    prostateStructure;

                                if (createAxes) {
                                    let prostateAxes = new ProstateAxes();
                                    prostateStructure.initProstateAxes(
                                        prostateAxes
                                    );
                                }

                                if (
                                    aiSegmentationURI &&
                                    aiSegmentationURI !== EMPTY_STRING
                                ) {
                                    prostateStructure.visibility = false;
                                } else {
                                    prostateStructure.visibility = true;
                                }

                                if (onLoadCallback) {
                                    onLoadCallback();
                                }
                            });
                    })
                    .catch(() => {
                        scope.setState({ loadState: pageLoadStates.FAILED });
                    });
            }
        }

        if (aiSegmentationURI && aiSegmentationURI !== EMPTY_STRING) {
            if (!patientAnatomyModel.prostateAI) {
                getPHISignedURLS3({
                    ...ids,
                    uris: toArray(aiSegmentationURI),
                })
                    .then((payload) => payload.json())
                    .then((json) => {
                        let url = getSignedUrl(json.payload.signedurls);
                        let prostateStructure = new ProstateModel();

                        prostateStructure.mriViewer =
                            patientAnatomyModel.mriViewer;
                        prostateStructure.meshViewer =
                            patientAnatomyModel.meshViewer;

                        prostateStructure
                            .initializeView(url, {
                                name: volumeNames.MR_PROSTATE_AI,
                                color: structureColorPalette.AI_PROSTATE,
                                opacity: 0.3,
                                visibility: true,
                            })
                            .then(() => {
                                patientAnatomyModel.prostateAI =
                                    prostateStructure;

                                if (createAxes) {
                                    let prostateAxes = new ProstateAxes();
                                    prostateStructure.initProstateAxes(
                                        prostateAxes
                                    );
                                }

                                if (onLoadCallback) {
                                    onLoadCallback();
                                }
                            });
                    })
                    .catch(() => {
                        scope.setState({ loadState: pageLoadStates.FAILED });
                    });
            }
        }
    }
}

export function legacy_loadROIs(scope, plan, ids, onLoadCallback = undefined) {
    let tplan = plan.TreatmentPlan;
    if (TreatmentPlan.hasROIs(tplan)) {
        TreatmentPlan.getROIs(tplan).forEach((roi, idx) => {
            if (
                TreatmentPlan.getStructureInFrame(roi, refFrames.MRI) &&
                TreatmentPlan.getStructureInFrame(roi, refFrames.MRI).URI !==
                    EMPTY_STRING
            ) {
                return getPHISignedURLS3({
                    ...ids,
                    uris: toArray(
                        TreatmentPlan.getStructureInFrame(roi, refFrames.MRI)
                            .URI
                    ),
                })
                    .then((payload) => payload.json())
                    .then((json) => {
                        let url = getSignedUrl(json.payload.signedurls);
                        let roiModel = new ROIModel(roi);
                        roiModel.mriViewer =
                            scope.patientAnatomyModel.mriViewer;
                        roiModel.meshViewer =
                            scope.patientAnatomyModel.meshViewer;
                        roiModel.initializeView(url, {
                            name: volumeNames.MR_ROI + String(idx),
                            color: structureColorPalette.ROI_CS_CANCER,
                            opacity: 1.0,
                            visibility: true,
                        });
                        scope.patientAnatomyModel.addRoi(roiModel);
                        scope.patientAnatomyController &&
                            scope.patientAnatomyController.update();
                        if (onLoadCallback) {
                            onLoadCallback();
                        }
                    })
                    .catch(() => {
                        rootStore.dispatch(
                            setErrorState(
                                true,
                                clientErrorMessages.MESH_DOWNLOAD_FAILED
                            )
                        );
                    });
            }
        });
    }
}

export function loadRois({
    plan,
    ids,
    patientAnatomyModel,
    patientAnatomyController,
    onLoadCallback,
}) {
    const tPlan = plan.TreatmentPlan;
    if (TreatmentPlan.hasROIs(tPlan)) {
        TreatmentPlan.getROIs(tPlan).forEach((roi, idx) => {
            const roiFrame = TreatmentPlan.getStructureInFrame(
                roi,
                refFrames.MRI
            );

            if (roiFrame && roiFrame.URI !== EMPTY_STRING) {
                getPHISignedURLS3({ ...ids, uris: toArray(roiFrame.URI) })
                    .then((payload) => payload.json())
                    .then((json) => {
                        const url = getSignedUrl(json.payload.signedurls);
                        const roiModel = new ROIModel(roi);
                        roiModel.mriViewer = patientAnatomyModel.mriViewer;
                        roiModel.meshViewer = patientAnatomyModel.meshViewer;
                        roiModel.initializeView(url, {
                            name: volumeNames.MR_ROI + String(idx),
                            color: structureColorPalette.ROI_CS_CANCER,
                            opacity: 1.0,
                            visibility: true,
                        });

                        patientAnatomyModel.addRoi(roiModel);
                        if (patientAnatomyController) {
                            patientAnatomyController.update();
                        }
                        if (onLoadCallback) {
                            onLoadCallback();
                        }
                    })
                    .catch(() => {
                        rootStore.dispatch(
                            setErrorState(
                                true,
                                clientErrorMessages.MESH_DOWNLOAD_FAILED
                            )
                        );
                    });
            }
        });
    }
}

export function legacy_loadOtherStructures(
    scope,
    plan,
    ids,
    onLoadCallback = undefined
) {
    let tplan = plan.TreatmentPlan;
    if (TreatmentPlan.hasOtherStructures(tplan)) {
        TreatmentPlan.getOtherStructures(tplan).forEach((os, idx) => {
            if (
                TreatmentPlan.getStructureInFrame(os, refFrames.MRI) &&
                TreatmentPlan.getStructureInFrame(os, refFrames.MRI).URI !==
                    EMPTY_STRING
            ) {
                return getPHISignedURLS3({
                    ...ids,
                    uris: toArray(
                        TreatmentPlan.getStructureInFrame(os, refFrames.MRI).URI
                    ),
                })
                    .then((payload) => payload.json())
                    .then((json) => {
                        let url = getSignedUrl(json.payload.signedurls);
                        let osModel = new ROIModel(os);
                        osModel.mriViewer = scope.patientAnatomyModel.mriViewer;
                        osModel.meshViewer =
                            scope.patientAnatomyModel.meshViewer;
                        osModel.initializeView(url, {
                            name: volumeNames.MR_TUMOR + String(idx),
                            color: structureColorPalette.TUMOR_GROUND_TRUTH,
                            visibility: false,
                            opacity: 0.5,
                        });
                        scope.patientAnatomyModel.addTumorGroundTruth(osModel);
                        if (onLoadCallback) {
                            onLoadCallback();
                        }
                    })
                    .catch(() => {
                        rootStore.dispatch(
                            setErrorState(
                                true,
                                clientErrorMessages.MESH_DOWNLOAD_FAILED
                            )
                        );
                    });
            }
        });
    }
}

export function loadOtherStructures({
    plan,
    ids,
    patientAnatomyModel,
    onLoadCallback = undefined,
}) {
    const tPlan = plan.TreatmentPlan;
    if (TreatmentPlan.hasOtherStructures(tPlan)) {
        TreatmentPlan.getOtherStructures(tPlan).forEach((os, idx) => {
            const osFrame = TreatmentPlan.getStructureInFrame(
                os,
                refFrames.MRI
            );
            if (osFrame && osFrame.URI !== EMPTY_STRING) {
                return getPHISignedURLS3({
                    ...ids,
                    uris: toArray(
                        TreatmentPlan.getStructureInFrame(os, refFrames.MRI).URI
                    ),
                })
                    .then((payload) => payload.json())
                    .then((json) => {
                        const url = getSignedUrl(json.payload.signedurls);
                        const osModel = new ROIModel(os);
                        osModel.mriViewer = patientAnatomyModel.mriViewer;
                        osModel.meshViewer = patientAnatomyModel.meshViewer;
                        osModel.initializeView(url, {
                            name: volumeNames.MR_TUMOR + String(idx),
                            color: structureColorPalette.TUMOR_GROUND_TRUTH,
                            visibility: false,
                            opacity: 0.5,
                        });

                        patientAnatomyModel.addTumorGroundTruth(osModel);

                        if (onLoadCallback) {
                            onLoadCallback();
                        }
                    })
                    .catch(() => {
                        rootStore.dispatch(
                            setErrorState(
                                true,
                                clientErrorMessages.MESH_DOWNLOAD_FAILED
                            )
                        );
                    });
            }
        });
    }
}

export function legacy_loadMargin(scope, plan, ids) {
    let tplan = plan.TreatmentPlan;
    if (TreatmentPlan.hasMargins(tplan)) {
        let margin = tplan.StructureData.Margins[0];

        getPHISignedURLS3({
            ...ids,
            uris: toArray(
                TreatmentPlan.getStructureInFrame(margin, refFrames.MRI).URI
            ),
        })
            .then((payload) => payload.json())
            .then((json) => {
                let url = getSignedUrl(json.payload.signedurls);
                let marginStructure = new StructureModel();
                marginStructure.mriViewer = scope.patientAnatomyModel.mriViewer;
                marginStructure.meshViewer =
                    scope.patientAnatomyModel.meshViewer;
                marginStructure.initializeView(url, {
                    name: volumeNames.MR_MARGIN_AI,
                    color: structureColorPalette.TX_MARGIN,
                    opacity: 0.3,
                });
                marginStructure.visibility = true;
                scope.patientAnatomyModel.margin = marginStructure;
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.MESH_DOWNLOAD_FAILED
                    )
                );
            });
    }
}

export function loadMargin({ plan, ids, patientAnatomyModel, setLoadState }) {
    const tPlan = plan.TreatmentPlan;
    if (TreatmentPlan.hasMargins(tPlan)) {
        const margin = tPlan.StructureData.Margins[0];
        getPHISignedURLS3({
            ...ids,
            uris: toArray(
                TreatmentPlan.getStructureInFrame(margin, refFrames.MRI).URI
            ),
        })
            .then((payload) => payload.json())
            .then((json) => {
                const url = getSignedUrl(json.payload.signedurls);
                const marginStructure = new StructureModel();
                marginStructure.mriViewer = patientAnatomyModel.mriViewer;
                marginStructure.meshViewer = patientAnatomyModel.meshViewer;
                marginStructure.initializeView(url, {
                    name: volumeNames.MR_MARGIN_AI,
                    color: structureColorPalette.TX_MARGIN,
                    opacity: 0.3,
                });
                marginStructure.visibility = true;
                patientAnatomyModel.margin = marginStructure;
            })
            .catch(() => {
                setLoadState(pageLoadStates.FAILED);
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.MESH_DOWNLOAD_FAILED
                    )
                );
            });
    }
}

async function legacy_loadAblationVolumes(scope, targets) {
    let uuid = scope.props.currentTreatmentPlan.SavedData.AblationProfileUUID;
    if (uuid) {
        await legacy_setAblationProfileHelper(scope, uuid);
        scope.targetSetModel.setTargetsFromTreatmentPlanFormat(
            targets,
            scope.targetSetController.ablationProfileTemplate
        );
    }
}

async function loadAblationVolumes({
    authToken,
    userUuid,
    currentTreatmentPlan,
    targetSetModel,
    targetSetController,
    targets,
}) {
    const ablationProfileUuid =
        currentTreatmentPlan.SavedData.AblationProfileUUID;

    if (ablationProfileUuid) {
        await setAblationProfileHelper({
            authToken,
            userUuid,
            ablationProfileUuid,
            targetSetController,
        });
        targetSetModel.setTargetsFromTreatmentPlanFormat(
            targets,
            targetSetController.ablationProfileTemplate
        );
    }
}

export function legacy_loadMRI(scope, plan, ids) {
    const tplan = plan.TreatmentPlan;

    getMRIURLsHelper(tplan, ids)
        .then((mriURLs) => {
            scope.mriModel.displayMeshContours = true;
            if (
                !scope.props.currentTreatmentPlan.SavedData.CPMForLUTDisplayURI
            ) {
                // Disable LUT if we dont actually have a CPMForLUTDisplayURI defined
                scope.mriModel.lutEnabled = false;
            }

            return scope.mriModel.initializeView(mriURLs);
        })
        .then(() => {
            scope.mriModel.orientation = sceneOrientations.AXIAL;
            scope.mriModel.sliceIndexPercent = DEFAULT_TICK_PERCENT;

            const { targetSetModel, targetSetController } = scope;

            if (targetSetModel && targetSetController) {
                const targets = TreatmentPlan.getTargets(
                    scope.props.currentTreatmentPlan.TreatmentPlan
                );

                if (targets) {
                    legacy_loadAblationVolumes(scope, targets).then(() => {
                        scope.setState({ ablationSitesLoaded: true });
                        scope.patientAnatomyController &&
                            scope.patientAnatomyController.update();
                    });
                }
            }

            if (scope.state.loadState !== pageLoadStates.FAILED) {
                scope.setState({
                    meshViewerLoadState: pageLoadStates.COMPLETE,
                    loadState: pageLoadStates.COMPLETE,
                });
                scope.mriModel._meshViewer.controls.saveState();
            }

            if (
                scope.mriModel.lutEnabled &&
                scope.props.currentTreatmentPlan.SavedData.CPMForLUTDisplayURI
            ) {
                getPHISignedURLS3({
                    token: ids.token,
                    useruuid: ids.useruuid,
                    patientuuid: ids.patientuuid,
                    visituuid: ids.visituuid,
                    uris: toArray(plan.SavedData.CPMForLUTDisplayURI),
                })
                    .then((payload) => payload.json())
                    .then((json) => getSignedUrl(json.payload.signedurls))
                    .then((url) => scope.mriModel.initializeLUT([url]));
            }
        })
        .catch(() => {
            scope.setState({ loadState: pageLoadStates.FAILED });
        });
}

export function loadMriStep4({
    ids,
    currentTreatmentPlan,
    mriModel,
    targetSetModel,
    targetSetController,
    patientAnatomyController,
    setAblationSitesLoaded,
    loadState,
    setLoadState,
}) {
    const tPlan = currentTreatmentPlan.TreatmentPlan;
    getMRIURLsHelper(tPlan, ids)
        .then((mriUrls) => {
            mriModel.displayMeshContours = true;
            if (!currentTreatmentPlan.SavedData.CPMForLUTDisplayURI) {
                // Disable LUT if we don't have a CPMForLUTDisplayURI defined
                mriModel.lutEnabled = false;
            }

            return mriModel.initializeView(mriUrls);
        })
        .then(() => {
            mriModel.orientation = sceneOrientations.AXIAL;
            mriModel.sliceIndexPercent = DEFAULT_TICK_PERCENT;

            if (targetSetModel && targetSetController) {
                const targets = TreatmentPlan.getTargets(
                    currentTreatmentPlan.TreatmentPlan
                );

                if (targets) {
                    loadAblationVolumes({
                        authToken: ids.token,
                        userUuid: ids.useruuid,
                        currentTreatmentPlan,
                        targetSetModel,
                        targetSetController,
                        targets,
                    }).then(() => {
                        setAblationSitesLoaded(true);
                        patientAnatomyController &&
                            patientAnatomyController.update();
                    });
                }
            }

            if (loadState !== pageLoadStates.FAILED) {
                setLoadState(pageLoadStates.COMPLETE);
                mriModel._meshViewer.controls.saveState();
            }

            if (
                mriModel.lutEnabled &&
                currentTreatmentPlan.SavedData.CPMForLUTDisplayURI
            ) {
                getPHISignedURLS3({
                    token: ids.token,
                    useruuid: ids.useruuid,
                    patientuuid: ids.patientuuid,
                    visituuid: ids.visituuid,
                    uris: toArray(
                        currentTreatmentPlan.SavedData.CPMForLUTDisplayURI
                    ),
                })
                    .then((payload) => payload.json())
                    .then((json) => getSignedUrl(json.payload.signedurls))
                    .then((url) => mriModel.initializeLUT([url]));
            }
        })
        .catch(() => {
            setLoadState(pageLoadStates.FAILED);
        });
}

export function legacy_loadBxCores(scope, plan) {
    let tplan = plan.TreatmentPlan;

    let cores = TreatmentPlan.getBiopsyCores(tplan);

    if (cores) {
        for (const [idx, c] of cores.entries()) {
            // Core index is used to map cores in MeshViewer with cores in BiopsyPlotContainer and PathologyTable
            c.coreIndex = idx;
            let coreModel = new BiopsyCoreModel();
            coreModel.mriViewer = scope.patientAnatomyModel.mriViewer;
            coreModel.meshViewer = scope.patientAnatomyModel.meshViewer;
            coreModel.initializeView(c);
            coreModel.visibility = true;
            scope.patientAnatomyModel.addBxCore(coreModel);
        }
    }
}

export function loadBxCores({ plan, patientAnatomyModel }) {
    const tPlan = plan.TreatmentPlan;
    const cores = TreatmentPlan.getBiopsyCores(tPlan);
    if (cores) {
        for (let c of cores) {
            let coreModel = new BiopsyCoreModel();
            coreModel.mriViewer = patientAnatomyModel.mriViewer;
            coreModel.meshViewer = patientAnatomyModel.meshViewer;
            coreModel.initializeView(c);
            coreModel.visibility = true;
            patientAnatomyModel.addBxCore(coreModel);
        }
    }
}

export function legacy_loadBoundingCone(scope, plan) {
    let tplan = plan.TreatmentPlan;

    if (TreatmentPlan.hasBxCores(tplan)) {
        let bxCores = TreatmentPlan.getBiopsyCores(tplan);
        let prostate = scope.patientAnatomyModel.prostate;
        getBoundingCone(
            {
                radius: prostate.boundingSphere.radius,
                center: {
                    X: prostate.boundingSphere.center.x,
                    Y: prostate.boundingSphere.center.y,
                    Z: prostate.boundingSphere.center.z,
                },
            },
            bxCores
        ).then((result) => {
            let boundingCone = result.payload;
            let boundingConeStructure = new BoundingCone();
            boundingConeStructure.mriViewer =
                scope.patientAnatomyModel.mriViewer;
            boundingConeStructure.meshViewer =
                scope.patientAnatomyModel.meshViewer;
            boundingConeStructure.initializeView(boundingCone);
            boundingConeStructure.visibility = false;
            scope.targetSetController.boundingCone = boundingConeStructure;
        });
    }
}

export function loadBoundingCone({
    plan,
    patientAnatomyModel,
    targetSetController,
}) {
    const tPlan = plan.TreatmentPlan;

    if (TreatmentPlan.hasBxCores(tPlan)) {
        const bxCores = TreatmentPlan.getBiopsyCores(tPlan);
        const prostate = patientAnatomyModel.prostate;
        getBoundingCone(
            {
                radius: prostate.boundingSphere.radius,
                center: {
                    X: prostate.boundingSphere.center.x,
                    Y: prostate.boundingSphere.center.y,
                    Z: prostate.boundingSphere.center.z,
                },
            },
            bxCores
        )
            .then((result) => {
                const boundingCone = result.payload;
                const boundingConeStructure = new BoundingCone();

                boundingConeStructure.mriViewer = patientAnatomyModel.mriViewer;
                boundingConeStructure.meshViewer =
                    patientAnatomyModel.meshViewer;
                boundingConeStructure.initializeView(boundingCone);

                boundingConeStructure.visibility = false;
                targetSetController.boundingCone = boundingConeStructure;
            })
            .catch(() => {});
    }
}

export function makeCustomCatheterObject(burnZoneTipOffset = 6.5, id) {
    const parameters = {
        lcShaftLength: 40,
        lcShaftRadius: 1,
        lcShaftResolution: 32,
        lcTipLength: 3,
        lcTipResolution: 32,
        lcTipRadius: 1,
        lcColor: 0xd8d8d8,
    };

    const cylGeometry = new THREE.CylinderGeometry(
        parameters.lcShaftRadius,
        parameters.lcShaftRadius,
        parameters.lcShaftLength,
        parameters.lcShaftResolution
    );
    const cylMaterial = new THREE.MeshPhongMaterial({
        color: parameters.lcColor,
    });
    const cylinder = new THREE.Mesh(cylGeometry, cylMaterial);
    cylinder.translateY(
        -(parameters.lcShaftLength / 2 + parameters.lcTipLength)
    );

    cylinder.translateY(burnZoneTipOffset);

    cylinder.name = `${LC_MESH_NAMES.ROD}_${id}`;

    const coneGeometry = new THREE.ConeGeometry(
        parameters.lcTipRadius,
        parameters.lcTipLength,
        parameters.lcTipResolution,
        parameters.lcTipResolution
    );
    const coneMaterial = new THREE.MeshPhongMaterial({
        color: parameters.lcColor,
    });
    const cone = new THREE.Mesh(coneGeometry, coneMaterial);

    cone.translateY(-parameters.lcTipLength / 2);

    cone.translateY(burnZoneTipOffset);

    cone.name = `${LC_MESH_NAMES.TIP}_${id}`;

    let laserCatheter = new THREE.Object3D();

    laserCatheter.name = `${CUSTOM_TARGET_NAMES.LC_NAME}_${id}`;

    laserCatheter.add(cylinder);
    laserCatheter.add(cone);

    return laserCatheter;
}

export function resetViewer(meshViewer, mriViewer, sliceController, mriModel) {
    // Reset 3D Mesh Viewer
    meshViewer.current.resetScene();

    // Reset 2D MRI Viewer
    mriViewer.current.resetScene();

    // Reset MRI slice to default value
    sliceController.current.reset();

    // Reset controls to match with the selected orientation
    // The first mriModel.orientation is a "set" function
    // While the second mriModel.orientation is a "get" function
    // eslint-disable-next-line
    mriModel.orientation = mriModel.orientation;
}
