import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { keyStrings, sceneOrientations } from "../../../../constants";
import axSrcBlue from "../../../../assets/axial_cube_btn_blue.svg";
import axSrcDark from "../../../../assets/axial_cube_btn_dark.svg";
import sagSrcBlue from "../../../../assets/sagittal_cube_btn_blue.svg";
import sagSrcDark from "../../../../assets/sagittal_cube_btn_dark.svg";
import corSrcBlue from "../../../../assets/coronal_cube_btn_blue.svg";
import corSrcDark from "../../../../assets/coronal_cube_btn_dark.svg";
import MRIModel from "../../../__shared__/Viewers/Structures/MRI/MRIModel";
import MRIController from "../../../__shared__/Viewers/Structures/MRI/MRIController";
import { orientationButtons } from "../../constants";

const ORIENTATION_BTN_STATES = {
    AXIAL: {
        axial: "active",
        sag: "inactive",
        cor: "inactive",
    },
    SAGITTAL: {
        axial: "inactive",
        sag: "active",
        cor: "inactive",
    },
    CORONAL: {
        axial: "inactive",
        sag: "inactive",
        cor: "active",
    },
};

class OrientationButtonGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnState: ORIENTATION_BTN_STATES.AXIAL,
        };

        this.handleClick = this.handleClick.bind(this);
        this.keyBoardControlHandler = this.keyBoardControlHandler.bind(this);
    }

    componentDidMount() {
        window.addEventListener("keydown", this.keyBoardControlHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyBoardControlHandler);
    }

    handleClick(e, orientation) {
        e.stopPropagation();

        this.handleChange(orientation);
    }

    handleChange(orientation) {
        if (!orientation) {
            return;
        }

        this.props.mriModel.orientation = orientation;

        switch (orientation) {
            case sceneOrientations.AXIAL:
                this.setState({
                    btnState: ORIENTATION_BTN_STATES.AXIAL,
                });
                break;
            case sceneOrientations.SAGITTAL:
                this.setState({
                    btnState: ORIENTATION_BTN_STATES.SAGITTAL,
                });
                break;
            case sceneOrientations.CORONAL:
                this.setState({
                    btnState: ORIENTATION_BTN_STATES.CORONAL,
                });
                break;
            default:
                break;
        }
    }

    keyBoardControlHandler(e) {
        if (e == null) {
            return;
        }

        e.stopPropagation();

        let orientation;

        switch (e.key) {
            case keyStrings.ONE:
                orientation = sceneOrientations.AXIAL;
                break;
            case keyStrings.TWO:
                orientation = sceneOrientations.SAGITTAL;
                break;
            case keyStrings.THREE:
                orientation = sceneOrientations.CORONAL;
                break;
            default:
                return;
        }

        this.handleClick(e, orientation);
    }

    render() {
        let axSrc = axSrcDark;
        let sagSrc = sagSrcDark;
        let corSrc = corSrcDark;

        switch (this.state.btnState) {
            case ORIENTATION_BTN_STATES.AXIAL:
                axSrc = axSrcBlue;
                break;
            case ORIENTATION_BTN_STATES.SAGITTAL:
                sagSrc = sagSrcBlue;
                break;
            case ORIENTATION_BTN_STATES.CORONAL:
                corSrc = corSrcBlue;
                break;
            default:
                break;
        }

        return (
            <div className="orientation-btns-cntr">
                <div
                    className="orientation-btn pr-2"
                    onClick={(e) =>
                        this.handleClick(e, sceneOrientations.AXIAL)
                    }
                >
                    <img
                        className="orientation-btn-icon"
                        src={axSrc}
                        alt={orientationButtons.AXIAL}
                    />
                    <p>Axial</p>
                </div>
                <div
                    className="orientation-btn pr-2"
                    onClick={(e) =>
                        this.handleClick(e, sceneOrientations.SAGITTAL)
                    }
                >
                    <img
                        className="orientation-btn-icon"
                        src={sagSrc}
                        alt={orientationButtons.SAGITTAL}
                    />
                    <p>Sagittal</p>
                </div>
                <div
                    className="orientation-btn"
                    onClick={(e) =>
                        this.handleClick(e, sceneOrientations.CORONAL)
                    }
                >
                    <img
                        className="orientation-btn-icon"
                        src={corSrc}
                        alt={orientationButtons.CORONAL}
                    />
                    <p>Coronal</p>
                </div>
            </div>
        );
    }
}

OrientationButtonGroup.propTypes = {
    mriModel: PropTypes.instanceOf(MRIModel),
    mriController: PropTypes.instanceOf(MRIController),
    color: PropTypes.string,
    style: PropTypes.object,
};

export default OrientationButtonGroup;
