import { connect } from "react-redux";
import { setCurrentTreatmentPlan } from "../../CreateTPlan_1Upload/actions";
import PropTypes from "prop-types";
import MarksScoreGraph from "../MarksScoreGraph";
import { useEffect, useState } from "react";
import { getNewLesionContour } from "../../CreateTPlan_4EditMargin/LesionContourWorkflow/lesion_helper";
import CancerLesionStatistics from "../CancerLesionStatistics";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";

function ReviewCancerLesion(props) {
    const {
        currentTreatmentPlan: plan,
        useruuid,
        patientuuid,
        planuuid,
        authToken,
    } = props;

    const [userSelectedCpmThreshold, setUserSelectedCpmThreshold] = useState(0);
    const [userSelectedProstateCoverage, setUserSelectedProstateCoverage] =
        useState(0);
    const [userSelectedMarksScore, setUserSelectedMarksScore] = useState(0);
    const [userSelectedLesionVolume, setUserSelectedLesionVolume] = useState(0);
    const [updatingMargin, setUpdatingMargin] = useState(false);

    useEffect(() => {
        if (TreatmentPlan.hasMargins(plan.TreatmentPlan)) {
            const lesionVolume = TreatmentPlan.getMarginByIdx(
                plan.TreatmentPlan,
                0
            ).VolumeCC;
            setUserSelectedLesionVolume(lesionVolume);
        }

        setUserSelectedProstateCoverage(
            plan.SavedData.UserSelectedLesionCoverageOfProstate
        );
        setUserSelectedCpmThreshold(plan.SavedData.UserSelectedCPMThreshold);
        setUserSelectedMarksScore(plan.SavedData.UserSelectedMarksScore);
    }, [plan]);

    function getNewLesionContourHandler(userSelectedCpmThresholdParam) {
        const cpmThreshold =
            userSelectedCpmThresholdParam ?? userSelectedCpmThreshold;

        getNewLesionContour({
            currentTreatmentPlan: plan,
            updatingMargin,
            setUpdatingMargin,
            userSelectedCpmThreshold: cpmThreshold,
            setUserSelectedProstateCoverage,
            setUserSelectedMarksScore,
            setUserSelectedLesionVolume,
            authToken,
            useruuid,
            patientuuid,
            planuuid,
            setCurrentTreatmentPlan,
            onMarginCreation: props.onMarginCreation,
        });
    }

    function handleSetLesionParams(params) {
        setUserSelectedProstateCoverage(params.prostateCoverage);
        setUserSelectedCpmThreshold(params.cpmThreshold);
        setUserSelectedMarksScore(params.marksScore);
        setUserSelectedLesionVolume(params.lesionVolume);
    }

    return (
        <div className="h-full w-full bg-[#393939] px-4 text-white">
            <MarksScoreGraph
                currentTreatmentPlan={plan}
                defaultProstateCoverage={
                    plan.SavedData.OptimalLesionCoverageOfProstate
                }
                defaultMarksScore={plan.SavedData.OptimalMarksScore}
                getNewLesionContourHandler={getNewLesionContourHandler}
                handleSetLesionParams={handleSetLesionParams}
                userSelectedProstateCoverage={userSelectedProstateCoverage}
                userSelectedMarksScore={userSelectedMarksScore}
            />
            <CancerLesionStatistics
                currentTreatmentPlan={plan}
                updatingMargin={updatingMargin}
                userSelectedProstateCoverage={userSelectedProstateCoverage}
                userSelectedMarksScore={userSelectedMarksScore}
                userSelectedLesionVolume={userSelectedLesionVolume}
            />
        </div>
    );
}

ReviewCancerLesion.propTypes = {
    currentTreatmentPlan: PropTypes.object,
    authToken: PropTypes.string,
    useruuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    onMarginCreation: PropTypes.func,
};

const mapStateToProps = function (state) {
    return {
        authToken: state.LoginReducer.authToken,
        useruuid: state.LoginReducer.useruuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCancerLesion);
