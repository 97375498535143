import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

export function Index({ component: Component, state, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                rest.authFunc(rest) ? (
                    <Component {...props} {...state} />
                ) : (
                    <Redirect
                        to={{
                            pathname: rest.altPath,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

Index.propTypes = {
    component: PropTypes.any,
    location: PropTypes.object,
    state: PropTypes.object,
};

const mapStateToProps = function (state) {
    return state.LoginReducer;
};

export default connect(mapStateToProps)(Index);
