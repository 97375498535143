import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import SignUp from "./components/SignUpFlow";
import { NotFound404 } from "./components/NotFound404";
import PrivateRoute from "./components/__shared__/PrivateRoute";
import "./styles/standard_styles.css";
import SessionManager from "./sessionmanager";
import CreateTPlan_1Upload from "./components/CreateTPlan_1Upload";
import CreateTPlan_2SegMRI from "./components/CreateTPlan_2SegMRI";
import CreateTPlan_3Biopsy from "./components/CreateTPlan_3Biopsy";
import CreateTPlan_5Summary from "./components/CreateTPlan_5Summary";
import EnableMFA from "./components/EnableMFA";
import Profile from "./components/Profile";
import Contributors from "./components/Contributors";
import CreateAblationProfile from "./components/CreateAblationProfile";
import PasswordRecover from "./components/PasswordRecover";
import { SetNewPassword } from "./components/SetNewPassword";
import { stepPath } from "./constants";
import { authPlanCompleteFunc, authTokenFunc } from "./helpers/helpers";
import CreateTPlan_4_1_GenerateCPM from "./components/CreateTPlan_4_1_GenerateCPM";
import CreateTPlan_1_1_SelectPlanType from "./components/CreateTPlan_1_1_SelectPlanType";
import CreateTPlan_4_5_Workflow from "./components/CreateTPlan_4_5_Workflow";

class AppRouter extends Component {
    render() {
        return (
            <BrowserRouter>
                <div id={"browser-router"}>
                    <SessionManager />
                    <Switch>
                        <PrivateRoute
                            exact
                            path={stepPath.INDEX}
                            component={Home}
                            altPath={stepPath.LOGIN}
                            authFunc={authTokenFunc}
                        />
                        <PrivateRoute
                            path={stepPath.HOME}
                            component={Home}
                            altPath={stepPath.LOGIN}
                            authFunc={authTokenFunc}
                        />
                        <PrivateRoute
                            path={stepPath.STEP1_1}
                            component={CreateTPlan_1_1_SelectPlanType}
                            altPath={stepPath.HOME}
                            authFunc={authPlanCompleteFunc}
                        />
                        <PrivateRoute
                            path={stepPath.STEP1}
                            component={CreateTPlan_1Upload}
                            altPath={stepPath.HOME}
                            authFunc={authPlanCompleteFunc}
                        />
                        <PrivateRoute
                            path={stepPath.STEP2}
                            component={CreateTPlan_2SegMRI}
                            altPath={stepPath.HOME}
                            authFunc={authPlanCompleteFunc}
                        />
                        <PrivateRoute
                            path={stepPath.STEP3}
                            component={CreateTPlan_3Biopsy}
                            altPath={stepPath.HOME}
                            authFunc={authPlanCompleteFunc}
                        />
                        <PrivateRoute
                            path={stepPath.STEP4_1}
                            component={CreateTPlan_4_1_GenerateCPM}
                            altPath={stepPath.HOME}
                            authFunc={authPlanCompleteFunc}
                        />
                        <PrivateRoute
                            path={stepPath.STEP4}
                            component={CreateTPlan_4_5_Workflow}
                            altPath={stepPath.HOME}
                            authFunc={authPlanCompleteFunc}
                        />
                        <PrivateRoute
                            path={stepPath.STEP5}
                            component={CreateTPlan_4_5_Workflow}
                            altPath={stepPath.HOME}
                            authFunc={authPlanCompleteFunc}
                        />
                        <PrivateRoute
                            path={stepPath.STEP6}
                            component={CreateTPlan_5Summary}
                            altPath={stepPath.LOGIN}
                            authFunc={authTokenFunc}
                        />
                        <PrivateRoute
                            path={stepPath.PROFILE}
                            component={Profile}
                            altPath={stepPath.PROFILE}
                            authFunc={authTokenFunc}
                        />
                        <PrivateRoute
                            path={stepPath.CREATE_ABLATION_PROFILE}
                            component={CreateAblationProfile}
                            altPath={stepPath.CREATE_ABLATION_PROFILE}
                            authFunc={authTokenFunc}
                        />
                        <PrivateRoute
                            path={stepPath.ENABLE_MFA}
                            component={EnableMFA}
                            altPath={stepPath.ENABLE_MFA}
                            authFunc={authTokenFunc}
                        />
                        <Route path={stepPath.LOGIN} component={Login} />
                        <Route
                            path={stepPath.FORGOT_PW}
                            component={PasswordRecover}
                        />
                        <Route path={stepPath.SIGNUP} component={SignUp} />
                        <Route
                            path={stepPath.SET_NEW_PASS}
                            component={SetNewPassword}
                        />
                        <Route
                            path={stepPath.SECRET_CONTRIBUTOR_PAGE}
                            component={Contributors}
                        />
                        <Route component={NotFound404} />
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default AppRouter;
