import React, { Component } from "react";
import tr12Template from "../../../assets/biopsy_templates/TR-12.svg";
import tp12Template from "../../../assets/biopsy_templates/TP-12-Generic.svg";
import tpUroNav12Template from "../../../assets/biopsy_templates/TP-12-UroNav.svg";
import tpUroNav20Template from "../../../assets/biopsy_templates/TP-20-UroNav.svg";
import tpPPTemplate from "../../../assets/biopsy_templates/TP-PrecisionPoint.svg";
import "./styles.css";
import BiopsyPlot from "../BiopsyPlot";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SystematicTemplateNames } from "../constants";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";

class BiopsyPlotContainer extends Component {
    constructor(props) {
        super(props);

        this.biopsyPlotRef = React.createRef();

        this.callBiopsyPlotFunction = this.callBiopsyPlotFunction.bind(this);
    }

    callBiopsyPlotFunction() {
        if (this.biopsyPlotRef.current) {
            this.biopsyPlotRef.current.forceUpdate();
        }
    }

    getSystematicTemplate() {
        let bxTemplate = TreatmentPlan.getBxTemplate(
            this.props.currentTreatmentPlan.TreatmentPlan
        );
        switch (bxTemplate) {
            case SystematicTemplateNames.TR_12:
                return (
                    <img
                        id="tr-12-systematic-template"
                        alt="Transrectal 12 Core Systematic Biopsy Template"
                        src={tr12Template}
                    />
                );
            case SystematicTemplateNames.TP_12:
                return (
                    <img
                        id="tp-12-systematic-template"
                        alt="Transperineal 12 Core Systematic Biopsy Template"
                        src={tp12Template}
                    />
                );
            case SystematicTemplateNames.TP_UroNav12:
                return (
                    <img
                        id="tp-uronav-12-systematic-template"
                        alt="UroNav Transperineal 12 Core Systematic Biopsy Template"
                        src={tpUroNav12Template}
                        className="double-decker"
                    />
                );
            case SystematicTemplateNames.TP_UroNav20:
                return (
                    <img
                        id="tp-uronav-20-systematic-template"
                        alt="UroNav Transperineal 20 Core Systematic Biopsy Template"
                        src={tpUroNav20Template}
                        className="double-decker"
                    />
                );
            case SystematicTemplateNames.TP_PrecisionPoint:
                return (
                    <img
                        id="tp-pp-systematic-template"
                        alt="Precision Point Systematic Biopsy Template"
                        src={tpPPTemplate}
                        className="double-decker"
                    />
                );
            default:
                return (
                    <img
                        id="tr-12-systematic-template"
                        alt="Transrectal 12 Core Systematic Biopsy Template"
                        src={tr12Template}
                    />
                );
        }
    }

    render() {
        return (
            <div className="biopsy-plot-container">
                <div className="biopsy-template-container">
                    {this.getSystematicTemplate()}
                    <BiopsyPlot ref={this.biopsyPlotRef} />
                </div>
            </div>
        );
    }
}

BiopsyPlotContainer.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    currentTreatmentPlan: PropTypes.object,
    children: PropTypes.array,
};

BiopsyPlotContainer.defaultProps = {
    currentTreatmentPlan: {},
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
    BiopsyPlotContainer
);
