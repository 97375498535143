import React, { Component } from "react";
import "./styles.css";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { handleLogout } from "../../Login/actions";
import { signOut } from "../../Login/helpers";
import { connect } from "react-redux";
import { EMPTY_STRING, SUCCESS, stepPath } from "../../../constants";
import { withRouter } from "react-router-dom";
import { isEmpty } from "../../../helpers/helpers";
import blankUser from "../../../assets/blank_user.svg";
import PropTypes from "prop-types";
import { releasePlanLock } from "../../../helpers/backend_api";

class ProfileMenu extends Component {
    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    async handleLogout() {
        await releasePlanLock({
            token: this.props.authToken,
            useruuid: this.props.userUuid,
            planuuid: this.props.planUuid,
        });
        signOut(this.props.userData.username).then((payload) => {
            if (isEmpty(payload) || payload.status !== SUCCESS) {
                this.setState({
                    hasError: true,
                });
            }

            this.props.history.push(stepPath.LOGIN);
            this.props.handleLogout();
        });
    }

    render() {
        let profilePicUrl = this.props.userData.profilePictureUrl
            ? this.props.userData.profilePictureUrl
            : blankUser;
        const pictureToggle = React.forwardRef((props, ref) => (
            <img
                id="topbar-profile-pic"
                src={profilePicUrl}
                className={"profile-picture unselectable"}
                ref={ref}
                alt={"profile"}
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick(e);
                }}
            />
        ));

        pictureToggle.displayName = "pictureToggle";

        return (
            <div id={this.props.id} className={"profile-menu"}>
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                        as={pictureToggle}
                        id={"dropdown-button"}
                    />

                    <Dropdown.Menu id={"profile-menu-items"} className="z-50">
                        <Dropdown.Item
                            id={"profile-menu-profile-button"}
                            onClick={() =>
                                this.props.history.push(stepPath.PROFILE)
                            }
                        >
                            Profile
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            id={"profile-menu-logout-button"}
                            onClick={this.handleLogout}
                        >
                            Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}

ProfileMenu.propTypes = {
    authToken: PropTypes.string,
    history: PropTypes.object,
    onClick: PropTypes.func,
    userData: PropTypes.object,
    userUuid: PropTypes.string,
    planUuid: PropTypes.string,
    children: PropTypes.any,
    id: PropTypes.string,
    handleLogout: PropTypes.func,
};

ProfileMenu.defaultProps = {
    id: EMPTY_STRING,
    authToken: EMPTY_STRING,
    userData: {},
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        userUuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        userData: state.LoginReducer.userData,
        planUuid: state.CreatePlanReducer.planuuid,
        flags: state.LoginReducer.flags,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        handleLogout: () => dispatch(handleLogout()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)
);
