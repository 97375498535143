import React from "react";
import PropTypes from "prop-types";

/**
 * XBadge Component
 * Replaces the X icons usages previously imported from:
 * - "frontend/src/assets/x_icon.svg"
 * - "frontend/src/assets/gray_x.svg"
 *
 * @todo Refactor the old icon usages to use this component.
 */
function XBadge({ size = 4, stroke = 2, color = "#ffffff" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`h-${size} w-${size} `}
        >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
    );
}

XBadge.propTypes = {
    size: PropTypes.number,
    stroke: PropTypes.number,
    color: PropTypes.string,
};

export default XBadge;
