import { uploadStates } from "./constants";
import { stepBarStepNames } from "../../constants";

export const initialState = {
    planCreationStepMetaData: [
        { active: true, complete: false, name: stepBarStepNames.PATIENT_INFO },
        { active: false, complete: false, name: stepBarStepNames.SEGMENTATION },
        { active: false, complete: false, name: stepBarStepNames.BIOPSY },
        { active: false, complete: false, name: stepBarStepNames.CANCER_AI },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.TOOL_PLACEMENT,
        },
        { active: false, complete: false, name: stepBarStepNames.SUMMARY },
    ],

    currentTreatmentPlan: {},
    planuuid: "",
    patientuuid: "",
    visituuid: "",

    uploadState: [
        uploadStates.INIT,
        uploadStates.INIT,
        uploadStates.INIT,
        uploadStates.INIT,
    ],
    uploadProgress: ["0", "0", "0", "0"],
    parseProgress: ["0", "0", "0", "0"],
    parseFailureMessage: ["", "", "", ""],

    isPathReportUploaded: false,
    isTemplateUploaded: false,
    isBiopsyDataUploaded: false,
    pathReportThumbnailURL: null,
    templateThumbnailURL: null,
    mriThumbnailURL: null,
    biopsyThumbnailURL: null,
    isFreehandPlan: false,
};
