export const EPS = 1 - 0.00001;
export const MAX_PROSTATE_COVERAGE = 0.5;

export const STEP4_BUTTON_TEXT = {
    CONFIRM: "Confirm",
    NEXT_STEP: "Confirm Cancer Lesion Contour",
};

export const STEP5_BUTTON_TEXT = {
    NEXT_STEP: "Verify Tool Placements",
};

export const STEP4_TEXT = {
    REVIEW_CANCER_LESION: "Review Cancer Lesion",
    VERIFY_TOOL_PLACEMENTS: "Verify Tool Placements",
    SUB_HEADER: "Choose a lesion size by interacting with the graph",
};

export const STEP4_MODAL = {
    TITLE: "Optional Tool Placement",
    TEXT: "The required steps are complete. You can now proceed to the summary or, optionally, to tool placement.",
    NO_BTN: "Proceed to Tool Placement",
    YES_BTN: "Proceed to Summary",
};

export const STEP4_CEM_WARNING = {
    TITLE: "Warning",
    FOOTER_PREFIX:
        "For additional details and examples of “hotspots,” refer to the latest version of the",
    USER_MANUAL: "User Manual",
};

export const STEP4_PLOT_LEGEND = {
    PATIENT_PROFILE: "Patient Profile",
    USER_SELECTED: "User Selected",
    DEFAULT: "Default",
};

export const EncapsulationConfidenceScoreDefinition =
    "The Encapsulation Confidence Score (expressed from 0-100%) is a population-level " +
    "measure of confidence that the lesion contour contains all csPCa (GGG ≥ 2). " +
    "The score is based on the csPCa encapsulation rate observed using the same lesion " +
    "contour threshold in a retrospective study of whole mount data. For more information, consult the user manual.";

export const ChooseLesionSizeTooltip =
    "Using the interactive plot below, select a lesion contour that has the desired tradeoff between Encapsulation Confidence Score and lesion volume";

export const FLAIneligibleWarning =
    "Cancer lesion contour is larger than 50% of the prostate";
