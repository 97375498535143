import { EMPTY_STRING, USER_MANUAL_URL } from "../../constants";
import React from "react";

export const UPLOAD_STEP = 0;
export const SEG_MRI_STEP = 1;
export const BIOPSY_STEP = 2;
export const EDIT_MARGIN_STEP = 3;
export const TOOL_PLACEMENT_STEP = 4;
export const SUMMARY_STEP = 5;
export const FREEHAND_SUMMARY_STEP = 2;

export const DEFAULT_SELECT_OPTION = "Select An Option";
export const MAXIMUM_NAME_LENGTH = 256;
export const UPLOAD_CARD_NUMBER = 4;

export const VISIT_DATE_TEXT = "Visit Date";

export const parsingCardLabels = {
    BIOPSY: "BIOPSY",
    FREEHAND_BIOPSY: "FREEHAND BIOPSY",
    REPORT: "REPORT",
    MISC: "MISC",
    TEMPLATE: "TEMPLATE",
    MRI: "MRI",
    NONE: "NONE",
};

export const TEMPLATE_DOWNLOAD_FORMAT = {
    PDF: "Download template (.pdf)",
    DOCX: "Download template (.docx)",
};

export const TEMPLATE_PDF_URL =
    "https://avendahealth.com/unfold-ai-biopsy-tracking-pdf-template";

export const TEMPLATE_DOCX_URL =
    "https://avendahealth.com/unfold-ai-biopsy-tracking-template";

export const BIOPSY_UPLOAD_NUDGE_TEXT = (
    <p>
        Start by uploading a biopsy case. <br />
        For details on the operation of this software, click&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={USER_MANUAL_URL}>
            here
        </a>
        &nbsp;to download the latest version of the User Manual
    </p>
);

export const FREEHAND_BIOPSY_UPLOAD_NUDGE_TEXT = (
    <p>
        Start by uploading a patient MRI. <br />
        For details on the operation of this software, click&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={USER_MANUAL_URL}>
            here
        </a>
        &nbsp;to download the latest version of the User Manual
    </p>
);

export const PATIENT_DETAILS_NUDGE_TEXT =
    "Confirm accuracy of a patient details";

export const CREATE_SEGMENTATION_NUDGE_TEXT =
    "Use the add segmentation button to create one if none exist";

export const uploadCardText = {
    BIOPSY_UPLOAD_COMPLETE: "Biopsy upload complete",
    BIOPSY_UPLOAD_DEFAULT:
        "Drag and drop biopsy data [.zip / folder] here or click to upload a folder",
    PATH_UPLOAD_COMPLETE: "Pathology report upload complete",
    TEMPLATE_UPLOAD_COMPLETE: "Supportive documentation upload complete",
    PATH_UPLOAD_DEFAULT:
        "Drag and drop or click to upload pathology report  [.pdf/.docx/.xlsx/.txt/.rtf/.csv/.tsv/.jpeg/.jpg/.png/.heic/.bmp/.tiff]",
    TEMPLATE_UPLOAD_DEFAULT:
        "Optional: Drag and drop or click to upload supportive documentation (i.e. Fusion Biopsy Cores Tracking Template) [.pdf/.docx/.xlsx/.txt/.rtf/.csv/.tsv/.jpeg/.jpg/.png/.heic/.bmp/.tiff]",
    MRI_UPLOAD_FOUND: "MRI upload found in patient data",
    MRI_UPLOAD_COMPLETE: "MRI upload complete",
    MRI_UPLOAD_DEFAULT: "Drag and drop or click to upload patient MRI folder",
};

export const EXCLUDE_FILE_LIST = [
    "**/*.DS_Store",
    "**/REPORT/PDF_IMAGES/*",
    "**/*MR_DeformedSurface.bin",
    "**/SEGMENT/SUR00000*",
    "**/FUSION/*",
    "**/LEGACY/*",
    "**/SCREEN/*",
    "**/REPORT/Target*",
    "**/SEGMENT/SUR00000*",
    "**/SEGMENT/BOUNDARY.bpf",
    "**/NEDLESEQ/*.txt",
    "**/CASEDIR*",
    "**/3DDATA/Registered*",
];

export const uploadStates = {
    INIT: 0,
    UPLOADING: 1,
    PARSING: 2,
    COMPLETE: 3,
    FAILED: 4,
};

export const keyPatientInfoCardPatientDetailFields = {
    F_NAME: "First Name",
    M_NAME: "Middle Name",
    L_NAME: "Last Name",
    P_MRN: "ID",
    DOB: "Date Of Birth",
    RACE: "Race",
    ETH: "Ethnicity",
    PSA: "PSA",
};

export const keyPatientInfoCardValidationPatterns = new Map([
    [
        keyPatientInfoCardPatientDetailFields.F_NAME,
        /^[a-zA-Z0-9]{1,64}([- ][a-zA-Z0-9]{1,64}){0,63}$/,
    ],
    [
        keyPatientInfoCardPatientDetailFields.M_NAME,
        /^(([a-zA-Z0-9]{1,64}([- ][a-zA-Z0-9]{1,64}){0,63})|.{0})$/,
    ],
    [
        keyPatientInfoCardPatientDetailFields.L_NAME,
        /^[a-zA-Z0-9]{1,64}([- ][a-zA-Z0-9]{1,64}){0,63}$/,
    ],
    [
        keyPatientInfoCardPatientDetailFields.P_MRN,
        /^(([a-zA-Z0-9.,():-]{1,64}([- ][a-zA-Z0-9.,():-]{1,64}){0,63})|.{0})$/,
    ],
    [
        keyPatientInfoCardPatientDetailFields.DOB,
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
    ],
    [
        keyPatientInfoCardPatientDetailFields.PSA,
        /^(0*[1-9][0-9]{0,2}(\.[0-9]{1,4}|)|0\.0*[1-9][0-9]{0,4})$/,
    ],
]);

export const keyPatientInfoCardFieldErrors = new Map([
    [
        keyPatientInfoCardPatientDetailFields.F_NAME,
        "Must be letters or numbers",
    ],
    [
        keyPatientInfoCardPatientDetailFields.M_NAME,
        "Must be letters or numbers",
    ],
    [
        keyPatientInfoCardPatientDetailFields.L_NAME,
        "Must be letters or numbers",
    ],
    [
        keyPatientInfoCardPatientDetailFields.P_MRN,
        "Can only contain these symbols: .,():-",
    ],
    [keyPatientInfoCardPatientDetailFields.DOB, "Must be yyyy-mm-dd "],
    [keyPatientInfoCardPatientDetailFields.ETH, "Please Select"],
    [keyPatientInfoCardPatientDetailFields.RACE, "Please Select"],
    [keyPatientInfoCardPatientDetailFields.PSA, "Please Enter PSA"],
]);

export const keyPatientInfoCardFieldUnitsMap = new Map([
    [keyPatientInfoCardPatientDetailFields.F_NAME, EMPTY_STRING],
    [keyPatientInfoCardPatientDetailFields.M_NAME, EMPTY_STRING],
    [keyPatientInfoCardPatientDetailFields.L_NAME, EMPTY_STRING],
    [keyPatientInfoCardPatientDetailFields.P_MRN, EMPTY_STRING],
    [keyPatientInfoCardPatientDetailFields.DOB, EMPTY_STRING],
    [keyPatientInfoCardPatientDetailFields.PSA, "ng/ml"],
]);

export const RACIAL_CATEGORIES = [
    "American Indian or Alaska Native",
    "Asian",
    "Black or African American",
    "Native Hawaiian or Other Pacific Islander",
    "White",
    "Other",
    "Unknown",
    "Declined to Specify",
];

export const ETHNICITY_CATEGORIES = [
    "Hispanic or Latino",
    "Not Hispanic or Latino",
    "Unknown",
    "Declined to Specify",
];
