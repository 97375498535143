import React from "react";
import "./styles.css";
import { connect } from "react-redux";
import { isEmpty } from "../../../helpers/helpers";
import PropTypes from "prop-types";
import PatientAnatomyModel from "../Viewers/Structures/PatientAnatomy/PatientAnatomyModel";
import MRIModel from "../Viewers/Structures/MRI/MRIModel";
import TargetSetModel from "../Viewers/Structures/Targets/TargetSetModel";
import CPMColorKey from "../CPMColorKey";
import {
    addBxCores,
    addCancerMap,
    addFilterItem,
    addMRIOptions,
    getActiveProstate,
} from "./helpers";
import { sceneFilterNames, structureColorPalette } from "../../../constants";

class FilterToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayModal: true,
            mriOptionsVisible: false,
            forceUpdate: 0,
            autoLevel: false,
        };

        this.toggleOptions = this.toggleOptions.bind(this);
        this.getModal = this.getModal.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.getFilterItems = this.getFilterItems.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
        this.toggleMRIOptions = this.toggleMRIOptions.bind(this);
        this.handleAutoLvlClick = this.handleAutoLvlClick.bind(this);
    }

    addListeners() {
        document.addEventListener("wheel", this.scrollHandler);
    }

    removeListeners() {
        document.removeEventListener("wheel", this.scrollHandler);
    }

    componentDidMount() {
        document.addEventListener("mouseenter", this.addListeners);
        document.addEventListener("mouseleave", this.removeListeners);
        return true;
    }

    componentWillUnmount() {
        document.removeEventListener("mouseenter", this.addListeners);
        document.removeEventListener("mouseleave", this.removeListeners);
        return true;
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        return true;
    }

    scrollHandler(e) {
        e.stopPropagation();
    }

    toggleMRIOptions() {
        this.setState({
            mriOptionsVisible: !this.state.mriOptionsVisible,
        });
    }

    toggleOptions() {
        this.setState({ displayModal: !this.state.displayModal });
        return true;
    }

    handleAutoLvlClick(event) {
        this.props.mriModel.autoLevel = !this.props.mriModel.autoLevel;
        this.setState({ autoLevel: this.props.mriModel.autoLevel });
        event.stopPropagation();
        return true;
    }

    getFilterItems() {
        let pam = this.props.patientAnatomyModel;
        if (!pam) {
            return;
        }

        let items = [];
        addMRIOptions(this, items);
        addCancerMap(this, items);
        addFilterItem(
            items,
            pam.prostate,
            sceneFilterNames.PROSTATE,
            structureColorPalette.PROSTATE
        );
        addFilterItem(
            items,
            pam.rois,
            sceneFilterNames.ROI,
            structureColorPalette.ROI_CS_CANCER
        );
        addBxCores(this.props, items);
        addFilterItem(
            items,
            pam.margin,
            sceneFilterNames.MARGIN,
            structureColorPalette.TX_MARGIN
        );
        addFilterItem(
            items,
            pam.tumorGroundTruth,
            sceneFilterNames.TUMOR,
            structureColorPalette.TUMOR_GROUND_TRUTH
        );
        addFilterItem(
            items,
            this.props.targetSetModel,
            sceneFilterNames.LASER_CATHETER,
            structureColorPalette.LASER_CATHETER
        );
        if (
            this.props.displaySettings != null &&
            this.props.displaySettings.axesEnabled
        ) {
            let prostate = getActiveProstate(this.props) || {};
            addFilterItem(
                items,
                prostate.prostateAxes,
                sceneFilterNames.AXES,
                structureColorPalette.PROSTATE
            );
        }
        return items;
    }

    getModal(displayModal, items) {
        if (!displayModal || items == null || items.length < 1) {
            return null;
        }

        return (
            <div
                className={"filter-widget-menu-options"}
                style={{ zIndex: this.props.style.zIndex }}
                onWheel={this.scrollHandler}
            >
                {items}
            </div>
        );
    }

    render() {
        let items = this.getFilterItems();
        let modal = this.getModal(this.props.active, items);
        let displayCPMColorKey =
            this.props.mriModel.lutEnabled && this.props.mriModel.lutVisibility;

        return (
            <React.Fragment>
                <div
                    className="filter-widget"
                    ref={this.setWrapperRef}
                    onWheel={this.scrollHandler}
                >
                    {modal}
                </div>
                {displayCPMColorKey && <CPMColorKey />}
            </React.Fragment>
        );
    }
}

FilterToggle.propTypes = {
    patientAnatomyModel: PropTypes.instanceOf(PatientAnatomyModel),
    mriModel: PropTypes.instanceOf(MRIModel),
    targetSetModel: PropTypes.instanceOf(TargetSetModel),
    plan: PropTypes.object,
    displaySettings: PropTypes.object,
    ignoreBxCoreLabels: PropTypes.bool,
    active: PropTypes.bool,
    style: PropTypes.object,
    id: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
};

FilterToggle.defaultProps = {
    style: {
        zIndex: 1,
    },
    displaySettings: {
        mriModalEnabled: true,
    },
    active: false,
    ignoreBxCoreLabels: false,
};

/* istanbul ignore next */
const mapStateToProps = function (state, ownProps) {
    let displaySettings = isEmpty(ownProps.displaySettings)
        ? state.ImageViewerReducer.displaySettings
        : ownProps.displaySettings;

    return {
        plan: state.CreatePlanReducer.currentTreatmentPlan.TreatmentPlan,
        displaySettings: displaySettings,
    };
};

export default connect(mapStateToProps)(FilterToggle);
