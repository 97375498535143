import React from "react";
import { GradientDefs, LineSeries, XAxis, YAxis } from "react-vis";
import { TurboColorMapColorsSHIFTED } from "../../../__shared__/Viewers/Structures/MRI/consts";

export function getCrossHairs(x, y, color = "#ffffff") {
    return (
        <LineSeries
            strokeDasharray={"8"}
            color={color}
            strokeWidth={3}
            opacity={0.5}
            data={[
                { x: 0, y: y },
                {
                    x: x,
                    y: y,
                },
                { x: x, y: 0 },
            ]}
        />
    );
}

export function getPlotXAxis() {
    const fudgeFactor = 2; // Otherwise the last 0 in 100 gets cut off
    return (
        <XAxis
            title="Lesion Size (as % of Prostate Volume)"
            tickValues={[0, 25, 50, 75, 100 - fudgeFactor]}
            tickSize={0}
            tickFormat={(t) => (t >= 100 - fudgeFactor ? 100 : t)}
            style={{
                line: { stroke: "#ffffff", strokeWidth: "2px" },
                text: {
                    stroke: "none",
                    fill: "#ffffff",
                    fontSize: "14px",
                    fontWeight: 600,
                },
                title: {
                    fontSize: 16,
                    stroke: "#ffffff",
                    strokeWidth: "0px",
                    fill: "#ffffff",
                },
            }}
        />
    );
}

export function getPlotYAxis() {
    return (
        <YAxis
            title="Encapsulation Score"
            tickValues={[0, 25, 50, 75, 100]}
            tickSize={0}
            style={{
                line: { stroke: "#ffffff", strokeWidth: "2px" },
                text: {
                    stroke: "none",
                    fill: "#ffffff",
                    fontSize: "14px",
                    fontWeight: 600,
                },
                title: {
                    fontSize: 16,
                    stroke: "#ffffff",
                    strokeWidth: "0px",
                    fill: "#ffffff",
                },
            }}
        />
    );
}

export function getMarksScoreGradient(curveTuple) {
    const EPS = 1 - 0.00001;
    const marksScoreAnchorPoints = [
        0.0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6,
        0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0,
    ];

    let msCurve = curveTuple.marksScore;
    let cpmCurve = curveTuple.cpmThreshold;
    let correspondingCpmValues = [];

    for (let x of marksScoreAnchorPoints) {
        for (let i = msCurve.length - 1; i >= 0; --i) {
            if (msCurve[parseInt(i)] >= x) {
                let firstIdx = i === msCurve.length - 1;
                let x0 = firstIdx ? 0 : msCurve[parseInt(i + 1)];
                let x1 = msCurve[parseInt(i)];

                let y0cpm = firstIdx ? EPS : cpmCurve[parseInt(i + 1)];
                let y1cpm = cpmCurve[parseInt(i)];

                let xDif = x1 - x0; // Need to check for vertical lines with xDif

                let yCpm = !xDif
                    ? y1cpm
                    : y0cpm + (x - x0) * ((y1cpm - y0cpm) / (x1 - x0));

                correspondingCpmValues.push(yCpm);
                break;
            }
        }
    }

    let marksScoreHexColorPairings = [];
    for (let i = 0; i < correspondingCpmValues.length - 1; i++) {
        let cpmVal = correspondingCpmValues[parseInt(i)];
        let cpmValCorrected = lhmLogTransform(cpmVal);

        for (let turboVal of TurboColorMapColorsSHIFTED) {
            if (turboVal[0] >= cpmValCorrected) {
                let hexcode = rgbToHex(
                    Math.floor(turboVal[1] * 255),
                    Math.floor(turboVal[2] * 255),
                    Math.floor(turboVal[3] * 255)
                );

                marksScoreHexColorPairings.push(
                    <stop
                        key={`gradient-stop-${i}`}
                        offset={`${Math.max(
                            0,
                            Math.min(
                                100,
                                100 * (1 - marksScoreAnchorPoints[parseInt(i)])
                            )
                        )}%`}
                        stopColor={hexcode}
                        stopOpacity={1.0}
                    />
                );

                break;
            }
        }
    }

    return (
        <GradientDefs>
            <linearGradient
                id="marks-score-turbo-color-map-gradient"
                x1="0"
                x2="0"
                y1="0"
                y2="1"
            >
                {marksScoreHexColorPairings.reverse()}
            </linearGradient>
        </GradientDefs>
    );
}

const logConstScale = 103.06;
const logConstInputOffset = 0.1;
const logConstOutputOffset = 262.03;
const _lhmLogTransform = (value) =>
    logConstScale * Math.log(value + logConstInputOffset) +
    logConstOutputOffset;
const lhmLogTransform = (value) =>
    // Log transform used to map between raw LHM probability values and the display-friendly equivalent used
    // in the LHM Heatmap display
    (_lhmLogTransform(value) - _lhmLogTransform(0)) / 255;
const rgbToHex = (r, g, b) =>
    "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
