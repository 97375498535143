import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { rootStore, stickyStore } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "./router";
import "./styles/standard_styles.css";
import "./styles/shared_styles.css";
import "./index.css";
import Bowser from "bowser";
import BrowserSplash from "./components/__shared__/BrowserSplash";
import { MINIMUM_CHROME_VERISON } from "./constants";

class Index extends Component {
    isSupportedBrowser() {
        let parsedResult = Bowser.parse(window.navigator.userAgent);
        let browser = parsedResult.browser;
        let platform = parsedResult.platform;

        return (
            (browser.name === "Chrome" || browser.name === "Chromium") &&
            parseInt(browser.version) >= MINIMUM_CHROME_VERISON &&
            platform.type === "desktop"
        );
    }

    render() {
        if (this.isSupportedBrowser()) {
            return (
                <Provider store={rootStore}>
                    <PersistGate loading={null} persistor={stickyStore}>
                        <AppRouter />
                    </PersistGate>
                </Provider>
            );
        } else {
            return <BrowserSplash />;
        }
    }
}

ReactDOM.render(<Index />, document.getElementById("root"));
