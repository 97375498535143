import React, { Component } from "react";
import TopBar from "../__shared__/TopBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import SearchBar from "./SearchBar";
import "./styles.css";
import ProfileMenu from "../__shared__/ProfileMenu";
import PatientHistory from "./PatientHistory";
import { getUserPatientsMetadata, getNotifications } from "./helpers";
import { connect } from "react-redux";
import {
    resetCreatePlanShard,
    setCurrentPatientUUID,
    setCurrentPlanUUID,
    setCurrentTreatmentPlan,
    setCurrentVisitUUID,
    setFreehandPlan,
    setPlanCreationStepMetaData,
    setUploadState,
} from "../CreateTPlan_1Upload/actions";
import {
    EMPTY_STRING,
    loginReducerFlags,
    stepMetaData,
    stepPath,
    stepNumber,
    SUCCESS,
    clientErrorMessages,
    freehandStepMetaData,
} from "../../constants";
import { withRouter } from "react-router-dom";
import { getTreatmentPlan } from "../CreateTPlan_1Upload/helpers";
import { uploadStates } from "../CreateTPlan_1Upload/constants";
import { buttonNames, lockedErrorMessage } from "./constants";
import { setFlag } from "../Login/actions";
import BubbleContainer from "./BubbleContainer";
import { resetImageViewerShard } from "../../redux/image_viewer/actions";
import PropTypes from "prop-types";
import { setErrorState } from "../../redux/error_banner/actions";
import { rootStore } from "../../redux/store";
import DownloadSelectCard from "../__shared__/DownloadSelectCard";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import { getFreehandWorkflowAdjustedStep } from "../../helpers/helpers";
import ProgressBar from "./ProgressBar";
import AHButton from "../__shared__/StyleGuide/AHButton";
import ClickOutside from "../__shared__/ClickOutside";
import xbtn from "../../assets/gray_x.svg";
import {
    acquirePlanLock,
    checkPlanLocked,
    checkSharedPatient,
    releasePlanLock,
} from "../../helpers/backend_api";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientsFetchedData: [],
            loadComplete: false,
            loadFailed: false,
            showPatientList: false,
            toggleChangeNotification: false,
            notifications: null,
            plan: null,
        };

        this.handleResumePlan = this.handleResumePlan.bind(this);
        this.setPlanInShard = this.setPlanInShard.bind(this);
        this.getUserPatientsMetadataWrapper =
            this.getUserPatientsMetadataWrapper.bind(this);
        this.showDownloadCard = this.showDownloadCard.bind(this);
        this.canAcquirePlanLock = this.canAcquirePlanLock.bind(this);
        this.handleNotifications = this.handleNotifications.bind(this);
        this.checkNotification = this.checkNotification.bind(this);
        this.formatChangeKey = this.formatChangeKey.bind(this);
        this.formatTimestamp = this.formatTimestamp.bind(this);
        this.resumePlan = this.resumePlan.bind(this);
    }

    async componentDidMount() {
        await releasePlanLock({
            token: this.props.authToken,
            useruuid: this.props.useruuid,
            planuuid: this.props.planuuid,
        });
        this.props.resetCreatePlanShard();
        this.props.resetImageViewerShard();
        this.getUserPatientsMetadataWrapper();
    }

    getUserPatientsMetadataWrapper() {
        getUserPatientsMetadata({
            useruuid: this.props.useruuid,
            token: this.props.authToken,
        }).then((result) => {
            if (result && result.status === SUCCESS) {
                this.setState({
                    patientsFetchedData: result.plansMetadata,
                    loadComplete: true,
                });
            } else {
                this.setState({
                    loadFailed: true,
                });
            }
        });
    }

    onAddPatientClick = () => {
        this.props.history.push(stepPath.STEP1_1);
        this.props.setFlag({
            flagId: loginReducerFlags.PLAN_COMPLETED,
            newFlagState: false,
        });
    };

    showHide = () => {
        this.setState((prevState) => ({
            showPatientList: !prevState.showPatientList,
        }));
    };

    onListPatientClick = async () => {
        await this.showHide();
    };

    async setPlanInShard(patientuuid, planuuid) {
        let scope = this;
        let plan;
        await getTreatmentPlan(this.props.authToken, patientuuid, planuuid)
            .then((payload) => {
                return payload.json();
            })
            .then((json) => {
                plan = json.payload.plan;
                scope.props.setCurrentTreatmentPlan(plan);
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.TPLAN_RETRIEVE_FAILED
                    )
                );
            });
        return plan;
    }

    canAcquirePlanLock(checkPlanLockedResponse) {
        return (
            checkPlanLockedResponse.status === SUCCESS &&
            (!checkPlanLockedResponse.planLocked ||
                (checkPlanLockedResponse.planLocked &&
                    checkPlanLockedResponse.lockHolderUserUuid ===
                        this.props.useruuid))
        );
    }

    /**
     * btnParams
     * @property {string} planId - planUuid
     * @property {string} patientId - patientUuid
     * @property {string} visitId - visitUuid
     * @property {number} planStepPath - number for plan step
     * @property {bool} flagState - state to be set for planCompleted
     */
    async handleResumePlan(btnParams, buttonName) {
        if (buttonName === buttonNames.REVIEW) {
            this.setPlanShardBeforeResume(btnParams);
            return;
        }

        const checkSharedPatientResponse = await checkSharedPatient({
            patientuuid: btnParams.patientId,
            token: this.props.authToken,
        });

        if (!checkSharedPatientResponse) {
            this.setPlanShardBeforeResume(btnParams);
            return;
        }

        const checkPlanLockedResponse = await checkPlanLocked({
            token: this.props.authToken,
            planuuid: btnParams.planId,
        });

        if (this.canAcquirePlanLock(checkPlanLockedResponse)) {
            await acquirePlanLock({
                token: this.props.authToken,
                planuuid: btnParams.planId,
                useruuid: this.props.useruuid,
            });
            this.setPlanShardBeforeResume(btnParams);
        } else {
            window.location.reload();
            rootStore.dispatch(setErrorState(true, lockedErrorMessage));
        }
    }

    setPlanShardBeforeResume(btnParams) {
        //set redux shard
        this.props.setCurrentVisitUUID(btnParams.visitId);
        this.props.setCurrentPatientUUID(btnParams.patientId);
        this.props.setCurrentPlanUUID(btnParams.planId);
        this.setPlanInShard(btnParams.patientId, btnParams.planId).then(
            (plan) => {
                this.props.setFlag({
                    flagId: loginReducerFlags.PLAN_COMPLETED,
                    newFlagState: btnParams.flagState,
                });
                this.props.setUploadState(0, uploadStates.COMPLETE);

                if (TreatmentPlan.isMRIOnly(plan)) {
                    let freehandPathStep = getFreehandWorkflowAdjustedStep(
                        btnParams.planStepPath
                    );
                    this.props.setPlanCreationStepMetaData(
                        freehandStepMetaData[parseInt(freehandPathStep)]
                    );
                    this.props.setFreehandPlan(true);
                } else {
                    this.props.setPlanCreationStepMetaData(
                        stepMetaData[btnParams.planStepPath]
                    );
                    this.props.setFreehandPlan(false);
                }

                if (btnParams.planStepPath === stepNumber.SUMMARY) {
                    if (window.pendo) {
                        try {
                            window.pendo.track("Review Plan", {
                                planId: btnParams.planId,
                            });
                        } catch {
                            () => {};
                        }
                    }
                } else {
                    if (window.pendo) {
                        try {
                            window.pendo.track("Resume Plan", {
                                planId: btnParams.planId,
                            });
                        } catch {
                            () => {};
                        }
                    }
                }
                this.props.history.push(
                    Object.values(stepPath)[btnParams.planStepPath]
                );
            }
        );
    }

    async handleNotifications(planuuid) {
        let notifications = [];
        await getNotifications({
            useruuid: this.props.useruuid,
            planuuid: planuuid,
            token: this.props.authToken,
        }).then((payload) => {
            if (payload && payload.status === SUCCESS) {
                notifications = payload.changeNotificationList;
            }
        });
        return notifications;
    }

    showDownloadCard() {
        return this.props.downloadProperties.selectActive ? (
            <DownloadSelectCard
                patientuuid={this.props.downloadProperties.patientuuid}
                planuuid={this.props.downloadProperties.planuuid}
            />
        ) : (
            EMPTY_STRING
        );
    }

    formatChangeKey(changeKey) {
        return changeKey
            .replace(/(StepSaved|Saved)Data/g, "")
            .replace(/([a-z])([A-Z])/g, "$1 $2");
    }

    formatTimestamp(timestamp) {
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(
            new Date(timestamp)
        );
    }

    async checkNotification(
        event,
        plan,
        eventType = "PatientPlansSubListItem"
    ) {
        let savedEvent = { ...event, target: { ...event.target } };

        if (
            event.target.textContent === buttonNames.RESUME ||
            eventType === "BubbleContainer"
        ) {
            let changeList = await this.handleNotifications(plan.planUuid);

            if (
                changeList === null ||
                (Array.isArray(changeList) && changeList.length === 0)
            ) {
                this.resumePlan(savedEvent, plan);
            } else {
                changeList.sort(
                    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                );
                let notificationItemList = changeList.map((item, index) => (
                    <li key={index} className="notification-item pb-1">
                        <span className="font-medium">
                            {this.formatTimestamp(item.timestamp)}
                        </span>{" "}
                        - Changes made by{" "}
                        <span className="font-medium">
                            {item.updatedByUserInfo.firstName}{" "}
                            {item.updatedByUserInfo.lastName}
                        </span>{" "}
                        ({item.updatedByUserInfo.email}):
                        <ul className="list-disc pl-9 pt-1">
                            {Object.entries(item.changes).map(
                                ([changeKey, changeValue], idx) => (
                                    <li key={idx}>
                                        <span className="font-medium">
                                            {this.formatChangeKey(changeKey)}
                                            {changeKey !== "SavedData" && ":"}
                                        </span>{" "}
                                        {changeValue}
                                    </li>
                                )
                            )}
                        </ul>
                    </li>
                ));
                this.setState({
                    toggleChangeNotification: true,
                    plan: plan,
                    notifications: notificationItemList,
                });
            }
        } else if (event.target.textContent === buttonNames.REVIEW) {
            this.resumePlan(event, plan);
        }
    }

    resumePlan(event, plan) {
        let path = plan.SavedData.PlanCreationStep;
        let flagState = false;

        let btnParams = {
            planId: plan.planUuid,
            patientId: plan.patientUuid,
            visitId: plan.visitUuid,
            planStepPath: path,
            flagState: flagState,
        };
        this.handleResumePlan(btnParams, event.target.textContent);
    }

    render() {
        return (
            <div className="home-screen">
                <TopBar>
                    <AvendaLogo
                        id="create-plan-avenda-logo"
                        to={stepPath.HOME}
                    />
                    <SearchBar
                        patientsFetchedData={this.state.patientsFetchedData}
                        loadComplete={this.state.loadComplete}
                        checkNotification={this.checkNotification}
                    />
                    <ProfileMenu id="home-page-profile-menu" />
                </TopBar>
                <div className="home-screen-body large">
                    {this.state.showPatientList ? (
                        <PatientHistory
                            patientsFetchedData={this.state.patientsFetchedData}
                            loadComplete={this.state.loadComplete}
                            onClickClose={this.showHide}
                            checkNotification={this.checkNotification}
                        />
                    ) : (
                        <React.Fragment>
                            <AHButton
                                id="upload-new-patient-button"
                                className="center bottom-margin"
                                onClick={this.onAddPatientClick}
                                isOutline={false}
                                isSecondary={false}
                                isDynamic={false}
                                size={"large"}
                                text={"Upload New Patient"}
                            />

                            <AHButton
                                id="list-existing-pt-btn"
                                className="center top-margin click-outside-ignore"
                                onClick={this.onListPatientClick}
                                isOutline={false}
                                isSecondary={false}
                                isDynamic={false}
                                size={"large"}
                                text={"List Existing Patients"}
                            />

                            <BubbleContainer
                                patientData={this.state.patientsFetchedData}
                                checkNotification={this.checkNotification}
                                authToken={this.props.authToken}
                                userUuid={this.props.useruuid}
                            />
                        </React.Fragment>
                    )}

                    {this.showDownloadCard()}

                    <ProgressBar
                        loadComplete={this.state.loadComplete}
                        loadFailed={this.state.loadFailed}
                    />

                    {this.state.toggleChangeNotification &&
                        this.state.notifications && (
                            <>
                                <div
                                    style={{
                                        position: "fixed",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        zIndex: 1000,
                                    }}
                                />
                                <ClickOutside
                                    onClickOutsideCallback={() =>
                                        this.setState({
                                            toggleChangeNotification: false,
                                        })
                                    }
                                >
                                    <div id="change-notification-container">
                                        <div id="change-notification-title">
                                            <div className="text-2xl font-medium">
                                                Treatment Plan Has Been Updated
                                            </div>
                                        </div>
                                        <div id="change-notification-subtitle">
                                            <div>
                                                Please confirm to proceed to the
                                                treatment plan. Proceeding will
                                                restrict others from editing
                                                this plan until you exit or log
                                                out.
                                            </div>
                                        </div>
                                        <div id="change-list-container">
                                            <div id="change-list">
                                                {this.state.notifications}
                                            </div>
                                        </div>
                                        <AHButton
                                            id="change-notification-proceed-btn"
                                            isOutline={false}
                                            isSecondary={false}
                                            size={"large"}
                                            isDynamic={false}
                                            text={"Proceed"}
                                            className="center top-margin"
                                            onClick={(event) =>
                                                this.resumePlan(
                                                    event,
                                                    this.state.plan
                                                )
                                            }
                                        />
                                        <img
                                            id="change-notification-x-btn"
                                            className="click-outside-ignore"
                                            src={xbtn}
                                            alt="Exit"
                                            onClick={() =>
                                                this.setState({
                                                    toggleChangeNotification: false,
                                                })
                                            }
                                        />
                                    </div>
                                </ClickOutside>
                            </>
                        )}
                </div>
            </div>
        );
    }
}

Home.propTypes = {
    setCurrentVisitUUID: PropTypes.func,
    setCurrentPatientUUID: PropTypes.func,
    setCurrentPlanUUID: PropTypes.func,
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    planuuid: PropTypes.string,
    resetCreatePlanShard: PropTypes.func,
    resetImageViewerShard: PropTypes.func,
    setFlag: PropTypes.func,
    setPlanCreationStepMetaData: PropTypes.func,
    history: PropTypes.object,
    downloadProperties: PropTypes.object,
    setUploadState: PropTypes.func,
    setFreehandPlan: PropTypes.func,
};

Home.defaultProps = {
    useruuid: EMPTY_STRING,
};

const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        downloadProperties: state.HomeReducer.downloadProperties,
        planuuid: state.CreatePlanReducer.planuuid,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setFlag: (flag) => dispatch(setFlag(flag)),
        setCurrentVisitUUID: (visituuid) =>
            dispatch(setCurrentVisitUUID(visituuid)),
        setCurrentPatientUUID: (patientuuid) =>
            dispatch(setCurrentPatientUUID(patientuuid)),
        setCurrentPlanUUID: (planuuid) =>
            dispatch(setCurrentPlanUUID(planuuid)),
        setPlanCreationStepMetaData: (stepMetaData) =>
            dispatch(setPlanCreationStepMetaData(stepMetaData)),
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        resetCreatePlanShard: () => dispatch(resetCreatePlanShard()),
        setFreehandPlan: (isOn) => dispatch(setFreehandPlan(isOn)),
        resetImageViewerShard: () => dispatch(resetImageViewerShard()),
        setUploadState: (idx, upstate) =>
            dispatch(setUploadState(idx, upstate)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
