import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import RequirementListItem from "../RequirementListItem";
import RequirementList from "../RequirementList";
import ClickOutside from "../ClickOutside";
import DownArrow from "../IconComponents/DownArrow";
import PatientShareCard from "../PatientShareCard";
import Accordion from "../Accordion";
import LightBulb from "../IconComponents/LightBulb";
import LightBulbAlert from "../IconComponents/LightBulbAlert";
import { connect } from "react-redux";
import { planStageEnum } from "./constants";

// This feature will be deprecated
class RequirementsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
        this.makeItem = this.makeItem.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.getRequirementList = this.getRequirementList.bind(this);
        this.getOptionalList = this.getOptionalList.bind(this);
        this.areAllItemsComplete = this.areAllItemsComplete.bind(this);
    }

    makeItem(itemInfo) {
        return (
            <RequirementListItem
                id={itemInfo.id}
                satisfied={itemInfo.satisfied}
                listIndex={itemInfo.listIndex}
                reqText={itemInfo.reqText}
            />
        );
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        const planNotes =
            this.props.currentTreatmentPlan?.TreatmentPlan?.AdditionalData
                ?.PlanNotes || {};
        const needsReviewState =
            this.props.currentTreatmentPlan?.MetaData?.PlanStage;
        // If there is at least one note and the Plan Status is set to "needs review", open the RequirementsModal/Side Bar
        if (
            planNotes &&
            Object.keys(planNotes).length > 0 &&
            needsReviewState === planStageEnum.NEEDS_REVIEW
        ) {
            this.setState({ modalOpen: true });
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    closeModal() {
        if (this.state.modalOpen) {
            this.setState({ modalOpen: false });
        }
    }

    openModal() {
        if (!this.state.modalOpen) {
            this.setState({ modalOpen: true });
        }
    }

    getRequirementList(required) {
        let reqItemList = [];
        if (required != null) {
            required.forEach((itemInfo) =>
                reqItemList.push(this.makeItem(itemInfo))
            );
        }
        return reqItemList;
    }

    getOptionalList(optionals) {
        let optItemList = [];
        if (optionals != null) {
            optionals.forEach((itemInfo) =>
                optItemList.push(this.makeItem(itemInfo))
            );
        }
        return optItemList;
    }

    areAllItemsComplete(items) {
        if (items) {
            return items.every((item) => item.satisfied);
        }
    }

    render() {
        const areTasksAllComplete =
            !this.props.requirements.required ||
            this.areAllItemsComplete(this.props.requirements.required);
        const iconComponent = areTasksAllComplete ? LightBulb : LightBulbAlert;

        return (
            <ClickOutside onClickOutsideCallback={this.closeModal}>
                {this.state.modalOpen ? (
                    <div className="req-modal-container-open z-20">
                        <div className="req-modal-content">
                            <DownArrow
                                id="req-modal-btn-open"
                                className="req-modal-arrow-open"
                                stroke={getComputedStyle(
                                    document.body
                                ).getPropertyValue("--backgroundWhite")}
                                onClick={this.closeModal}
                            />
                            <PatientShareCard />
                            <Accordion
                                iconComponent={iconComponent}
                                title="Required Tasks"
                            >
                                <RequirementList
                                    requiredItems={this.getRequirementList(
                                        this.props.requirements.required
                                    )}
                                    optionalItems={this.getOptionalList(
                                        this.props.requirements.optionals
                                    )}
                                />
                            </Accordion>
                        </div>
                    </div>
                ) : (
                    <div
                        id="req-modal-btn-closed"
                        className="req-modal-container-closed-pull-tab"
                        onClick={this.openModal}
                    >
                        <DownArrow
                            className="req-modal-arrow-closed click-outside-ignore"
                            stroke={getComputedStyle(
                                document.body
                            ).getPropertyValue("--backgroundWhite")}
                        />
                    </div>
                )}
            </ClickOutside>
        );
    }
}

RequirementsModal.propTypes = {
    requirements: PropTypes.object,
    onRef: PropTypes.func,
    id: PropTypes.string,
    style: PropTypes.object,
    currentTreatmentPlan: PropTypes.object,
};

RequirementsModal.defaultProps = {
    requirements: {
        required: [
            { id: "req1", satisfied: true, reqText: "None", listIndex: 1 },
        ],
        optionals: [
            { id: "opt1", satisfied: true, reqText: "None", listIndex: 1 },
        ],
    },
    onRef: undefined,
};

const mapStateToProps = function (state) {
    return {
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

export default connect(mapStateToProps)(RequirementsModal);
