import React from "react";
import "./styles.css";
import AnimateHeight from "react-animate-height";
import PatientPlansSubList from "../PatientPlansSubList";
import {
    CONTAINER_HEIGHT,
    LIST_ITEM_HEIGHT,
    N_A,
    lockedPlanTooltipMsg,
} from "../constants";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import Lock from "../../__shared__/IconComponents/Lock";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { If, Then } from "react-if";

const CLOSED = LIST_ITEM_HEIGHT;
const TOGGLE_THRESHOLD = 5; //Number of plans required to display sorting toggles

class PatientListItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            planBox: CLOSED,
            isLocked: false,
        };

        this.openHeight = LIST_ITEM_HEIGHT + CONTAINER_HEIGHT;
        this.handleClick = this.handleClick.bind(this);
        this.setHeight = this.setHeight.bind(this);
        this.renderLockIcon = this.renderLockIcon.bind(this);
    }

    setHeight(props) {
        let numRows =
            props.plansData.length >= TOGGLE_THRESHOLD
                ? TOGGLE_THRESHOLD + 1
                : props.plansData.length;
        this.openHeight = LIST_ITEM_HEIGHT + numRows * LIST_ITEM_HEIGHT;
    }

    componentDidMount() {
        this.setHeight(this.props);
    }

    componentDidUpdate(prevProps) {
        this.setHeight(this.props);

        if (this.props.toggleClose !== prevProps.toggleClose) {
            this.setState({ planBox: CLOSED });
        }
    }

    handleClick() {
        this.setState({
            planBox: this.state.planBox === CLOSED ? this.openHeight : CLOSED,
        });
    }

    renderTooltipMsg() {
        const { patientData, plansData, userData } = this.props;

        if (!patientData || !plansData || plansData.length === 0) {
            return null;
        }

        const targetPlan = patientData.find(
            (patientPlan) =>
                patientPlan["MetaData"]["PlanUUID"] === plansData[0]["planUuid"]
        );

        if (
            !targetPlan["SavedData"]["PlanCreationCompleteFlag"] &&
            targetPlan["LockedByUser"] &&
            targetPlan["LockedByUser"]["uuid"] !== userData.uuid
        ) {
            this.setState({ isLocked: true });
            return (
                <Tooltip id="patient-list-item-tooltip">
                    {lockedPlanTooltipMsg(
                        targetPlan["LockedByUser"]["firstName"],
                        targetPlan["LockedByUser"]["lastName"],
                        targetPlan["LockedByUser"]["email"]
                    )}
                </Tooltip>
            );
        }

        return null;
    }

    renderLockIcon() {
        return (
            <If condition={this.state.isLocked}>
                <Then>
                    <OverlayTrigger
                        placement="right"
                        overlay={this.renderTooltipMsg()}
                    >
                        <div className="patient-lock-icon">
                            <Lock />
                        </div>
                    </OverlayTrigger>
                </Then>
            </If>
        );
    }

    render() {
        let inProgColor = this.props.inProgress
            ? "in-progress-list-item"
            : EMPTY_STRING;
        let className = `patient-list-item${
            this.state.planBox === this.openHeight
                ? " selectedItem"
                : EMPTY_STRING
        }`;

        return (
            <AnimateHeight
                id={this.props.listItemId}
                className={"anim-spec"}
                height={this.state.planBox}
                animateOpacity
            >
                <div
                    className={className}
                    style={this.props.style}
                    onClick={this.handleClick}
                >
                    <div className="patient-id-box">
                        <p className={`display-22 ${inProgColor} pendo-ignore`}>
                            {this.props.id}
                        </p>
                    </div>
                    <div className="patient-name-box">
                        <p className={`display-22 ${inProgColor} pendo-ignore`}>
                            {this.props.name}
                        </p>
                    </div>
                    <div className="patient-date-box">
                        <p className={`display-22 ${inProgColor} pendo-ignore`}>
                            {this.props.creationDate}
                        </p>
                    </div>
                    <div
                        className="patient-plan-stage-box"
                        id={`plan-stage-item-${this.props.patientIndex}`}
                    >
                        {this.props.planStage !== EMPTY_STRING ? (
                            <ul
                                id="plan-status-badge"
                                className="patient-plan-stage-badge"
                            >
                                <li>
                                    <p
                                        className={`display-14-medium ${inProgColor}`}
                                    >
                                        {this.props.planStage}
                                    </p>
                                </li>
                            </ul>
                        ) : null}
                    </div>
                    {this.renderLockIcon()}
                </div>
                <PatientPlansSubList
                    listItemId={this.props.listItemId}
                    plansData={this.props.plansData}
                    clickCallback={this.props.clickCallback}
                    checkNotification={this.props.checkNotification}
                    userData={this.props.userData}
                    authToken={this.props.authToken}
                    isLocked={this.state.isLocked}
                    tooltipMsg={this.renderTooltipMsg()}
                />
            </AnimateHeight>
        );
    }
}

PatientListItem.propTypes = {
    //Data for all plans associated with a single patient
    plansData: PropTypes.arrayOf(PropTypes.object),
    patientData: PropTypes.array,
    //Toggle to close the Plan sub list container so patients can be sorted
    toggleClose: PropTypes.bool,
    //ID for the list item
    listItemId: PropTypes.string,
    patientIndex: PropTypes.number,
    //Name of Patient
    name: PropTypes.string,
    //Patient MRN
    id: PropTypes.string,
    creationDate: PropTypes.string,
    planStage: PropTypes.string,
    //Function to callback from plan Download Icon click
    clickCallback: PropTypes.func,
    //Loading in progress
    inProgress: PropTypes.bool,
    style: PropTypes.object,
    checkNotification: PropTypes.func,
    userData: PropTypes.object,
    authToken: PropTypes.string,
};

PatientListItem.defaultProps = {
    plansData: [],
    toggleClose: false,
    name: N_A,
    id: N_A,
    creationDate: N_A,
    planStage: EMPTY_STRING,
    inProgress: false,
    userData: {},
    authToken: EMPTY_STRING,
};

const mapStateToProps = function (state) {
    return {
        authToken: state.LoginReducer.authToken,
        userData: state.LoginReducer.userData,
    };
};

export default withRouter(connect(mapStateToProps)(PatientListItem));
