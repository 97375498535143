import { useState } from "react";
import PropTypes from "prop-types";
import XBadge from "../../__shared__/IconComponents/XBadge";
import { STEP4_PLOT_LEGEND } from "../constants";

function MarksScoreLegend(props) {
    const [closeBtnVisible, setCloseBtnVisible] = useState(false);

    return (
        <div
            className="absolute bottom-20 right-6 w-32 rounded bg-[#393939] px-2 pt-1 text-sm"
            onMouseEnter={() => setCloseBtnVisible(true)}
            onMouseLeave={() => setCloseBtnVisible(false)}
        >
            <div className="flex items-center gap-1">
                <div className="relative">
                    <div
                        className="h-4 w-4 rounded-full"
                        style={{
                            background:
                                "conic-gradient(#0066ff 45deg, #00aaff 90deg, #00ffc7 135deg, #00fc1e 180deg, #fdbf00 225deg, #fc5000 270deg, #e72a2a 360deg)",
                        }}
                    />
                    <div className="absolute bottom-1 left-1 h-2 w-2 rounded-full bg-[#393939]" />
                </div>
                <div>{STEP4_PLOT_LEGEND.PATIENT_PROFILE}</div>
            </div>
            <div className="flex items-center gap-1">
                <div className="h-4 w-4 rounded-full border-2 border-[#a82ca0] bg-[#f542e9]" />
                <div>{STEP4_PLOT_LEGEND.USER_SELECTED}</div>
            </div>
            <div className="flex items-center gap-1">
                <div className="h-4 w-4 rounded-full border-2 border-[#b1b1b1] bg-[#ffffff]" />
                <div>{STEP4_PLOT_LEGEND.DEFAULT}</div>
            </div>
            {closeBtnVisible && (
                <button
                    className="absolute right-[-6px] top-[-6px] h-4 w-4 rounded-full bg-[#696969] hover:bg-[#898989]"
                    onClick={props.closeLegend}
                    aria-label="Close legend button"
                >
                    <XBadge />
                </button>
            )}
        </div>
    );
}

MarksScoreLegend.propTypes = {
    closeLegend: PropTypes.func,
};
export default MarksScoreLegend;
