import React, { Component } from "react";
import { connect } from "react-redux";
import pprStack from "../../../assets/paper_stack.svg";
import "./styles.css";
import { setUploadState } from "../actions";
import { EMPTY_STRING } from "../../../constants";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
    TEMPLATE_DOWNLOAD_FORMAT,
    parsingCardLabels,
    TEMPLATE_PDF_URL,
    TEMPLATE_DOCX_URL,
} from "../constants";
import { If, Then, Else } from "react-if";
import QuestionMark from "../../__shared__/IconComponents/QuestionMark";

class UploadStartCard extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.getFileInput = this.getFileInput.bind(this);
        this.getFolderInput = this.getFolderInput.bind(this);
    }

    openFileDialog() {
        this.fileInputRef.current.click();
    }

    onChange(e) {
        if (!this.props.folderSelect) {
            const files = e.target.files;

            if (files.length > 0) {
                this.props.onFilesAdded(files);
            }
        } else {
            const inputFieldFileList = e.target && e.target.files;
            const fileList = inputFieldFileList || [];

            if (fileList.length > 0) {
                this.props.onFolderAdded(fileList);
            }
        }
    }

    onDrop(e) {
        if (
            e.dataTransfer != null &&
            e.dataTransfer.items != null &&
            e.dataTransfer.items.length > 0
        ) {
            let itemType = e.dataTransfer.items[0].type;
            if (itemType === EMPTY_STRING) {
                this.props.onFolderDropped(e.dataTransfer.items[0]);
            } else {
                this.props.onFileDropped(e.dataTransfer.items[0]);
            }
        }
    }

    getFileInput() {
        return (
            <input
                id={"upload-start-card-input-file-" + this.props.idx}
                ref={this.fileInputRef}
                className="upload-file-input"
                type="file"
                onChange={this.onChange}
            />
        );
    }

    getFolderInput() {
        return (
            <input
                id={"upload-start-card-input-folder-" + this.props.idx}
                ref={this.fileInputRef}
                className="upload-file-input"
                type="file"
                onChange={this.onChange}
                webkitdirectory=""
            />
        );
    }

    getToolTipText() {
        switch (this.props.label) {
            case parsingCardLabels.BIOPSY: {
                return parsingToolTipText.BIOPSY;
            }
            case parsingCardLabels.REPORT: {
                return parsingToolTipText.PATH_REPORT;
            }
            case parsingCardLabels.TEMPLATE: {
                return parsingToolTipText.TEMPLATE;
            }
            case parsingCardLabels.MRI: {
                return parsingToolTipText.MRI;
            }
            default:
                break;
        }
    }

    render() {
        const renderTooltip = (props) => (
            <Tooltip id={"parsing-faq-tool-tip"} {...props}>
                {this.getToolTipText()}
            </Tooltip>
        );

        return (
            <div
                id={"upload-start-card-" + this.props.idx}
                className="upload-card waiting flex w-full justify-between pr-[5%]"
                style={this.props.style}
                onClick={this.openFileDialog}
                onDrop={this.onDrop}
            >
                <div id="upload-thumbnail" className="unselectable pl-[4%]">
                    <img src={pprStack} alt="paper stack icon" />
                </div>
                <div className="upload-text display-16">
                    <p className="basis-2/3" style={{ color: "#000000" }}>
                        {this.props.text}
                    </p>
                    {this.props.label === parsingCardLabels.TEMPLATE && (
                        <div className="flex basis-1/3 flex-col gap-6 pl-4">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={TEMPLATE_PDF_URL}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <p>{TEMPLATE_DOWNLOAD_FORMAT.PDF}</p>
                            </a>
                            <a
                                href={TEMPLATE_DOCX_URL}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {TEMPLATE_DOWNLOAD_FORMAT.DOCX}
                            </a>
                        </div>
                    )}
                </div>
                <If condition={this.props.folderSelect}>
                    <Then>{this.getFolderInput()}</Then>
                    <Else>{this.getFileInput()}</Else>
                </If>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <div className="choose-lesion-size-tooltip-container">
                        <QuestionMark />
                    </div>
                </OverlayTrigger>
            </div>
        );
    }
}

UploadStartCard.propTypes = {
    style: PropTypes.object,
    text: PropTypes.string,
    idx: PropTypes.number,
    onFilesAdded: PropTypes.func,
    onFolderAdded: PropTypes.func,
    onFolderDropped: PropTypes.func,
    onFileDropped: PropTypes.func,
    folderSelect: PropTypes.bool,
    label: PropTypes.string,
};

UploadStartCard.defaultProps = {
    style: null,
    text: EMPTY_STRING,
    idx: 0,
    folderSelect: false,
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setUploadState: (idx, upstate) =>
            dispatch(setUploadState(idx, upstate)),
    };
};

export default connect(null, mapDispatchToProps)(UploadStartCard);

export const parsingToolTipText = {
    BIOPSY: "The currently supported fusion biopsy import formats include: Eigen Artemis 1.4, 3.0, 3.4, Invivo Uronav 2.2",
    PATH_REPORT:
        "The currently supported pathology report formats include: [.pdf/.docx/.xlsx/.txt/.rtf/.csv/.tsv/.jpeg/.jpg/.png/.heic/.bmp/.tiff]",
    TEMPLATE:
        "The currently supported supportive documentation formats include: [.pdf/.docx/.xlsx/.txt/.rtf/.csv/.tsv/.jpeg/.jpg/.png/.heic/.bmp/.tiff]",
    MRI: "The currently supported MRI formats include: DICOM",
};
