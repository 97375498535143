import React, { Component } from "react";
import TopBar from "../__shared__/TopBar";
import ProfileMenu from "../__shared__/ProfileMenu";
import StepMarkerBar from "../__shared__/StepMarkerBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import PreviousStepButton from "../__shared__/PreviousStepButton";
import NextStepButton from "../__shared__/NextStepButton";
import { connect } from "react-redux";
import {
    SEG_MRI_STEP,
    BIOPSY_STEP,
    EDIT_MARGIN_STEP,
} from "../CreateTPlan_1Upload/constants";
import {
    setCurrentTreatmentPlan,
    setStepActive,
    setStepComplete,
} from "../CreateTPlan_1Upload/actions";
import "./styles.css";
import {
    getFileS3XML,
    getPHISignedURLS3,
    updateTreatmentPlan,
} from "../../helpers/backend_api";
import {
    setBiopsyError,
    setCoreLocations,
    setPathReportByteArray,
    setTemplateByteArray,
} from "./actions";
import { getMaxGleasonGradeGroupFromCores } from "./helpers";
import { computeBiopsyButtonLocations } from "./biopsy_button_calc";
import PathologyTable from "./PathologyTable";
import {
    pageLoadStates,
    stepPath,
    clientErrorMessages,
    stepPath as stepPathValue,
} from "../../constants";
import { getTreatmentPlan } from "../CreateTPlan_1Upload/helpers";
import RequirementsModal from "../__shared__/RequirementsModal";
import { getLoadingScreen, getSignedUrl } from "../../helpers/helpers";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../constants";
import { setErrorState } from "../../redux/error_banner/actions";
import { rootStore } from "../../redux/store";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import BiopsyCompleteAlertHandler from "./BiopsyCompleteAlertHandler";
import { If, Then } from "react-if";
import BiopsyPlotContainer from "./BiopsyPlotContainer";
import MRIModel from "../__shared__/Viewers/Structures/MRI/MRIModel";
import MRIController from "../__shared__/Viewers/Structures/MRI/MRIController";
import PatientAnatomyModel from "../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyModel";
import TargetSetModel from "../__shared__/Viewers/Structures/Targets/TargetSetModel";
import TargetSetController from "../__shared__/Viewers/Structures/Targets/TargetSetController";
import PatientAnatomyController from "../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyController";
import {
    legacy_loadBxCores,
    legacy_loadMRI,
    legacy_loadProstate,
    legacy_loadROIs,
} from "../../helpers/load_helpers";
import { setDebugHooks } from "../../helpers/debug/debug_hooks";
import MeshViewer from "../__shared__/Viewers/MeshViewer";
import BiopsyTemplateFrame from "./BiopsyTemplateFrame";
import ResetViewerButton from "../__shared__/ResetViewerButton";
import { RESET_VIEW } from "./constants";
import blueResetArrow from "../../assets/ccw_btn_blue.svg";
import PDFViewerWrapper from "./PDFViewerWrapper";

class CreateTPlan_3Biopsy extends Component {
    constructor(props) {
        super(props);
        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.requirements = {
            required: [
                {
                    id: "label-biopsy-req",
                    satisfied: false,
                    reqText:
                        "Label Biopsy Cores according to the Pathology Report",
                    listIndex: 1,
                },
            ],
            optionals: [],
        };

        this.state = {
            coreLoadState: pageLoadStates.LOADING,
            pdfLoadState: pageLoadStates.LOADING,
            meshViewerLoadState: pageLoadStates.LOADING,
            showAlerts: false,
        };

        this.meshRef = React.createRef();
        this.biopsyPlotContainerRef = React.createRef();

        const smallViewerCameraDistance = 150;
        this.mriModel = new MRIModel(false, smallViewerCameraDistance);
        this.mriModel.visibility = false;
        this.mriController = new MRIController(this.mriModel);
        this.patientAnatomyModel = new PatientAnatomyModel();
        this.targetSetModel = new TargetSetModel();
        this.targetSetController = new TargetSetController(
            this.targetSetModel,
            this.mriModel
        );
        this.patientAnatomyController = new PatientAnatomyController(
            this.patientAnatomyModel,
            this.targetSetModel
        );

        setDebugHooks({
            mriModel: this.mriModel,
            patientAnatomyModel: this.patientAnatomyModel,
            targetSetModel: this.targetSetModel,
        });

        this.isAnyCorePositiveCheck = this.isAnyCorePositiveCheck.bind(this);
        this.verifyBiopsyDataCallback =
            this.verifyBiopsyDataCallback.bind(this);
        this.setPdfLoaded = this.setPdfLoaded.bind(this);
        this.getLoadedState = this.getLoadedState.bind(this);
        this.callBiopsyPlotContainerFunction =
            this.callBiopsyPlotContainerFunction.bind(this);
    }

    componentDidMount() {
        let scope = this;
        getTreatmentPlan(
            this.props.authToken,
            this.props.patientuuid,
            this.props.planuuid
        )
            .then((payload) => payload.json())
            .then((json) => {
                let plan = json.payload.plan.TreatmentPlan;
                scope.props.setCurrentTreatmentPlan(json.payload.plan);

                let cores = computeBiopsyButtonLocations(plan);
                scope.props.setCoreLocations(cores);
                scope.getMRIURLs(json.payload.plan);
                scope.getMeshURLs(json.payload.plan);
                scope.setState({ coreLoadState: pageLoadStates.COMPLETE });
            })
            .catch(() => {
                scope.setState({ coreLoadState: pageLoadStates.COMPLETE });
            });

        const pathologyReportURI =
            this.props.currentTreatmentPlan?.TreatmentPlan?.PatientData
                .PathologyReportURI;
        const templateURI =
            this.props.currentTreatmentPlan?.TreatmentPlan?.PatientData
                ?.TemplateURI;
        if (pathologyReportURI?.length > 1) {
            getPHISignedURLS3({
                token: this.props.authToken,
                patientuuid: this.props.patientuuid,
                visituuid: this.props.visituuid,
                uris: [pathologyReportURI],
            })
                .then((payload) => payload.json())
                .then((json) => {
                    let url = getSignedUrl(json.payload.signedurls);
                    getFileS3XML(url)
                        .then((req) => {
                            let byteArray = new Uint8Array(req.response);
                            if (byteArray && byteArray.length > 0) {
                                scope.props.setPathReportByteArray(
                                    Array.from(byteArray)
                                );
                            }
                        })
                        .catch(() => {
                            scope.setPdfLoaded(pageLoadStates.COMPLETE);
                        });
                })
                .catch(() => {
                    scope.setPdfLoaded(pageLoadStates.FAILED);
                    this.setBannerError(
                        true,
                        clientErrorMessages.PATHREPORT_LOAD_FAILED
                    );
                });
        }
        if (templateURI?.length > 1) {
            getPHISignedURLS3({
                token: this.props.authToken,
                patientuuid: this.props.patientuuid,
                visituuid: this.props.visituuid,
                uris: [templateURI],
            })
                .then((payload) => payload.json())
                .then((json) => {
                    let url = getSignedUrl(json.payload.signedurls);
                    getFileS3XML(url)
                        .then((req) => {
                            let byteArray = new Uint8Array(req.response);
                            if (byteArray && byteArray.length > 0) {
                                scope.props.setTemplateByteArray(
                                    Array.from(byteArray)
                                );
                            }
                        })
                        .catch(() => {
                            scope.setPdfLoaded(pageLoadStates.COMPLETE);
                        });
                })
                .catch(() => {
                    scope.setPdfLoaded(pageLoadStates.FAILED);
                    this.setBannerError(
                        true,
                        clientErrorMessages.PATHREPORT_LOAD_FAILED
                    );
                });
        }
        if (templateURI?.length < 1 && pathologyReportURI?.length < 1) {
            scope.setPdfLoaded(pageLoadStates.FAILED);
        }
    }
    getMeshURLs(plan) {
        let scope = this;

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
        };

        legacy_loadBxCores(scope, plan);
        legacy_loadProstate(scope, plan, ids, false, true);
        legacy_loadROIs(scope, plan, ids);
    }

    getMRIURLs(plan) {
        let scope = this;

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
        };

        legacy_loadMRI(scope, plan, ids);
    }

    componentWillUnmount() {
        this.props.setPathReportByteArray(null);
        this.props.setTemplateByteArray(null);
    }

    prevStep() {
        this.props.setStepActive(BIOPSY_STEP, false);
        this.props.setStepComplete(BIOPSY_STEP, false);
        this.props.setStepActive(SEG_MRI_STEP, true);

        let plan = this.props.currentTreatmentPlan;
        plan.SavedData.PlanCreationStep = SEG_MRI_STEP;
        this.props.setCurrentTreatmentPlan(plan);

        updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        });
    }

    nextStep(stepPath) {
        if (this.isAnyCorePositiveCheck()) {
            let scope = this;
            let plan = this.props.currentTreatmentPlan;
            plan.SavedData.PlanCreationStep = EDIT_MARGIN_STEP;
            plan.TreatmentPlan.PatientData.GleasonGradeGroup =
                getMaxGleasonGradeGroupFromCores(
                    TreatmentPlan.getBiopsyCores(plan.TreatmentPlan)
                );

            // Automatically uncheck "Needs Review" box when a Cancer Extension Map (CEM) is generated
            if (stepPath === stepPathValue.STEP4_1) {
                plan.MetaData.PlanStage = EMPTY_STRING;
            }

            updateTreatmentPlan({
                useruuid: this.props.useruuid,
                patientuuid: this.props.patientuuid,
                planuuid: this.props.planuuid,
                token: this.props.authToken,
                plan: plan,
            })
                .catch(() => {
                    this.setBannerError(
                        true,
                        clientErrorMessages.TPLAN_UPDATE_FAILED
                    );
                })
                .finally(() => {
                    scope.props.history.push(stepPath);
                    scope.props.setStepComplete(BIOPSY_STEP, true);
                    scope.props.setStepActive(BIOPSY_STEP, false);
                    scope.props.setStepActive(EDIT_MARGIN_STEP, true);
                    scope.props.setCurrentTreatmentPlan(plan);
                });

            return true;
        } else {
            this.requirementsModalRef.openModal();
        }
        return false;
    }

    isAnyCorePositiveCheck() {
        let cores =
            this.props.currentTreatmentPlan.TreatmentPlan.StructureData.Biopsy;
        if (cores) {
            for (let i = 0; i < cores.length; i++) {
                if (cores[parseInt(i)].GleasonGradeGroup >= 1) {
                    return true;
                }
            }
        }
        return false;
    }

    getRequirements() {
        this.requirements.required[0].satisfied = this.isAnyCorePositiveCheck();
        return this.requirements;
    }

    checkErrorPresent() {
        setTimeout(() => true, 1000);
    }

    async verifyBiopsyDataCallback() {
        document.activeElement.blur();

        await this.checkErrorPresent();

        if (this.isAnyCorePositiveCheck()) {
            this.setState({
                showAlerts: true,
            });
            return true;
        } else if (this.requirementsModalRef.state.modalOpen) {
            this.requirementsModalRef.closeModal();
            return false;
        } else {
            this.requirementsModalRef.openModal();
            return false;
        }
    }

    setPdfLoaded(pageLoadState) {
        this.setState({ pdfLoadState: pageLoadState });
    }

    getLoadedState() {
        if (
            this.state.coreLoadState === pageLoadStates.COMPLETE &&
            (this.state.pdfLoadState === pageLoadStates.COMPLETE ||
                this.state.pdfLoadState === pageLoadStates.FAILED) &&
            this.state.meshViewerLoadState === pageLoadStates.COMPLETE
        ) {
            return pageLoadStates.COMPLETE;
        }
        if (this.state.coreLoadState === pageLoadStates.FAILED) {
            return pageLoadStates.FAILED;
        }

        return pageLoadStates.LOADING;
    }

    setBannerError(hasError, errMsg) {
        rootStore.dispatch(setErrorState(hasError, errMsg));
    }

    callBiopsyPlotContainerFunction() {
        if (this.biopsyPlotContainerRef.current) {
            this.biopsyPlotContainerRef.current.callBiopsyPlotFunction();
        }
    }

    resetViewer() {
        // Reset 3D Mesh Viewer
        this.meshRef.current.resetScene();
    }

    render() {
        let lhmDeleteInput = {
            patientuuid: this.props.patientuuid,
            visituuid: this.props.visituuid,
            fileURI: this.props.currentTreatmentPlan.SavedData.CPMURI,
            token: this.props.authToken,
        };

        return (
            <div className={"create-plan-biopsy-screen"}>
                <TopBar>
                    <AvendaLogo id={"create-plan-avenda-logo"} to={"/home"} />
                    <div className={"create-plan-step-bar-container"}>
                        <div className={"step-bar-prev-btn-group"}>
                            <PreviousStepButton
                                to={stepPath.STEP2}
                                callback={this.prevStep}
                                isReady={
                                    this.state.coreLoadState !==
                                    pageLoadStates.LOADING
                                }
                            />
                            <StepMarkerBar />
                        </div>
                        <NextStepButton
                            reqsComplete={this.isAnyCorePositiveCheck()}
                            reqsCompleteTxt={"Verify Data"}
                            to={stepPath.STEP3}
                            noLink={true}
                            callback={this.verifyBiopsyDataCallback}
                        />
                    </div>
                    <ProfileMenu />
                </TopBar>
                {getLoadingScreen(this.getLoadedState())}
                <RequirementsModal
                    onRef={(ref) => (this.requirementsModalRef = ref)}
                    requirements={this.getRequirements()}
                />
                <div className="biopsy-screen-body">
                    <PDFViewerWrapper
                        setPdfLoaded={this.setPdfLoaded}
                        currentTreatmentPlan={this.props.currentTreatmentPlan}
                    />
                    <BiopsyTemplateFrame
                        currentTreatmentPlan={this.props.currentTreatmentPlan}
                    >
                        <BiopsyPlotContainer
                            ref={this.biopsyPlotContainerRef}
                        />
                        <div className="mesh-viewer-frame">
                            <ResetViewerButton
                                toolTipText={RESET_VIEW}
                                style={{
                                    position: "absolute",
                                    top: "12px",
                                    right: "15px",
                                    zIndex: "1",
                                }}
                                width={"35px"}
                                imgBlue={blueResetArrow}
                                resetViewer={() => this.resetViewer()}
                            />
                            <MeshViewer
                                style={{
                                    position: "absolute",
                                    left: "0px",
                                    top: "0px",
                                }}
                                ref={this.meshRef}
                                staticViewer={true}
                                displaySettings={{
                                    mriEnabled: false,
                                    composerEnabled: true,
                                }}
                                targetSetController={this.targetSetController}
                                targetSetModel={this.targetSetModel}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                                patientAnatomyModel={this.patientAnatomyModel}
                                patientAnatomyController={
                                    this.patientAnatomyController
                                }
                                activateCoreClick={true}
                            />
                        </div>
                        <If
                            condition={
                                this.state.coreLoadState ===
                                pageLoadStates.COMPLETE
                            }
                        >
                            <Then>
                                <PathologyTable
                                    dataLoaded={this.state.coreLoadState}
                                    meshViewer={this.meshRef}
                                    biopsyPlot={this.biopsyPlotContainerRef}
                                    currentTreatmentPlan={
                                        this.props.currentTreatmentPlan
                                    }
                                    activeCore={this.props.activeCore}
                                    setActiveCore={this.props.setActiveCore}
                                    patientAnatomyController={
                                        this.patientAnatomyController
                                    }
                                    updateBiopsyPlot={
                                        this.callBiopsyPlotContainerFunction
                                    }
                                />
                            </Then>
                        </If>
                    </BiopsyTemplateFrame>
                </div>
                <If condition={this.state.showAlerts}>
                    <Then>
                        <BiopsyCompleteAlertHandler
                            step3CompleteCallback={this.nextStep}
                            setLHMError={this.setBannerError}
                            setAlertState={(alertState) => {
                                this.setState({ showAlerts: alertState });
                            }}
                            lhmDeleteInput={lhmDeleteInput}
                            currentTreatmentPlan={
                                this.props.currentTreatmentPlan
                            }
                            setCurrentTreatmentPlan={
                                this.props.setCurrentTreatmentPlan
                            }
                        />
                    </Then>
                </If>
            </div>
        );
    }
}

CreateTPlan_3Biopsy.propTypes = {
    authToken: PropTypes.string,
    useruuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    visituuid: PropTypes.string,
    setStepActive: PropTypes.func,
    setStepComplete: PropTypes.func,
    currentTreatmentPlan: PropTypes.object,
    setCurrentTreatmentPlan: PropTypes.func,
    setPathReportByteArray: PropTypes.func,
    setTemplateByteArray: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    activeCore: PropTypes.number,
    setActiveCore: PropTypes.func,
    setBiopsyError: PropTypes.func,
    bxError: PropTypes.string,
};

CreateTPlan_3Biopsy.defaultProps = {
    useruuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    location: {},
    bxError: EMPTY_STRING,
};

const mapStateToProps = function (state) {
    return {
        activeCore: state.CreatePlanBiopsyReducer.activeCore,
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
        bxError: state.CreatePlanBiopsyReducer.bxError,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
        setPathReportByteArray: (bytes) =>
            dispatch(setPathReportByteArray(bytes)),
        setTemplateByteArray: (bytes) => dispatch(setTemplateByteArray(bytes)),
        setCoreLocations: (cores) => dispatch(setCoreLocations(cores)),
        setBiopsyError: (bxError) => dispatch(setBiopsyError(bxError)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTPlan_3Biopsy);
