import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import ClickOutside from "../ClickOutside";
import AHButton from "../StyleGuide/AHButton";

/**
 * @todo Rewrite this modal component with React functional component with TS (PropTypes is deprecated) and Tailwind
 * @todo Add use case for both 1 and 2 button scenarios
 * @todo Consider separating "click outside" and "go back" logic (currently overridden with clickOutsideCallbackOverride)
 */
class DecisionAlertCard extends Component {
    constructor(props) {
        super(props);

        this.handleYesClick = this.handleYesClick.bind(this);
        this.handleNoClick = this.handleNoClick.bind(this);
    }

    handleYesClick() {
        this.props.yesBtnCallback();
    }

    handleNoClick() {
        this.props.noBtnCallback();
    }

    render() {
        return (
            <div className={`${this.props.id}-decision-alert-overlay`}>
                <ClickOutside
                    onClickOutsideCallback={
                        this.props.clickOutsideCallbackOverride ??
                        this.props.noBtnCallback
                    }
                >
                    <div
                        id={this.props.id}
                        className={"decision-alert-card"}
                        style={this.props.style}
                    >
                        <div className={"decision-alert-child-container"}>
                            {this.props.children}
                        </div>
                        <div className={"decision-alert-button-container"}>
                            {!this.props.singleBtn && (
                                <div className={"decision-alert-no-btn"}>
                                    <AHButton
                                        id={this.props.id + "-no-btn"}
                                        isOutline={true}
                                        isSecondary={true}
                                        onClick={this.handleNoClick}
                                        size={"medium"}
                                        text={this.props.noBtnTxt}
                                    />
                                </div>
                            )}
                            <div className={"decision-alert-yes-btn"}>
                                <AHButton
                                    id={this.props.id + "-yes-btn"}
                                    onClick={this.handleYesClick}
                                    size={"medium"}
                                    isDisabled={this.props.disableConfirm}
                                    text={this.props.yesBtnTxt}
                                />
                            </div>
                        </div>
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

DecisionAlertCard.propTypes = {
    noBtnCallback: PropTypes.func,
    yesBtnCallback: PropTypes.func,
    noBtnTxt: PropTypes.string,
    yesBtnTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
    disableConfirm: PropTypes.bool,
    singleBtn: PropTypes.bool,
    clickOutsideCallbackOverride: PropTypes.func,
};

DecisionAlertCard.defaultProps = {
    noBtnCallback: undefined,
    yesBtnCallback: undefined,
    noBtnTxt: "No",
    yesBtnTxt: "Yes",
    singleBtn: false,
};

export default DecisionAlertCard;
