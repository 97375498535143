import React from "react";
import { Form } from "react-bootstrap";
import { EMPTY_STRING } from "../../../constants";
import {
    formatLesionVolume,
    formatMarksScore,
    formatProstateCoverage,
    formatVolume,
} from "../helpers";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import QuestionMark from "../../__shared__/IconComponents/QuestionMark";
import Tooltip from "react-bootstrap/Tooltip";
import {
    EncapsulationConfidenceScoreDefinition,
    FLAIneligibleWarning,
    MAX_PROSTATE_COVERAGE,
} from "../constants";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import SpinLoader from "../../__shared__/SpinLoader";
import warningIconSrc from "../../../assets/WarningTriangle.svg";

function CancerLesionStatistics(props) {
    function getProstageCoverageWarning() {
        const coverage =
            props.currentTreatmentPlan.SavedData
                ?.UserSelectedLesionCoverageOfProstate;
        return coverage > MAX_PROSTATE_COVERAGE;
    }

    const prostateVolume = TreatmentPlan.getCoreStructure(
        props.currentTreatmentPlan.TreatmentPlan
    ).VolumeCC;

    const renderFaqTooltip = (props) => (
        // Override default Bootstrap Tooltip styles with custom class defined in index.css
        // Applying inline styles does not work
        <Tooltip {...props} className="r-step4-tooltip-width">
            {EncapsulationConfidenceScoreDefinition}
        </Tooltip>
    );

    const renderWarningTooltip = (props) => (
        <Tooltip {...props}>{FLAIneligibleWarning}</Tooltip>
    );

    return (
        <div className="w-full pl-3 pt-4">
            <table className="h-40 w-full">
                <tbody>
                    <tr className="my-2">
                        <td className="pr-4">
                            Encapsulation Confidence Score:
                        </td>
                        <td
                            id="clc-workflow-cspca-coverage-score-value"
                            className="relative w-20"
                        >
                            <Form>
                                <Form.Control
                                    size="sm"
                                    readOnly={true}
                                    placeholder={
                                        props.updatingMargin
                                            ? EMPTY_STRING
                                            : formatMarksScore(
                                                  props.userSelectedMarksScore
                                              )
                                    }
                                />
                            </Form>
                            {/*TODO: Optimize SpinLoader duplicate code in input fields*/}
                            {props.updatingMargin && (
                                <SpinLoader
                                    className="absolute right-[30%] top-2"
                                    fgColor="#434343"
                                    width="20px"
                                    height="20px"
                                    loaderWidth="3px"
                                    animationDuration="3s"
                                />
                            )}
                        </td>
                        <td>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderFaqTooltip()}
                            >
                                <div className="pl-[30%]">
                                    <QuestionMark />
                                </div>
                            </OverlayTrigger>
                        </td>
                    </tr>
                    <tr className="my-2">
                        <td id="clc-workflow-clc-volume-label">
                            Prostate Volume:
                        </td>
                        <td
                            id="clc-workflow-prostate-volume-value"
                            className="w-20"
                        >
                            <Form>
                                <Form.Control
                                    size="sm"
                                    readOnly={true}
                                    placeholder={formatVolume(prostateVolume)}
                                />
                            </Form>
                        </td>
                    </tr>
                    <tr className="my-2">
                        <td id="clc-workflow-prostate-coverage-label">
                            Lesion Volume:
                        </td>
                        <td
                            id="clc-workflow-clc-volume-value"
                            className="relative w-20"
                        >
                            <Form>
                                <Form.Control
                                    size="sm"
                                    readOnly={true}
                                    placeholder={
                                        props.updatingMargin
                                            ? EMPTY_STRING
                                            : formatLesionVolume(
                                                  props.userSelectedLesionVolume
                                              )
                                    }
                                />
                            </Form>
                            {props.updatingMargin && (
                                <SpinLoader
                                    className="absolute right-[30%] top-2"
                                    fgColor="#434343"
                                    width="20px"
                                    height="20px"
                                    loaderWidth="3px"
                                    animationDuration="1s"
                                />
                            )}
                        </td>
                    </tr>
                    <tr className="my-2">
                        <td id="clc-workflow-cspca-coverage-score-label">
                            Prostate covered by Lesion:
                        </td>
                        <td
                            id="clc-workflow-prostate-coverage-value"
                            className="relative w-20"
                        >
                            <Form>
                                <Form.Control
                                    size="sm"
                                    readOnly={true}
                                    placeholder={
                                        props.updatingMargin
                                            ? ""
                                            : formatProstateCoverage(
                                                  props.userSelectedProstateCoverage
                                              )
                                    }
                                />
                            </Form>
                            {props.updatingMargin && (
                                <SpinLoader
                                    className="absolute right-[30%] top-2"
                                    fgColor="#434343"
                                    width="20px"
                                    height="20px"
                                    loaderWidth="3px"
                                    animationDuration="1s"
                                />
                            )}
                        </td>
                        <td>
                            {getProstageCoverageWarning() && (
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderWarningTooltip()}
                                >
                                    <div className="pl-[30%]">
                                        <img
                                            id="margin-workflow-statistics-warning"
                                            src={warningIconSrc}
                                            alt="Warning triangle"
                                        />
                                    </div>
                                </OverlayTrigger>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

CancerLesionStatistics.propTypes = {
    currentTreatmentPlan: PropTypes.object,
    updatingMargin: PropTypes.bool,
    userSelectedLesionVolume: PropTypes.number,
    userSelectedMarksScore: PropTypes.number,
    userSelectedProstateCoverage: PropTypes.number,
};

export default CancerLesionStatistics;
