import React from "react";
import PropTypes from "prop-types";
import AHButton from "../StyleGuide/AHButton";

function CemFailureScreen({ onClick }) {
    function handleClick() {
        if (onClick) {
            onClick();
        }
    }

    return (
        <div className="failure-screen">
            <div className="recovery-message">
                <p className="display-22">
                    Oops something went wrong. Return to Step 3 to retry.
                </p>
            </div>
            <AHButton
                className="h-14 w-44"
                text="Return to Step 3"
                onClick={handleClick}
            />
        </div>
    );
}

CemFailureScreen.propTypes = {
    onClick: PropTypes.func,
};

CemFailureScreen.defaultProps = {
    onClick: undefined,
};

export default CemFailureScreen;
