import React, { Component } from "react";
import AvendaLogo from "../__shared__/AvendaLogo";
import "./styles.css";
import { setNewPassword } from "../../helpers/backend_api";
import {
    EMPTY_STRING,
    clientErrorMessages,
    stepPath,
    SUCCESS,
} from "../../constants";
import PropTypes from "prop-types";
import NewPassword from "../__shared__/StyleGuide/NewPassword";
import {
    buttonSize,
    messages,
    submitButtonText,
    viewType,
} from "../__shared__/StyleGuide/NewPassword/constants";

export class SetNewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: EMPTY_STRING,
            confPassword: EMPTY_STRING,
            errorMsg: EMPTY_STRING,
        };
        this.username = EMPTY_STRING;
        this.resetToken = EMPTY_STRING;
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.username = urlParams.get("u");
        this.resetToken = urlParams.get("t");
    }

    handleSubmit(message, password) {
        this.setState({
            errorMsg: EMPTY_STRING,
        });

        if (message !== messages.SUCCESS) {
            this.setState({
                errorMsg: message,
            });
            return;
        }

        let input = {
            username: this.username,
            password: password,
            resetToken: this.resetToken,
        };

        setNewPassword(input).then((r) => {
            if (r.payload.status !== SUCCESS) {
                this.setState({
                    errorMsg: clientErrorMessages.CHANGE_PASSWORD_FAILED,
                });
                return;
            }
            this.props.history.push(stepPath.LOGIN);
        });
    }

    render() {
        let userData = {
            username: this.username,
        };

        return (
            <div className={"signup-screen"}>
                <AvendaLogo id={"signup-screen-avenda-logo"} />

                <div className={"signup-form"}>
                    <p
                        className={"display-36 black-text"}
                        style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                        Enter New Password
                    </p>

                    {this.state.errorMsg && (
                        <div
                            style={{
                                textAlign: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <p
                                className={
                                    "display-14-bold red d-inline-block ml-2 text-center"
                                }
                            >
                                {this.state.errorMsg}
                            </p>
                        </div>
                    )}

                    <NewPassword
                        submitButtonText={submitButtonText.SUBMIT}
                        viewType={viewType.MINI_VIEW}
                        userData={userData}
                        onParentSubmit={this.handleSubmit}
                        isLabelVisible={false}
                        isSubmitButtonPlacedRight={false}
                        submitButtonSize={buttonSize.LARGE}
                        newPasswordId={"reset-password-enter-password"}
                        confirmPasswordId={"reset-password-confirm-password"}
                        newPasswordPlaceholder={"Enter Password"}
                        confirmPasswordPlaceholder={"Confirm Password"}
                    />
                </div>
            </div>
        );
    }
}

SetNewPassword.propTypes = {
    history: PropTypes.object,
};
