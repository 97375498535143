import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    clientErrorMessages,
    AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
    EMPTY_STRING,
} from "../../../../constants";
import uncheckedSquare from "../../../../assets/UncheckedSquare.svg";
import checkedSquare from "../../../../assets/CheckedSquare.svg";
import { getAvendaDefaultProfile } from "../../helpers";
import { isEmpty } from "../../../../helpers/helpers";
import { getAblationProfiles } from "../../../Profile/helpers";
import { updateTreatmentPlan } from "../../../../helpers/backend_api";
import { rootStore } from "../../../../redux/store";
import { setErrorState } from "../../../../redux/error_banner/actions";
import { setCurrentTreatmentPlan } from "../../../CreateTPlan_1Upload/actions";
import AHButton from "../../../__shared__/StyleGuide/AHButton";

class AblationProfileSelectionPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ablationProfiles: [],
            selectedProfile: {},
        };

        this.getProfileListItems = this.getProfileListItems.bind(this);
        this.getAblationProfiles = this.getAblationProfiles.bind(this);
        this.saveProfileChoice = this.saveProfileChoice.bind(this);
        this.getProfileAsListItem = this.getProfileAsListItem.bind(this);
        this.getAvendaDefaultProfile = this.getAvendaDefaultProfile.bind(this);
        this.setDefaultProfileOption = this.setDefaultProfileOption.bind(this);
        this.setCurrentAblationProfile =
            this.setCurrentAblationProfile.bind(this);
    }

    componentDidMount() {
        this.getAblationProfiles();
        this.getAvendaDefaultProfile();
    }

    setCurrentAblationProfile(profile) {
        this.setState({
            selectedProfile: profile,
        });
    }

    getAvendaDefaultProfile() {
        getAvendaDefaultProfile().then((result) => {
            let newProfiles = this.state.ablationProfiles.concat(
                result.defaultProfile
            );
            this.setState({
                ablationProfiles: newProfiles,
                selectedProfile: result.defaultProfile,
            });
        });
    }

    getAblationProfiles() {
        if (!isEmpty(this.props.useruuid) && !isEmpty(this.props.authToken)) {
            let input = {
                userUuid: this.props.useruuid,
                authToken: this.props.authToken,
            };

            getAblationProfiles(input).then((result) => {
                if (result && !isEmpty(result.customProfiles)) {
                    let newProfiles = this.state.ablationProfiles.concat(
                        result.customProfiles
                    );
                    this.setState({
                        ablationProfiles: newProfiles,
                    });

                    this.setDefaultProfileOption();
                }
            });
        }
    }

    setDefaultProfileOption() {
        if (
            this.props.userData.defaultAblationProfileUUID === EMPTY_STRING ||
            this.props.userData.defaultAblationProfileUUID ===
                AVENDA_DEFAULT_ABLATION_PROFILE_UUID ||
            this.state.ablationProfiles
        ) {
            return;
        }

        let selectedProfileID = this.props.userData.defaultAblationProfileUUID;
        let selectedProfile;

        this.state.ablationProfiles.forEach((profile) => {
            selectedProfile =
                profile.user.ablationProfileUUID === selectedProfileID
                    ? profile
                    : selectedProfile;
        });

        if (selectedProfile) {
            this.setState({
                selectedProfile: selectedProfile,
            });
        }
    }

    getProfileAsListItem(profile, idx) {
        let uuid = profile.ablationProfileUUID
            ? profile.ablationProfileUUID
            : EMPTY_STRING;

        let imgSrc =
            uuid === this.state.selectedProfile.ablationProfileUUID
                ? checkedSquare
                : uncheckedSquare;
        let shade = idx % 2 === 0 ? "light" : "dark";

        return (
            <div
                key={uuid}
                className={`profile-list-item ${shade}`}
                onClick={() => {
                    this.setCurrentAblationProfile(profile);
                }}
            >
                <div className={"profile-list-item-checkbox"}>
                    <img
                        src={imgSrc}
                        width="25px"
                        height="25px"
                        alt={"Check Box"}
                    />
                </div>
                <p className={"ablation-list-item-text display-16"}>
                    {profile.name}
                </p>
            </div>
        );
    }

    getProfileListItems() {
        const profiles = this.state.ablationProfiles;
        let profileList = [];

        if (profiles.length >= 1) {
            profiles.forEach((profile, index) => {
                profileList.push(this.getProfileAsListItem(profile, index));
            });
        }

        return profileList;
    }

    saveProfileChoice(profile) {
        let scope = this;

        this.props.setAblationProfile(profile.ablationProfileUUID);

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
            planuuid: scope.props.planuuid,
        };

        let plan = this.props.currentTreatmentPlan;
        plan.SavedData.AblationProfileName = profile.name;
        plan.SavedData.AblationProfileUUID = profile.ablationProfileUUID;

        updateTreatmentPlan({
            ...ids,
            plan: plan,
        })
            .then((payload) => payload.json())
            .then((json) => {
                scope.props.setCurrentTreatmentPlan(json.payload.plan);
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
                );
            })
            .finally(() => {
                scope.props.onFinished();
            });
    }

    handlePanelClick(e) {
        e.stopPropagation();
    }

    render() {
        return (
            <div
                className="flex h-full w-full flex-col items-center bg-[#393939] text-white"
                style={this.props.style}
                onClick={this.handlePanelClick}
            >
                <div className="flex h-16 items-center justify-center text-lg">
                    Select a Tool Profile
                </div>
                <div className="ablation-profile-listing-cntr">
                    {this.getProfileListItems()}
                </div>
                <div className="confirm-profile-btn-cntr">
                    <AHButton
                        id="confirm-profile-btn"
                        onClick={() => {
                            this.saveProfileChoice(this.state.selectedProfile);
                        }}
                        size="large"
                        text="Confirm Profile Selection"
                    />
                </div>
            </div>
        );
    }
}

AblationProfileSelectionPanel.propTypes = {
    closeSelectPlacement: PropTypes.func,
    style: PropTypes.object,
    currentTreatmentPlan: PropTypes.object,
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    setAblationProfile: PropTypes.func,
    userData: PropTypes.object,
};

AblationProfileSelectionPanel.defaultProps = {
    style: {},
    currentTreatmentPlan: {},
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    userData: {},
};

/* istanbul ignore once */
const mapStateToProps = function (state) {
    return {
        userData: state.LoginReducer.userData,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
    };
};

/* istanbul ignore once */
const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AblationProfileSelectionPanel);
