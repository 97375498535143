import React, { Component } from "react";
import PropTypes from "prop-types";

export default class StepEdge extends Component {
    render() {
        let bgColor = this.props.isActive ? "#2E76F2" : "#D0D9DF";
        let graphic;
        if (this.props.activeStep) {
            graphic = (
                <svg viewBox="-3 0 150 4">
                    <g fill="none" stroke="none" strokeWidth="1">
                        <path
                            stroke={bgColor}
                            strokeDasharray="15,15"
                            strokeWidth="3"
                            strokeLinecap="round"
                            d="M5 2 l200 0"
                        />
                    </g>
                </svg>
            );
        } else {
            graphic = (
                <svg viewBox="0 0 210 15">
                    <g fill="none" stroke="none" strokeWidth="1">
                        <path
                            strokeWidth="5"
                            stroke={bgColor}
                            strokeLinecap="round"
                            d="M5 8 l200 0"
                        />
                    </g>
                </svg>
            );
        }
        return (
            <div id="step-edge" className="mx-3 h-full w-1/2">
                {graphic}
            </div>
        );
    }
}

StepEdge.propTypes = {
    id: PropTypes.string,
    isActive: PropTypes.bool,
    activeStep: PropTypes.bool,
};

StepEdge.defaultProps = {
    isActive: false,
    activeStep: false,
};
