import React, { Component } from "react";
import { connect } from "react-redux";
import bluChk from "../../../assets/blue_check_lg.svg";
import trashCan from "../../../assets/trash_can.svg";
import "./styles.css";
import { setCurrentPlanUUID, setCurrentTreatmentPlan } from "../actions";
import { getTreatmentPlan } from "../helpers";
import {
    EMPTY_STRING,
    SUCCESS,
    MAX_KEY_PATIENT_INFO_CARD_RELOADS,
    clientErrorMessages,
} from "../../../constants";
import { uploadStates } from "../constants";
import { setParseFailureMessage, setUploadState } from "../actions";
import PropTypes from "prop-types";

class UploadFinishedCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missCount: 0,
            planLoaded: false,
            planLoadingFailed: false,
        };
    }

    componentDidMount() {
        let scope = this;

        this.pollProgress = setInterval(() => {
            getTreatmentPlan(
                this.props.authToken,
                this.props.patientuuid,
                this.props.planuuid
            )
                .then((payload) => payload.json())
                .then((json) => {
                    if (json.payload.status === SUCCESS) {
                        scope.displayLoadTreatmentPlanSucceeded(
                            json.payload.plan
                        );
                    } else {
                        scope.setState({
                            missCount: scope.state.missCount + 1,
                        });
                        if (
                            scope.state.missCount ===
                            MAX_KEY_PATIENT_INFO_CARD_RELOADS
                        ) {
                            scope.displayLoadTreatmentPlanFailed();
                        }
                    }
                })
                .catch(() => {
                    scope.displayLoadTreatmentPlanFailed();
                });
        }, 1000);
    }

    displayLoadTreatmentPlanSucceeded(plan) {
        this.props.setCurrentTreatmentPlan(plan);
        if (this.props.parseCallback) {
            this.props.parseCallback(true);
        }
        this.setState({
            planLoaded: true,
        });
    }

    displayLoadTreatmentPlanFailed() {
        this.setState({
            planLoadingFailed: true,
        });
        this.props.setParseFailureMessage(
            clientErrorMessages.TPLAN_RETRIEVE_FAILED
        );
        this.props.setUploadState(uploadStates.FAILED);
    }

    componentDidUpdate() {
        if (this.state.planLoaded || this.state.planLoadingFailed) {
            clearInterval(this.pollProgress);
        }
    }

    componentWillUnmount() {
        clearInterval(this.pollProgress);
    }

    render() {
        let fileLabel =
            this.props.fileText === EMPTY_STRING
                ? this.props.label
                : this.props.fileText;
        return (
            <div
                id={this.props.id}
                className="upload-card finished solid"
                style={this.props.style}
            >
                <div className="finish-check-container w-100 d-inline-flex align-items-center justify-between pr-[4%]">
                    <div className="d-inline-flex align-items-center upload-label-info unselectable pl-[4%]">
                        <img src={bluChk} alt="upload successful check mark" />
                        <label className="display-16 text-dark ml-5">
                            {this.props.completedText}
                        </label>
                    </div>
                    <div className="file-label-info">
                        <label className="display-16 text-muted">
                            {fileLabel}
                        </label>
                    </div>
                    <div className="delete-upload-btn-cntr unselectable">
                        <img
                            className="delete-upload-btn"
                            id={`${this.props.label}-upload-card-delete-btn`}
                            src={trashCan}
                            alt="delete upload button"
                            onClick={() => {
                                this.props.setDeleteAlert(this.props.label);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

UploadFinishedCard.propTypes = {
    fileText: PropTypes.string,
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    planuuid: PropTypes.string,
    patientuuid: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
    text: PropTypes.string,
    setUploadState: PropTypes.func,
    setParseFailureMessage: PropTypes.func,
    setCurrentTreatmentPlan: PropTypes.func,
    parseCallback: PropTypes.func,
    completedText: PropTypes.string,
    setDeleteAlert: PropTypes.func,
    label: PropTypes.string,
};

UploadFinishedCard.defaultProps = {
    fileText: EMPTY_STRING,
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    completedText: "COMPLETE",
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        planuuid: state.CreatePlanReducer.planuuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch, ownProps) {
    return {
        setCurrentPlanUUID: (uuid) => dispatch(setCurrentPlanUUID(uuid)),
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setUploadState: (upstate) =>
            dispatch(setUploadState(ownProps.idx, upstate)),
        setParseFailureMessage: (msg) =>
            dispatch(setParseFailureMessage(ownProps.idx, msg)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFinishedCard);
