import React, { Component } from "react";
import TopBar from "../__shared__/TopBar";
import ProfileMenu from "../__shared__/ProfileMenu";
import StepMarkerBar from "../__shared__/StepMarkerBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import PreviousStepButton from "../__shared__/PreviousStepButton";
import NextStepButton from "../__shared__/NextStepButton";
import { connect } from "react-redux";
import {
    FREEHAND_SUMMARY_STEP,
    SEG_MRI_STEP,
    SUMMARY_STEP,
    TOOL_PLACEMENT_STEP,
} from "../CreateTPlan_1Upload/constants";
import {
    resetCreatePlanShard,
    setCurrentTreatmentPlan,
    setStepActive,
    setStepComplete,
} from "../CreateTPlan_1Upload/actions";
import "./styles.css";
import cubeBlue from "../../assets/nav_cube_blue.svg";
import cubeDark from "../../assets/nav_cube_dark.svg";
import cubeLight from "../../assets/nav_cube_light.svg";
import ButtonGroup from "../__shared__/ButtonGroup";
import MeshViewer from "../__shared__/Viewers/MeshViewer";
import SummaryPanel from "./SummaryPanel";
import SliceControl from "../__shared__/SliceControl";
import { getLoadingScreen, hasProperty, isEmpty } from "../../helpers/helpers";
import {
    releasePlanLock,
    updateTreatmentPlan,
} from "../../helpers/backend_api";
import MRIControlBar from "../__shared__/MRIControlBar";
import { resetImageViewerShard } from "../../redux/image_viewer/actions";
import {
    EMPTY_STRING,
    loginReducerFlags,
    pageLoadStates,
    stepPath,
    SUCCESS,
} from "../../constants";
import MRIViewerButton from "../__shared__/MRIViewerButton";
import NavCubeModal from "../__shared__/NavCubeModal";
import RequirementsModal from "../__shared__/RequirementsModal";
import DecisionAlertCard from "../__shared__/DecisionAlertCard";
import { getTreatmentPlan } from "../CreateTPlan_1Upload/helpers";
import { resetCreatePlanBiopsyShard } from "../CreateTPlan_3Biopsy/actions";
import filterBlue from "../../assets/filter_blue.svg";
import filterDark from "../../assets/filter_dark.svg";
import filterLight from "../../assets/filter_light.svg";
import FilterToggle from "../__shared__/FilterToggle";
import PropTypes from "prop-types";
import { setFlag } from "../Login/actions";
import { packPlan } from "./helpers";
import DownloadSelectCard from "../__shared__/DownloadSelectCard";
import { setDownloadProperties } from "../Home/actions";
import TargetSetModel from "../__shared__/Viewers/Structures/Targets/TargetSetModel";
import TargetSetController from "../__shared__/Viewers/Structures/Targets/TargetSetController";
import MRIModel from "../__shared__/Viewers/Structures/MRI/MRIModel";
import MRIController from "../__shared__/Viewers/Structures/MRI/MRIController";
import {
    legacy_loadBxCores,
    legacy_loadMargin,
    legacy_loadMRI,
    legacy_loadProstate,
    legacy_loadROIs,
    resetViewer,
} from "../../helpers/load_helpers";
import PatientAnatomyModel from "../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyModel";
import { setDebugHooks } from "../../helpers/debug/debug_hooks";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import MRIViewer from "../__shared__/Viewers/MRIViewer";
import SplitPane from "react-split-pane";
import PatientAnatomyController from "../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyController";
import SpinLoader from "../__shared__/SpinLoader";
import ResetViewerButton from "../__shared__/ResetViewerButton";
import { RESET_VIEW } from "../CreateTPlan_2SegMRI/constants";
import { getCemWarningMsg } from "../CreateTPlan_4EditMargin/helpers";

// TODO: Rename CreateTPlan_5Summary to CreateTPlan_6Summary, fix imports if needed
class CreateTPlan_5Summary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            planFinalized: false,
            displayFinalizeAlert: false,
            displayDownloadAlert: false,
            loadState: pageLoadStates.LOADING,
            finalizeInProgress: false,
            cemWarningMsg: EMPTY_STRING,
        };

        this.requirements = {
            required: [
                {
                    id: "confirm-plan-req",
                    satisfied: false,
                    reqText: "Finalize and Confirm Treatment Plan",
                    listIndex: 1,
                },
            ],
            optionals: [],
        };

        this.mriRef = React.createRef();
        this.meshRef = React.createRef();
        this.sliceControlRef = React.createRef();

        this.mriModel = new MRIModel(true);
        this.mriController = new MRIController(this.mriModel);
        this.patientAnatomyModel = new PatientAnatomyModel();
        this.targetSetModel = new TargetSetModel();
        this.targetSetController = new TargetSetController(
            this.targetSetModel,
            this.mriModel
        );
        this.patientAnatomyModel.targetSet = this.targetSetModel;
        this.patientAnatomyController = new PatientAnatomyController(
            this.patientAnatomyModel,
            this.targetSetModel
        );

        setDebugHooks({
            mriModel: this.mriModel,
            patientAnatomyModel: this.patientAnatomyModel,
            targetSetModel: this.targetSetModel,
        });

        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.finalizePlan = this.finalizePlan.bind(this);
        this.getDownloadModal = this.getDownloadModal.bind(this);
        this.handleFinalizePlanClick = this.handleFinalizePlanClick.bind(this);
    }

    componentDidMount() {
        let scope = this;
        getTreatmentPlan(
            this.props.authToken,
            this.props.patientuuid,
            this.props.planuuid
        )
            .then((payload) => payload.json())
            .then((json) => {
                scope.props.setCurrentTreatmentPlan(json.payload.plan);
                this.getCemWarnings(json.payload.plan);
                this.getMRIURLs(json.payload.plan);
                this.getMeshURLs(json.payload.plan);
                this.setState({
                    planFinalized:
                        json.payload.plan.SavedData.PlanCreationCompleteFlag,
                });
            })
            .catch(() => {
                scope.setState({ loadState: pageLoadStates.FAILED });
            });
    }

    prevStep() {
        let plan = this.props.currentTreatmentPlan;

        if (TreatmentPlan.isMRIOnly(this.props.currentTreatmentPlan)) {
            this.props.setStepActive(FREEHAND_SUMMARY_STEP, false);
            this.props.setStepComplete(FREEHAND_SUMMARY_STEP, false);
            this.props.setStepActive(SEG_MRI_STEP, true);
            plan.SavedData.PlanCreationStep = SEG_MRI_STEP;
        } else {
            this.props.setStepActive(SUMMARY_STEP, false);
            this.props.setStepComplete(SUMMARY_STEP, false);
            this.props.setStepActive(TOOL_PLACEMENT_STEP, true);
            plan.SavedData.PlanCreationStep = TOOL_PLACEMENT_STEP;
        }

        this.props.setCurrentTreatmentPlan(plan);
        updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        });
    }

    nextStep() {
        if (this.requirementsCheck()) {
            this.props.resetCreatePlanShard();
            this.props.resetCreatePlanBiopsyShard();
            this.props.resetImageViewerShard();
            return true;
        } else if (this.requirementsModalRef.state.modalOpen) {
            this.requirementsModalRef.closeModal();
        } else {
            this.requirementsModalRef.openModal();
        }
    }

    getCemWarnings(plan) {
        if (!plan.SavedData.CEMWarnings) {
            return;
        }

        let cemWarnings = plan.SavedData.CEMWarnings;

        let warningString = getCemWarningMsg(cemWarnings);

        this.setState({
            cemWarningMsg: warningString,
        });
    }

    getMeshURLs(plan) {
        let scope = this;

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
        };

        legacy_loadBxCores(scope, plan);
        legacy_loadProstate(scope, plan, ids, false, true);
        legacy_loadROIs(scope, plan, ids);
        legacy_loadMargin(scope, plan, ids);
    }

    getMRIURLs(plan) {
        let scope = this;

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
        };

        legacy_loadMRI(scope, plan, ids);
    }

    requirementsCheck() {
        return this.state.planFinalized;
    }

    getRequirements() {
        this.requirements.required[0].satisfied = this.state.planFinalized;
        return this.requirements;
    }

    async finalizePlan() {
        this.setState({
            finalizeInProgress: true,
        });

        await releasePlanLock({
            token: this.props.authToken,
            useruuid: this.props.useruuid,
            planuuid: this.props.planuuid,
        });

        let scope = this;

        this.setState({
            displayFinalizeAlert: false,
            planFinalized: true,
            displayDownloadAlert: true,
        });

        let plan = this.props.currentTreatmentPlan;

        if (!hasProperty(plan, "SavedData.PlanCreationCompleteFlag")) {
            return;
        }

        plan.SavedData.PlanCreationCompleteFlag = true;
        plan.SavedData.PlanCreationStep = SUMMARY_STEP;

        this.props.setFlag({
            flagId: loginReducerFlags.PLAN_COMPLETED,
            newFlagState: true,
        });

        updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.message === SUCCESS) {
                    scope.props.setCurrentTreatmentPlan(json.payload.plan);
                    scope.props.setStepComplete(SUMMARY_STEP, true);
                }
            });

        packPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
        });
    }

    getDownloadModal() {
        this.setState({ displayDownloadAlert: false });
        this.props.setDownloadProperties(
            true,
            this.props.patientuuid,
            this.props.planuuid
        );
    }

    handleFinalizePlanClick() {
        this.setState((prevState) => ({
            displayFinalizeAlert: !prevState.displayFinalizeAlert,
        }));
    }

    getAlert() {
        if (this.state.displayFinalizeAlert) {
            return (
                <DecisionAlertCard
                    id={"summary-alert"}
                    noBtnCallback={() => {
                        this.setState({ displayFinalizeAlert: false });
                    }}
                    noBtnTxt={"Go Back"}
                    yesBtnTxt={
                        this.state.finalizeInProgress ? (
                            <SpinLoader
                                display={"inline-block"}
                                height={"25px"}
                                width={"25px"}
                                animationDuration={"1s"}
                            />
                        ) : (
                            "Confirm"
                        )
                    }
                    yesBtnCallback={this.finalizePlan}
                >
                    <div className={"summary-alert-card-header"}>
                        <p
                            className={"display-26"}
                            style={{ color: "#000000" }}
                        >
                            Finalize Treatment Plan?
                        </p>
                    </div>
                    <div className={"summary-alert-card-body"}>
                        <div className={"summary-alert-row"}>
                            <p
                                className={"display-16"}
                                style={{ color: "#000000" }}
                            >
                                Note: You will no longer be able to make changes
                                to this plan after you confirm.
                            </p>
                        </div>
                    </div>
                </DecisionAlertCard>
            );
        }

        if (this.state.displayDownloadAlert) {
            return (
                <DecisionAlertCard
                    id={"summary-alert"}
                    noBtnCallback={() => {
                        this.setState({ displayDownloadAlert: false });
                    }}
                    noBtnTxt={"Later"}
                    yesBtnTxt={"Download Now"}
                    yesBtnCallback={this.getDownloadModal}
                >
                    <div className={"summary-alert-card-header"}>
                        <p
                            className={"display-26"}
                            style={{ color: "#000000" }}
                        >
                            Download Planning Outputs Now?
                        </p>
                    </div>
                    <div className={"summary-alert-card-body"}>
                        <div className={"summary-alert-row"}>
                            <p
                                className={"display-16"}
                                style={{ color: "#000000" }}
                            >
                                Note: You may choose to download the planning
                                outputs later from the patient history menu.
                            </p>
                        </div>
                    </div>
                </DecisionAlertCard>
            );
        }
    }

    showDownloadCard() {
        return this.props.downloadProperties.selectActive ? (
            <DownloadSelectCard
                currentTreatmentPlan={this.props.currentTreatmentPlan}
            />
        ) : (
            EMPTY_STRING
        );
    }

    getPreviousStep(plan) {
        return TreatmentPlan.isMRIOnly(plan) ? stepPath.STEP2 : stepPath.STEP5;
    }

    render() {
        let previousStep = this.getPreviousStep(
            this.props.currentTreatmentPlan
        );

        let previousButtonStyle = this.state.planFinalized
            ? { visibility: "hidden" }
            : {};

        return (
            <div className={"create-plan-summary-screen"}>
                <TopBar>
                    <AvendaLogo id={"create-plan-avenda-logo"} to={"/home"} />
                    <div className={"create-plan-step-bar-container"}>
                        <PreviousStepButton
                            to={previousStep}
                            callback={this.prevStep}
                            style={previousButtonStyle}
                            isReady={
                                this.state.loadState !== pageLoadStates.LOADING
                            }
                        />
                        <StepMarkerBar
                            previousStepsDisabled={this.state.planFinalized}
                        />
                        <NextStepButton
                            reqsComplete={this.requirementsCheck()}
                            reqsCompleteTxt={"Home"}
                            text={""}
                            to={
                                this.requirementsCheck()
                                    ? stepPath.HOME
                                    : stepPath.STEP6
                            }
                            callback={this.nextStep}
                        />
                    </div>
                    <ProfileMenu />
                </TopBar>
                <RequirementsModal
                    onRef={(ref) => (this.requirementsModalRef = ref)}
                    requirements={this.getRequirements()}
                />
                <SummaryPanel
                    isFreehandPlan={TreatmentPlan.isMRIOnly(
                        this.props.currentTreatmentPlan
                    )}
                    finalizeCallback={this.handleFinalizePlanClick}
                    cemWarningMsg={this.state.cemWarningMsg}
                />
                {this.getAlert()}
                {getLoadingScreen(this.state.loadState)}
                <div className={"mri-scene-container"}>
                    <MRIControlBar>
                        <div className={"mri-control-bar-left"}>
                            <ButtonGroup>
                                <MRIViewerButton
                                    toolTipText={"Scene Orientation"}
                                    name={"nav-cube"}
                                    imgBlue={cubeBlue}
                                    imgDark={cubeDark}
                                    imgLight={cubeLight}
                                    alt={"Nav Cube"}
                                >
                                    <NavCubeModal
                                        mriModel={this.mriModel}
                                        resetTick={() =>
                                            this.sliceControlRef.current.reset()
                                        }
                                        style={{ left: "524px" }}
                                    />
                                </MRIViewerButton>
                                <MRIViewerButton
                                    toolTipText={"Filter"}
                                    name={"filter"}
                                    imgBlue={filterBlue}
                                    imgDark={filterDark}
                                    imgLight={filterLight}
                                    alt={"Filter"}
                                >
                                    <FilterToggle
                                        mriModel={this.mriModel}
                                        patientAnatomyModel={
                                            this.patientAnatomyModel
                                        }
                                        targetSetModel={this.targetSetModel}
                                        displaySettings={{
                                            axesEnabled: true,
                                            mriEnabled: true,
                                            noMRIOption: true,
                                        }}
                                    />
                                </MRIViewerButton>
                            </ButtonGroup>
                            <SliceControl
                                ref={this.sliceControlRef}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                            />
                        </div>
                        <div className="mri-control-bar-right mr-10">
                            <ResetViewerButton
                                toolTipText={RESET_VIEW}
                                resetViewer={() =>
                                    resetViewer(
                                        this.meshRef,
                                        this.mriRef,
                                        this.sliceControlRef,
                                        this.mriModel
                                    )
                                }
                            />
                        </div>
                    </MRIControlBar>
                    <div className="mri-viewer">
                        <SplitPane
                            defaultSize={"60%"}
                            minSize={"0px"}
                            split={"vertical"}
                            pane1ClassName={"mri-pane"}
                            pane2ClassName={"mesh-pane"}
                            onDragFinished={() => {
                                this.mriRef.current.onWindowResize();
                                this.meshRef.current.onWindowResize();
                            }}
                        >
                            <MRIViewer
                                ref={this.mriRef}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                                patientAnatomyModel={this.patientAnatomyModel}
                            />
                            <MeshViewer
                                ref={this.meshRef}
                                staticViewer={true}
                                displaySettings={{
                                    mriEnabled: false,
                                    composerEnabled: true,
                                }}
                                targetSetController={this.targetSetController}
                                targetSetModel={this.targetSetModel}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                                patientAnatomyModel={this.patientAnatomyModel}
                                patientAnatomyController={
                                    this.patientAnatomyController
                                }
                                activateCoreClick={false}
                            />
                        </SplitPane>
                    </div>
                </div>
                {this.showDownloadCard()}
            </div>
        );
    }
}

CreateTPlan_5Summary.propTypes = {
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    displaySettings: PropTypes.object,
    setStepActive: PropTypes.func,
    setStepComplete: PropTypes.func,
    setCurrentTreatmentPlan: PropTypes.func,
    resetCreatePlanShard: PropTypes.func,
    resetCreatePlanBiopsyShard: PropTypes.func,
    resetImageViewerShard: PropTypes.func,
    setFlag: PropTypes.func,
    setDownloadProperties: PropTypes.func,
    downloadProperties: PropTypes.object,
};

CreateTPlan_5Summary.defaultProps = {
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    currentTreatmentPlan: {},
    displaySettings: {},
};

/* istanbul ignore next */
const mapStateToProps = function (state, ownProps) {
    let displaySettings = isEmpty(ownProps.displaySettings)
        ? state.ImageViewerReducer.displaySettings
        : ownProps.displaySettings;

    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
        displaySettings: displaySettings,
        downloadProperties: state.HomeReducer.downloadProperties,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setFlag: (flag) => dispatch(setFlag(flag)),
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
        resetCreatePlanShard: () => dispatch(resetCreatePlanShard()),
        resetCreatePlanBiopsyShard: () =>
            dispatch(resetCreatePlanBiopsyShard()),
        resetImageViewerShard: () => dispatch(resetImageViewerShard()),
        setDownloadProperties: (selectOpen, patID, planID) =>
            dispatch(setDownloadProperties(selectOpen, patID, planID)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTPlan_5Summary);
