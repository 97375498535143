import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import backArrow from "../../../../assets/back_arrow.svg";
import TargetSetController from "../../../__shared__/Viewers/Structures/Targets/TargetSetController";
import manualPlacementCartoon1 from "../../../../assets/manual_target_placement_cartoon_1.svg";
import manualPlacementCartoon2 from "../../../../assets/manual_target_placement_cartoon_2.svg";
import { defaultAblationVolumeSize } from "../../../__shared__/Viewers/Structures/Targets/consts";
import { getIconForAblationVolumePrimitiveType } from "../../helpers";
import { MANUALLY_ADD_TOOL_SITE_TITLE } from "../../constants";

class AblationSiteManualPlacementPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVolume: defaultAblationVolumeSize.LARGE,
        };

        this.onClose = this.onClose.bind(this);
        this.getVolumeList = this.getVolumeList.bind(this);
        this.setAblationVolumeMesh = this.setAblationVolumeMesh.bind(this);
    }

    componentDidMount() {
        this.props.targetSetController.guidanceMode = true;
        if (
            this.props.ablationProfile &&
            this.props.ablationProfile.volumes.length > 0
        ) {
            this.props.targetSetController.ablationProfileVolume =
                this.props.ablationProfile.volumes[0].ablationVolumeUUID;
            this.setState({
                selectedVolume:
                    this.props.ablationProfile.volumes[0].ablationVolumeUUID,
            });
        }
    }

    componentWillUnmount() {
        this.props.targetSetController.guidanceMode = false;
    }

    onClose(e) {
        e.stopPropagation();
        this.props.onClose();
    }

    setAblationVolumeMesh(uuid) {
        this.props.targetSetController.ablationProfileVolume = uuid;

        this.setState({
            selectedVolume: uuid,
        });
    }

    getVolumeList(ablationProfile) {
        let listItems = [];

        if (ablationProfile) {
            ablationProfile.volumes.forEach((ablationVolume, idx) => {
                let shade = idx % 2 === 0 ? "light" : "dark";

                if (
                    this.state.selectedVolume ===
                    ablationVolume.ablationVolumeUUID
                ) {
                    shade = "selected";
                }

                let volumeIcon = getIconForAblationVolumePrimitiveType(
                    ablationVolume.meshPrimitiveType
                );

                listItems.push(
                    <div
                        className={`volume-list-item ${shade}`}
                        onClick={() => {
                            this.setAblationVolumeMesh(
                                ablationVolume.ablationVolumeUUID
                            );
                        }}
                    >
                        <img
                            src={volumeIcon}
                            className={"volume-list-icon"}
                            alt={"Volume img"}
                        />
                        <p className={"volume-list-item-text display-14"}>
                            {ablationVolume.name}
                        </p>
                    </div>
                );
            });
        }

        return listItems;
    }

    render() {
        return (
            <div
                className="flex h-full w-full flex-col bg-[#393939] px-6"
                style={this.props.style}
            >
                <div className="flex h-20 items-center">
                    <div
                        className="manually-place-targets-back-btn w-5"
                        onClick={this.onClose}
                    >
                        <img src={backArrow} alt="Back arrow" />
                    </div>
                    <div className="display-20 grow text-center">
                        {MANUALLY_ADD_TOOL_SITE_TITLE}
                    </div>
                    <div className="h-full w-7"></div>
                </div>
                <div>
                    <p className="active-profile-label display-14 pb-1 italic text-white">
                        Active Tool Profile:{" "}
                        {this.props.ablationProfile &&
                        this.props.ablationProfile.name
                            ? this.props.ablationProfile.name
                            : ""}
                    </p>
                    <div>
                        <div className="profile-volume-list">
                            {this.getVolumeList(this.props.ablationProfile)}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="display-14 pt-2 text-center text-white">
                        <p>A guide will follow your cursor over the 2D MRI.</p>
                        <p>Click with your cursor to confirm placement.</p>
                        <img
                            className="manual-target-cartoon"
                            src={manualPlacementCartoon1}
                            alt="manual placement cartoon"
                        />
                        <p>Click elsewhere to place additional tool volumes.</p>
                        <img
                            className="manual-target-cartoon"
                            src={manualPlacementCartoon2}
                            alt="manual placement cartoon"
                        />
                        <p>
                            When you are done, click the back arrow to return to
                            the tool placement site list.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

AblationSiteManualPlacementPanel.propTypes = {
    targetSetController: PropTypes.instanceOf(TargetSetController),
    onClose: PropTypes.func,
    style: PropTypes.object,
    ablationProfile: PropTypes.object,
};

export default AblationSiteManualPlacementPanel;
