export function formatPercent(percent) {
    return `${Math.min(percent * 100, 100).toFixed(1)}%`;
}
export function formatMarksScore(marksScore) {
    marksScore = marksScore === 1.0 ? 0.999 : marksScore;
    return formatPercent(marksScore);
}

export function formatVolume(volumeFloat) {
    return `${volumeFloat.toFixed(1)}cc`;
}

export function formatLesionVolume(volumeFloat) {
    const volumeFmt = Math.max(volumeFloat, 0.1);
    let volumeStr = `${volumeFmt.toFixed(1)}cc`;
    if (volumeFmt <= 0.1) {
        volumeStr = "<" + volumeStr;
    }

    return volumeStr;
}

export function formatProstateCoverage(percent) {
    let percentFmt = Math.min(percent * 100, 100);
    percentFmt = Math.max(percentFmt, 0.1);
    let percentStr = `${percentFmt.toFixed(1)}%`;
    if (percent <= 0.01) {
        percentStr = "<" + percentStr;
    }

    return percentStr;
}

export function isInsideDiv(e, divRect) {
    return (
        e.clientX >= divRect.left &&
        e.clientX <= divRect.right &&
        e.clientY >= divRect.top &&
        e.clientY <= divRect.bottom
    );
}
