import React, { Component } from "react";
import TopBar from "../__shared__/TopBar";
import ProfileMenu from "../__shared__/ProfileMenu";
import StepMarkerBar from "../__shared__/StepMarkerBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import PreviousStepButton from "../__shared__/PreviousStepButton";
import NextStepButton from "../__shared__/NextStepButton";
import { connect } from "react-redux";
import {
    SEG_MRI_STEP,
    UPLOAD_STEP,
    BIOPSY_STEP,
    SUMMARY_STEP,
    FREEHAND_SUMMARY_STEP,
} from "../CreateTPlan_1Upload/constants";
import {
    setCurrentTreatmentPlan,
    setStepActive,
    setStepComplete,
} from "../CreateTPlan_1Upload/actions";
import "./styles.css";
import MRIControlBar from "../__shared__/MRIControlBar";
import MRIViewer from "../__shared__/Viewers/MRIViewer";
import ButtonGroup from "../__shared__/ButtonGroup";
import SliceControl from "../__shared__/SliceControl";
import { getLoadingScreen, isEmpty } from "../../helpers/helpers";
import {
    estimateMeshVolume,
    getMeshProperties,
    updateTreatmentPlan,
} from "../../helpers/backend_api";
import {
    EMPTY_STRING,
    pageLoadStates,
    refFrames,
    stepPath,
    SUCCESS,
    clientErrorMessages,
    structureColorPalette,
} from "../../constants";
import RequirementsModal from "../__shared__/RequirementsModal";
import MRIViewerButton from "../__shared__/MRIViewerButton";
import cubeBlue from "../../assets/nav_cube_blue.svg";
import cubeDark from "../../assets/nav_cube_dark.svg";
import cubeLight from "../../assets/nav_cube_light.svg";
import NavCubeModal from "../__shared__/NavCubeModal";
import FilterToggle from "../__shared__/FilterToggle";
import filterBlue from "../../assets/filter_blue.svg";
import filterDark from "../../assets/filter_dark.svg";
import filterLight from "../../assets/filter_light.svg";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import { getTreatmentPlan } from "../CreateTPlan_1Upload/helpers";
import {
    mriViewerButtonTooltip,
    PROSTATE_CORE_STRUCTURE_VTK_PATH,
    prostateSegOptions,
    segmentationDisplayOptions,
    step2AlertOptions,
    RESET_VIEW,
    prostateStructure,
} from "./constants";
import PropTypes from "prop-types";
import { setErrorState } from "../../redux/error_banner/actions";
import { rootStore } from "../../redux/store";
import MRIModel from "../__shared__/Viewers/Structures/MRI/MRIModel";
import MRIController from "../__shared__/Viewers/Structures/MRI/MRIController";
import TargetSetModel from "../__shared__/Viewers/Structures/Targets/TargetSetModel";
import TargetSetController from "../__shared__/Viewers/Structures/Targets/TargetSetController";
import {
    loadAllProstateSegmentations,
    legacy_loadBxCores,
    legacy_loadMRI,
    legacy_loadROIs,
    resetViewer,
} from "../../helpers/load_helpers";
import MeshEditController from "../__shared__/Viewers/Structures/Other/MeshEditController";
import SplitPane from "react-split-pane";
import MeshViewer from "../__shared__/Viewers/MeshViewer";
import SegmentationPanel from "./SegmentationPanel";
import StructurePickerPatientAnatomyModel from "../__shared__/Viewers/Structures/PatientAnatomy/StructurePickerPatientAnatomyModel";
import Step2ConfirmPrompt from "./Step2ConfirmPrompt";
import { setDebugHooks } from "../../helpers/debug/debug_hooks";
import DecisionAlertCard from "../__shared__/DecisionAlertCard";
import { saveProstateSegmentationEdits } from "./helpers";
import PatientAnatomyController from "../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyController";
import ResetViewerButton from "../__shared__/ResetViewerButton";
import { v1 as uuidv1 } from "uuid";

class CreateTPlan_2SegMRI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadState: pageLoadStates.LOADING,
            displayAlert: step2AlertOptions.NONE,
            activeWorkflowState: segmentationDisplayOptions.SEG_LISTING,
            editsSaved: false,
        };

        this.requirements = {
            required: [
                {
                    id: "prostate-req",
                    satisfied: false,
                    reqText: "Confirm Prostate segmentation",
                    listIndex: 1,
                },
            ],
            optionals: [
                {
                    id: "prostate-segment-req",
                    satisfied: false,
                    reqText:
                        "Use the Edit Tool to tweak the Prostate segmentation",
                    listIndex: 1,
                },
                {
                    id: "ai-segment-req",
                    satisfied: false,
                    reqText:
                        "Automatically create a new segmentation using AI ",
                    listIndex: 2,
                },
            ],
        };

        this.mriRef = React.createRef();
        this.meshRef = React.createRef();
        this.sliceControlRef = React.createRef();

        this.mriModel = new MRIModel();
        this.mriController = new MRIController(this.mriModel);
        this.patientAnatomyModel = new StructurePickerPatientAnatomyModel();
        this.targetSetModel = new TargetSetModel();
        this.targetSetController = new TargetSetController(
            this.targetSetModel,
            this.mriModel
        );
        this.meshEditController = new MeshEditController(this.mriModel);
        this.patientAnatomyController = new PatientAnatomyController(
            this.patientAnatomyModel,
            this.targetSetModel
        );

        setDebugHooks({
            mriModel: this.mriModel,
            patientAnatomyModel: this.patientAnatomyModel,
            targetSetModel: this.targetSetModel,
        });

        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.selectAlertCallback = this.selectAlertCallback.bind(this);
        this.isAProstateSegmentationAvailableCheck =
            this.isAProstateSegmentationAvailableCheck.bind(this);
        this.setWorkflowState = this.setWorkflowState.bind(this);
        this.setEditsSavedState = this.setEditsSavedState.bind(this);
        this.handleSaveProstateEdits = this.handleSaveProstateEdits.bind(this);
    }

    componentDidMount() {
        let scope = this;

        getTreatmentPlan(
            this.props.authToken,
            this.props.patientuuid,
            this.props.planuuid
        )
            .then((payload) => payload.json())
            .then((json) => {
                scope.props.setCurrentTreatmentPlan(json.payload.plan);

                const plan = json.payload.plan;
                this.getMRIURLs(plan);
                this.getMeshURLs(plan);

                //todo move this portion into phi-parser
                const isFreehandPlan = TreatmentPlan.isMRIOnly(plan);
                if (
                    !isFreehandPlan &&
                    (!plan.SavedData.UploadedProstateURI ||
                        plan.SavedData.UploadedProstateURI === EMPTY_STRING)
                ) {
                    if (
                        TreatmentPlan.getStructureInFrame(
                            TreatmentPlan.getCoreStructure(plan.TreatmentPlan),
                            refFrames.MRI
                        ) &&
                        TreatmentPlan.getStructureInFrame(
                            TreatmentPlan.getCoreStructure(plan.TreatmentPlan),
                            refFrames.MRI
                        ).URI !== EMPTY_STRING
                    ) {
                        plan.SavedData.UploadedProstateURI =
                            TreatmentPlan.getStructureInFrame(
                                TreatmentPlan.getCoreStructure(
                                    plan.TreatmentPlan
                                ),
                                refFrames.MRI
                            ).URI;
                    }

                    updateTreatmentPlan({
                        useruuid: this.props.useruuid,
                        patientuuid: this.props.patientuuid,
                        planuuid: this.props.planuuid,
                        token: this.props.authToken,
                        plan: plan,
                    })
                        .then((payload) => payload.json())
                        .then((json) => {
                            this.props.setCurrentTreatmentPlan(
                                json.payload.plan
                            );
                        })
                        .catch(() => {
                            rootStore.dispatch(
                                setErrorState(
                                    true,
                                    clientErrorMessages.TPLAN_UPDATE_FAILED
                                )
                            );
                        });
                }
            })
            .catch(() => {
                scope.setState({ loadState: pageLoadStates.FAILED });
            });
    }

    setWorkflowState(newState) {
        this.setState({ activeWorkflowState: newState });
    }

    setEditsSavedState(newState) {
        this.setState({ editsSaved: newState });
    }

    getMeshURLs(plan) {
        let scope = this;

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
        };

        legacy_loadBxCores(scope, plan, () => this.forceUpdate());
        loadAllProstateSegmentations(scope, plan, ids, true, () =>
            this.forceUpdate()
        );

        legacy_loadROIs(scope, plan, ids);
    }

    getMRIURLs(plan) {
        let scope = this;

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
        };

        legacy_loadMRI(scope, plan, ids);
    }

    prevStep() {
        this.props.setStepActive(SEG_MRI_STEP, false);
        this.props.setStepComplete(SEG_MRI_STEP, false);
        this.props.setStepActive(UPLOAD_STEP, true);

        let plan = this.props.currentTreatmentPlan;
        plan.SavedData.PlanCreationStep = UPLOAD_STEP;
        this.props.setCurrentTreatmentPlan(plan);

        updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        });
    }

    async nextStep(confirmedSegmentation) {
        let scope = this;
        let plan = this.props.currentTreatmentPlan;

        let newUri = _getConfirmedProstateURI(confirmedSegmentation, plan);

        plan.SavedData.ConfirmedProstateSegmentation = confirmedSegmentation;

        if (!TreatmentPlan.isMRIOnly(plan)) {
            plan.SavedData.PlanCreationStep = BIOPSY_STEP;
        } else {
            plan.SavedData.PlanCreationStep = SUMMARY_STEP;

            let freehandCoreStructureMRIFrame = {
                BoundingBox: null,
                Center: null,
                Path: PROSTATE_CORE_STRUCTURE_VTK_PATH,
                URI: newUri,
            };

            plan = TreatmentPlan.addCoreStructureToPlan(
                plan,
                freehandCoreStructureMRIFrame
            );
        }

        plan = await this.setPlanCoreStructureProperties(newUri, plan);

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
            planuuid: scope.props.planuuid,
        };

        _updateTreatmentPlanWithConfirmedProstateSegmentation(
            scope,
            plan,
            newUri,
            ids
        );
    }

    async setPlanCoreStructureProperties(uri, plan) {
        if (!plan.TreatmentPlan.StructureData.CoreStructure.VolumeCC) {
            const result = await estimateMeshVolume({
                uri: uri,
                token: this.props.authToken,
            });
            if (result.meshVolume) {
                const prostate = {
                    Name: prostateStructure.PROSTATE,
                    UUID: uuidv1(),
                    Color: structureColorPalette.PROSTATE,
                    Opacity: prostateStructure.DEFAULT_PROSTATE_OPACITY,
                    VolumeCC: result.meshVolume,
                };
                plan.TreatmentPlan.StructureData.CoreStructure = prostate;
            }
        }

        if (
            !TreatmentPlan.getStructureInFrame(
                TreatmentPlan.getCoreStructure(plan.TreatmentPlan),
                refFrames.MRI
            )
        ) {
            plan.TreatmentPlan.StructureData.CoreStructure.Frame = {};
            plan.TreatmentPlan.StructureData.CoreStructure.Frame[
                refFrames.MRI
            ] = {
                URI: EMPTY_STRING,
            };
        }

        plan.TreatmentPlan.StructureData.CoreStructure.Frame[
            refFrames.MRI
        ].URI = uri;

        return plan;
    }

    isAProstateSegmentationAvailableCheck() {
        let plan = this.props.currentTreatmentPlan;
        let tplan = plan.TreatmentPlan;
        return (
            (TreatmentPlan.getStructureInFrame(
                TreatmentPlan.getCoreStructure(tplan),
                refFrames.MRI
            ) &&
                TreatmentPlan.getStructureInFrame(
                    TreatmentPlan.getCoreStructure(tplan),
                    refFrames.MRI
                ).URI !== EMPTY_STRING) ||
            plan.SavedData.AIProstateURI ||
            plan.SavedData.AIEditedProstateURI ||
            plan.SavedData.UploadedProstateURI ||
            plan.SavedData.UploadedEditedProstateURI
        );
    }

    getRequirements() {
        this.requirements.required[0].satisfied =
            this.props.currentTreatmentPlan.SavedData
                .ConfirmedProstateSegmentation &&
            this.props.currentTreatmentPlan.SavedData
                .ConfirmedProstateSegmentation !== EMPTY_STRING;
        this.requirements.optionals[0].satisfied = false;
        this.requirements.optionals[1].satisfied = false;
        return this.requirements;
    }

    selectAlertCallback() {
        if (
            this.state.activeWorkflowState ===
            segmentationDisplayOptions.SEG_EDITING
        ) {
            this.setState({
                displayAlert: step2AlertOptions.CONFIRM_EDITS,
            });
            return true;
        }
        if (this.isAProstateSegmentationAvailableCheck()) {
            this.setState({
                displayAlert: step2AlertOptions.SELECT_SEGMENTATION,
            });
            return true;
        } else {
            this.requirementsModalRef.openModal();
            return false;
        }
    }

    getAlert(alertState) {
        let alert;

        switch (alertState) {
            case step2AlertOptions.CONFIRM_EDITS:
                alert = (
                    <DecisionAlertCard
                        id={"confirm-seg-edits"}
                        yesBtnCallback={() => {
                            this.handleSaveProstateEdits();
                        }}
                        noBtnCallback={() => {
                            this.setState({
                                displayAlert: step2AlertOptions.NONE,
                            });
                        }}
                        noBtnTxt={"Resume editing"}
                        yesBtnTxt={"Save edits"}
                    >
                        <div className={"confirm-seg-edits-card-header"}>
                            <p className={"display-26"}>
                                Save edits to prostate?
                            </p>
                        </div>
                        <div className={"confirm-seg-edits-card-body"}>
                            <div className={"confirm-seg-edits-row"}>
                                <p className={"display-16"}>
                                    Would you like to save the edits made to the
                                    prostate before continuing?
                                </p>
                            </div>
                        </div>
                    </DecisionAlertCard>
                );
                break;

            case step2AlertOptions.SELECT_SEGMENTATION:
                alert = (
                    <Step2ConfirmPrompt
                        onNoClick={() => {
                            this.setState({
                                displayAlert: step2AlertOptions.NONE,
                            });
                        }}
                        onYesClick={this.nextStep}
                        patientAnatomyModel={this.patientAnatomyModel}
                    />
                );
                break;

            default:
                alert = null;
        }

        return alert;
    }

    handleSaveProstateEdits() {
        let plan = this.props.currentTreatmentPlan;

        let ids = {
            token: this.props.authToken,
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            visituuid: this.props.visituuid,
            scanID: plan.MetaData.MasterScanID,
        };

        const saveProstateCallback = () => {
            this.setState({
                displayAlert: step2AlertOptions.SELECT_SEGMENTATION,
            });
        };

        saveProstateSegmentationEdits(
            ids,
            plan,
            this.meshEditController,
            this.patientAnatomyModel,
            saveProstateCallback
        );
    }

    render() {
        let nextStep = TreatmentPlan.isMRIOnly(this.props.currentTreatmentPlan)
            ? stepPath.STEP6
            : stepPath.STEP3;
        return (
            <div className={"create-plan-seg-mri-screen"}>
                <TopBar>
                    <AvendaLogo id={"create-plan-avenda-logo"} to={"/home"} />
                    <div className={"create-plan-step-bar-container"}>
                        <div className={"step-bar-prev-btn-group"}>
                            <PreviousStepButton
                                to={stepPath.STEP1}
                                callback={this.prevStep}
                                isReady={
                                    this.state.loadState !==
                                    pageLoadStates.LOADING
                                }
                            />
                            <StepMarkerBar />
                        </div>
                        <NextStepButton
                            reqsComplete={this.isAProstateSegmentationAvailableCheck()}
                            reqsCompleteTxt={"Verify Segmentation"}
                            noLink={true}
                            text={""}
                            to={nextStep}
                            callback={this.selectAlertCallback}
                        />
                    </div>
                    <ProfileMenu />
                </TopBar>
                <RequirementsModal
                    id={"seg-mri-pull-tab"}
                    onRef={(ref) => (this.requirementsModalRef = ref)}
                    requirements={this.getRequirements()}
                />
                <SegmentationPanel
                    patientAnatomyModel={this.patientAnatomyModel}
                    meshEditController={this.meshEditController}
                    setWorkflowState={this.setWorkflowState}
                    activeWorkflowState={this.state.activeWorkflowState}
                    currentTreatmentPlan={this.props.currentTreatmentPlan}
                    setCurrentTreatmentPlan={this.props.setCurrentTreatmentPlan}
                    editsSaved={this.state.editsSaved}
                    setEditsSavedState={this.setEditsSavedState}
                    ids={{
                        token: this.props.authToken,
                        useruuid: this.props.useruuid,
                        patientuuid: this.props.patientuuid,
                        planuuid: this.props.planuuid,
                        visituuid: this.props.visituuid,
                    }}
                />
                {getLoadingScreen(this.state.loadState)}
                <div className="mri-scene-container">
                    <MRIControlBar>
                        <div className="mri-control-bar-left">
                            <ButtonGroup>
                                <MRIViewerButton
                                    toolTipText={
                                        mriViewerButtonTooltip.SCENE_ORIENTATION
                                    }
                                    name="nav-cube"
                                    imgBlue={cubeBlue}
                                    imgDark={cubeDark}
                                    imgLight={cubeLight}
                                    alt={
                                        mriViewerButtonTooltip.SCENE_ORIENTATION
                                    }
                                >
                                    <NavCubeModal
                                        mriModel={this.mriModel}
                                        resetTick={() =>
                                            this.sliceControlRef.current.reset()
                                        }
                                    />
                                </MRIViewerButton>
                                <MRIViewerButton
                                    toolTipText={mriViewerButtonTooltip.FILTER}
                                    name={"filter"}
                                    imgBlue={filterBlue}
                                    imgDark={filterDark}
                                    imgLight={filterLight}
                                    alt={mriViewerButtonTooltip.FILTER}
                                >
                                    <FilterToggle
                                        mriModel={this.mriModel}
                                        patientAnatomyModel={
                                            this.patientAnatomyModel
                                        }
                                        displaySettings={{
                                            axesEnabled: true,
                                            mriEnabled: true,
                                            noMRIOption: true,
                                        }}
                                        ignoreBxCoreLabels={true}
                                    />
                                </MRIViewerButton>
                            </ButtonGroup>
                            <SliceControl
                                ref={this.sliceControlRef}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                            />
                        </div>
                        <div className="mri-control-bar-right mr-10">
                            <ResetViewerButton
                                toolTipText={RESET_VIEW}
                                resetViewer={() =>
                                    resetViewer(
                                        this.meshRef,
                                        this.mriRef,
                                        this.sliceControlRef,
                                        this.mriModel
                                    )
                                }
                            />
                        </div>
                    </MRIControlBar>
                    <div
                        className="mri-viewer"
                        onClick={() => {
                            this.setEditsSavedState(false);
                        }}
                    >
                        <SplitPane
                            defaultSize={"60%"}
                            minSize={"0px"}
                            split={"vertical"}
                            pane1ClassName={"mri-pane"}
                            pane2ClassName={"mesh-pane"}
                            onDragFinished={() => {
                                this.mriRef.current.onWindowResize();
                                this.meshRef.current.onWindowResize();
                            }}
                        >
                            <MRIViewer
                                ref={this.mriRef}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                                patientAnatomyModel={this.patientAnatomyModel}
                                meshEditController={this.meshEditController}
                            />
                            <MeshViewer
                                ref={this.meshRef}
                                staticViewer={true}
                                displaySettings={{
                                    mriEnabled: false,
                                    composerEnabled: true,
                                }}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                                patientAnatomyModel={this.patientAnatomyModel}
                                patientAnatomyController={
                                    this.patientAnatomyController
                                }
                                activateCoreClick={false}
                            />
                        </SplitPane>
                    </div>
                </div>
                {this.getAlert(this.state.displayAlert)}
            </div>
        );
    }
}

CreateTPlan_2SegMRI.propTypes = {
    authToken: PropTypes.string,
    useruuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    visituuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    setStepActive: PropTypes.func,
    setStepComplete: PropTypes.func,
    setCurrentTreatmentPlan: PropTypes.func,
};

CreateTPlan_2SegMRI.defaultProps = {
    useruuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    visituuid: EMPTY_STRING,
};

const mapStateToProps = function (state, ownProps) {
    let displaySettings = isEmpty(ownProps.displaySettings)
        ? state.ImageViewerReducer.displaySettings
        : ownProps.displaySettings;
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        displaySettings: displaySettings,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTPlan_2SegMRI);

function _updateTreatmentPlanWithConfirmedProstateSegmentation(
    scope,
    plan,
    uri,
    ids
) {
    let isFreehandPlan = TreatmentPlan.isMRIOnly(plan);
    getMeshProperties({
        uri: uri,
        token: ids.authToken,
    }).then((result) => {
        if (result.message === SUCCESS) {
            plan.TreatmentPlan.StructureData.CoreStructure.Frame[
                refFrames.MRI
            ].Center = result.payload.mesh.center;
            plan.TreatmentPlan.StructureData.CoreStructure.Frame[
                refFrames.MRI
            ].BoundingBox = result.payload.mesh.bbox;
        }

        updateTreatmentPlan({
            ...ids,
            plan: plan,
        })
            .then((payload) => payload.json())
            .then((json) => {
                scope.props.setCurrentTreatmentPlan(json.payload.plan);
            })
            .finally(() => {
                _setWorkflowSteps(isFreehandPlan, scope);
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
                );
            });
    });
}

function _setWorkflowSteps(isFreehandPlan, scope) {
    scope.props.setStepComplete(SEG_MRI_STEP, true);
    scope.props.setStepActive(SEG_MRI_STEP, false);

    if (!isFreehandPlan) {
        scope.props.setStepActive(BIOPSY_STEP, true);
        scope.props.history.push(stepPath.STEP3);
        return;
    }

    scope.props.setStepActive(FREEHAND_SUMMARY_STEP, true);
    scope.props.history.push(stepPath.STEP6);
}

function _getConfirmedProstateURI(confirmedSegmentation, plan) {
    /*
        Always prefer the edited segmentation over the non-edited segmentation
     */
    let newUri;

    if (confirmedSegmentation === prostateSegOptions.AI_SEG) {
        if (
            plan.SavedData.AIEditedProstateURI &&
            plan.SavedData.AIEditedProstateURI !== EMPTY_STRING
        ) {
            newUri = plan.SavedData.AIEditedProstateURI;
        } else {
            newUri = plan.SavedData.AIProstateURI;
        }
    } else if (confirmedSegmentation === prostateSegOptions.UPLOADED_SEG) {
        if (
            plan.SavedData.UploadedEditedProstateURI &&
            plan.SavedData.UploadedEditedProstateURI !== EMPTY_STRING
        ) {
            newUri = plan.SavedData.UploadedEditedProstateURI;
        } else {
            newUri = plan.SavedData.UploadedProstateURI;
        }
    }

    return newUri;
}
