export const NO_STATUS_TEXT = "";
export const NEEDS_REVIEW_TEXT = "Needs Review";
export const NEEDS_DATA_TEXT = "Needs Data";
export const PATHOLOGY_ENTRY_REQUESTED_TEXT = "Pathology Entry Requested";
export const CEM_GENERATED_TEXT = "CEM Generated";
export const CEM_FAILED_TEXT = "CEM Generation Failed";

export const planStageEnum = {
    NO_STATUS: 0,
    NEEDS_REVIEW: 1,
    NEEDS_DATA: 2,
    PATHOLOGY_ENTRY_REQUESTED: 3,
    CEM_GENERATED: 4,
    CEM_FAILED: 5,
};

export const CHECK_BOX_ID = "needs-review-check-box";
export const CHECK_BOX_ALT_TEXT = "Needs Review Check Box";
