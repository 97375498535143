import React, { useState } from "react";
import "./styles.css";
import DownArrow from "../IconComponents/DownArrow";
import PropTypes from "prop-types";

// TODO: Refactor this component: improve code quality, use Tailwind for styling, and TypeScript
export default function Accordion({
    iconComponent: IconComponent,
    title,
    children,
}) {
    const [isActive, setIsActive] = useState(false);

    function handleAccordionClick(e) {
        e.stopPropagation();
        setIsActive(!isActive);
    }

    return (
        <div className="accordion-container">
            <div className="accordion-header" onClick={handleAccordionClick}>
                <div className="accordion-logo-title">
                    <div>{IconComponent && <IconComponent />}</div>
                    <div className="display-regular-22 text-blue">{title}</div>
                </div>
                {isActive ? (
                    <DownArrow className="accordion-arrow-up" />
                ) : (
                    <DownArrow className="click-outside-ignore" />
                )}
            </div>
            {isActive && (
                <div
                    className="accordion-content click-outside-ignore"
                    onClick={handleAccordionClick}
                >
                    {children}
                </div>
            )}
        </div>
    );
}

Accordion.propTypes = {
    iconComponent: PropTypes.elementType,
    title: PropTypes.string,
    children: PropTypes.node,
};
