import {
    API_ENDPOINT,
    API_VERSION,
    httpMethods,
    SUCCESS,
    clientErrorMessages,
    FAILURE,
    PATIENT_ALREADY_SHARED,
    EMPTY_STRING,
    N_A_STRING,
} from "../../constants";
import { parsingCardLabels } from "./constants";
import { vsprintf } from "sprintf-js";
import { toQueryString } from "../../helpers/helpers";
import { setErrorState } from "../../redux/error_banner/actions";
import { rootStore } from "../../redux/store";
import { getAbortController } from "../../components/__shared__/AbortController/AbortController";

// Creates new entry in treatment plan storage.
// Returns patientuuid
export async function createNewPatient(token, useruuid) {
    return await fetch(
        vsprintf("%s/api/%s/patient", [API_ENDPOINT, API_VERSION]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                useruuid: useruuid,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.TPLAN_CREATE_FAILED);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_CREATE_FAILED)
            );
            return null;
        });
}

export async function createNewPlan(token, patientuuid) {
    return await fetch(
        vsprintf("%s/api/%s/patient/%s/plan", [
            API_ENDPOINT,
            API_VERSION,
            patientuuid,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.TPLAN_CREATE_FAILED);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_CREATE_FAILED)
            );
            return null;
        });
}

export async function createUserPatientShare(token, userUuid, patientUuid) {
    return await fetch(
        vsprintf("%s/api/%s/user/%s/patients/%s", [
            API_ENDPOINT,
            API_VERSION,
            userUuid,
            patientUuid,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (
                json.message === SUCCESS ||
                (json.message === FAILURE &&
                    json.payload.status === PATIENT_ALREADY_SHARED)
            ) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.FAILED_TO_SHARE_PATIENT);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.FAILED_TO_SHARE_PATIENT)
            );
            return null;
        });
}

// Creates new entry in phi data storage.
// Returns visituuid
export async function createNewVisit(token, patientuuid) {
    return await fetch(
        vsprintf("%s/api/%s/data/phi/patient/%s/visit", [
            API_ENDPOINT,
            API_VERSION,
            patientuuid,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                patientuuid: patientuuid,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.TPLAN_CREATE_FAILED);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_CREATE_FAILED)
            );
            return null;
        });
}

export async function getRawUploadSignedURL(token, key) {
    let queryStringParams = [];

    if (key !== null) {
        queryStringParams.push(vsprintf("key=%s", key));
    }

    let queryString = toQueryString(queryStringParams);
    let endpoint = vsprintf("%s/api/%s/upload/url%s", [
        API_ENDPOINT,
        API_VERSION,
        queryString,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            } else {
                throw new Error(clientErrorMessages.PRESIGNED_URL_FAILED);
            }
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.FILE_UPLOAD_FAILED)
            );
            return null;
        });
}

export async function putFileS3XML(signedUrl, file, callback, isPublic) {
    if (!signedUrl || !file) {
        return;
    }

    let formData = new FormData();
    formData.append("upfile", file);

    let req = new XMLHttpRequest();

    let abortController = getAbortController();
    let signal = abortController.signal;

    return await new Promise(
        function (resolve, reject) {
            req.onreadystatechange = function () {
                // Only run if the request is complete
                if (req.readyState !== 4) return;

                // Process the response
                if (req.status >= 200 && req.status < 300) {
                    // If successful
                    resolve(req);
                } else {
                    // If failed
                    reject({
                        status: req.status,
                        statusText: req.statusText,
                    });
                }
            };

            if (callback) {
                req.upload.onprogress = function (e) {
                    if (e.lengthComputable) {
                        callback((e.loaded / e.total) * 100);
                    }
                };
            }

            req.open(httpMethods.PUT, signedUrl, true);
            req.setRequestHeader("Content-type", file.type);

            if (isPublic) {
                req.setRequestHeader("x-amz-acl", "public-read");
            }

            req.send(file);
        },
        { signal }
    );
}

export function startParsing(input) {
    let route = "";
    switch (input.label) {
        case parsingCardLabels.BIOPSY:
            route = vsprintf("%s/api/%s/monitor/proc/parse/biopsy", [
                API_ENDPOINT,
                API_VERSION,
            ]);
            break;
        case parsingCardLabels.REPORT:
            route = vsprintf("%s/api/%s/monitor/proc/parse/attachment", [
                API_ENDPOINT,
                API_VERSION,
            ]);
            break;
        case parsingCardLabels.TEMPLATE:
            route = vsprintf("%s/api/%s/monitor/proc/parse/attachment", [
                API_ENDPOINT,
                API_VERSION,
            ]);
            break;
        case parsingCardLabels.MRI:
            route = vsprintf("%s/api/%s/monitor/proc/parse/mri", [
                API_ENDPOINT,
                API_VERSION,
            ]);
            break;
        default:
            throw new Error(clientErrorMessages.PARSING_UNACCEPTED_INPUT_TYPE);
    }

    if (input.isFreehand) {
        route = vsprintf("%s/api/%s/monitor/proc/parse/biopsy", [
            API_ENDPOINT,
            API_VERSION,
        ]);
    }

    return fetch(route, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        body: JSON.stringify({
            useruuid: input.useruuid,
            patientuuid: input.patientuuid,
            planuuid: input.planuuid,
            visituuid: input.visituuid,
            queuetag: input.queuetag,
            filekey: input.filekey,
            isdir: input.isdir,
            isfreehand: input.isFreehand,
            attachmentType: input.label,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then(
            (json) =>
                json.message === SUCCESS && json.payload.status === SUCCESS
        )
        .catch(() => null);
}

export async function getParsingStatus(filekey) {
    return await fetch(
        vsprintf("%s/api/%s/monitor/proc/parse/biopsy?key=%s", [
            API_ENDPOINT,
            API_VERSION,
            filekey,
        ]),
        {
            method: httpMethods.GET,
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => json.payload)
        .catch(() => null);
}

export async function getTreatmentPlan(token, patientuuid, planuuid) {
    return await fetch(
        vsprintf("%s/api/%s/patient/%s/plan/%s", [
            API_ENDPOINT,
            API_VERSION,
            patientuuid,
            planuuid,
        ]),
        {
            method: httpMethods.GET,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            mode: "cors",
        }
    ).catch(() => {
        rootStore.dispatch(
            setErrorState(true, clientErrorMessages.TPLAN_RETRIEVE_FAILED)
        );
        return null;
    });
}

export function acceptableFileType(file, label) {
    let isValidZipFile =
        file.type === "application/zip" ||
        file.type === "application/x-zip-compressed";
    let isValidAttachment =
        file.type === "application/pdf" ||
        file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || // .docx
        file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || // .xlsx
        file.type === "text/plain" || // .txt
        file.type === "application/rtf" || // .rtf
        file.type === "text/rtf" || // .rtf
        file.type === "text/csv" || // .csv
        file.type === "text/tab-separated-values" || // .tsv
        file.type === "image/jpeg" || // .jpeg, .jpg
        file.type === "image/png" || // .png
        file.type === "image/heic" || // .heic
        file.type === "image/bmp" || // .bmp
        file.type === "image/tiff"; // .tiff

    switch (label) {
        case parsingCardLabels.BIOPSY:
            return isValidZipFile;
        case parsingCardLabels.REPORT:
            return isValidAttachment;
        case parsingCardLabels.TEMPLATE:
            return isValidAttachment;
        case parsingCardLabels.MRI:
            return isValidZipFile;
        default:
            return false;
    }
}

export async function updateVisit(input) {
    let route = vsprintf("%s/api/%s/data/phi/patient/%s/visit/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.patientuuid,
        input.visituuid,
    ]);
    return fetch(route, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        body: JSON.stringify({
            visit: input.visit,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then(
            (json) =>
                json.message === SUCCESS && json.payload.status === SUCCESS
        )
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
            );
            return null;
        });
}

//Here for testing, will remove later
export function getVisit(input) {
    let route = vsprintf("%s/api/%s/data/phi/patient/%s/visit/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.patientuuid,
        input.visituuid,
    ]);
    return fetch(route, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.TPLAN_CREATE_FAILED);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_CREATE_FAILED)
            );
            return null;
        });
}

export async function updatePatientData(input) {
    let route = vsprintf("%s/api/%s/patient/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.patientuuid,
    ]);
    return fetch(route, {
        method: httpMethods.PUT,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        body: JSON.stringify({
            patientData: input.patientData,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then(
            (json) =>
                json.message === SUCCESS && json.payload.status === SUCCESS
        )
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
            );
            return null;
        });
}

export function deleteUpload(input) {
    let route = vsprintf("%s/api/%s/deleteUpload", [API_ENDPOINT, API_VERSION]);
    return fetch(route, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        body: JSON.stringify({
            useruuid: input.useruuid,
            patientuuid: input.patientuuid,
            planuuid: input.planuuid,
            visituuid: input.visituuid,
            iteration: input.iteration,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then(
            (json) =>
                json.message === SUCCESS && json.payload.status === SUCCESS
        )
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
            );
            return null;
        });
}

export function handleInvalidDates(dateStr) {
    const currentDate = new Date();
    const dobRegex = /^\d{4}-\d{2}-\d{2}$/;

    // Check if the date string format is correct
    if (!dobRegex.test(dateStr)) {
        return "Invalid Format, use YYYY-MM-DD";
    }

    const [year, month, day] = dateStr.split("-").map(Number);

    // Check if the month is valid (between 1 and 12)
    if (month < 1 || month > 12) {
        return "Invalid Date";
    }

    // Create a new date object to check day/month/year validity
    const inputDate = new Date(year, month - 1, day); // JavaScript months are 0-based (Jan = 0, Dec = 11)

    // Check if the date is invalid (e.g., out-of-range day for the given month/year)
    if (
        inputDate.getFullYear() !== year ||
        inputDate.getMonth() + 1 !== month || // Check if month matches
        inputDate.getDate() !== day // Check if day matches
    ) {
        return "Invalid Date";
    }

    // Check if the date is in the future
    if (inputDate > currentDate) {
        return "Invalid Date";
    }

    return EMPTY_STRING; // Return empty string if the date is valid
}

export function extractDateOnly(dateTimeString) {
    if (!dateTimeString) {
        return N_A_STRING;
    }
    const dateMatch = dateTimeString.match(/^\d{4}-\d{2}-\d{2}/);
    return dateMatch ? dateMatch[0] : N_A_STRING;
}
