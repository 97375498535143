import React, { Component } from "react";
import { EMPTY_STRING, keyStrings, SUCCESS } from "../../../constants";
import "./styles.css";
import AHAlphaField from "../../__shared__/StyleGuide/AHAlphaField";
import { checkOTPCode } from "../helpers";
import { withRouter } from "react-router";
import { Else, If, Then } from "react-if";
import PropTypes from "prop-types";
import { getUser } from "../../Login/helpers";

const otpLength = 6;

class OTPCodeWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMsg: EMPTY_STRING,
            otpCode: ["", "", "", "", "", ""],
        };

        this.otpInputRefs = [null, null, null, null, null, null];

        this.updateOTPCodeDigit = this.updateOTPCodeDigit.bind(this);
        this.generateAlphaFields = this.generateAlphaFields.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
    }

    onSuccess(authToken) {
        if (this.props.onSuccess) {
            getUser(this.props.username, authToken).then((result) => {
                if (result && result.status === SUCCESS) {
                    this.props.onSuccess(result.user, authToken);
                }
            });
        }
    }

    updateOTPCodeDigit(digit, input) {
        input = input.key;

        if (input === keyStrings.BACKSPACE) {
            if (!this.state.otpCode[parseInt(digit)]) {
                // backspace will move left if cell is empty
                if (digit > 0) {
                    this.otpInputRefs[parseInt(digit - 1)].focus();
                }

                return;
            } else {
                // otherwise backspace will just clear the cell
                let newOtpCode = this.state.otpCode;
                newOtpCode[parseInt(digit)] = "";

                this.setState({
                    otpCode: newOtpCode,
                });

                return;
            }
        } else if (input === keyStrings.ARROW_LEFT) {
            if (digit > 0) {
                this.otpInputRefs[parseInt(digit - 1)].focus();
            }
        } else if (input === keyStrings.ARROW_RIGHT) {
            if (digit < otpLength - 1) {
                this.otpInputRefs[parseInt(digit + 1)].focus();
            }
        }

        if (!Number.isInteger(parseInt(input))) {
            return;
        }

        let newOtpCode = this.state.otpCode;
        newOtpCode[parseInt(digit)] = input;

        this.setState({
            otpCode: newOtpCode,
        });

        if (digit < otpLength - 1) {
            this.otpInputRefs[parseInt(digit + 1)].focus();
            return;
        }

        if (digit === otpLength - 1) {
            let codeStr = "";

            newOtpCode.forEach((integer) => (codeStr += String(integer)));

            checkOTPCode(
                this.props.useruuid,
                codeStr,
                this.props.initialVerification
            ).then((result) => {
                if (result && result.status === SUCCESS && result.authToken) {
                    this.onSuccess(result.authToken);
                } else {
                    this.setState({
                        errorMsg: "Invalid code. Please try again.",
                        otpCode: ["", "", "", "", "", ""],
                    });
                    this.otpInputRefs[0].focus();
                }
            });
        }
    }

    generateAlphaFields() {
        let alphaFields = [];
        for (let i = 0; i < otpLength; i++) {
            alphaFields.push(
                <AHAlphaField
                    setInputRef={(input) => {
                        this.otpInputRefs[parseInt(i)] = input;
                    }}
                    label={""}
                    value={this.state.otpCode[parseInt(i)]}
                    placeholder={""}
                    onKeyDown={(_, e) => this.updateOTPCodeDigit(i, e)}
                    type={"text"}
                    maxLength={1}
                    className={"otp-code-field"}
                    showLabel={false}
                />
            );
        }
        return alphaFields;
    }

    render() {
        return (
            <div className={"signup-screen"}>
                <div className={"signup-form"}>
                    <p
                        className={"display-36 black-text"}
                        style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                        Submit MFA Code
                    </p>

                    <p
                        className={"display-14 black-text"}
                        style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                        Please enter the 6 digits on your MFA app to link your
                        account.
                    </p>
                    <div className={"otp-code-container"}>
                        {this.generateAlphaFields()}
                    </div>
                    <If condition={this.state.errorMsg}>
                        <Then>
                            <div
                                style={{
                                    textAlign: "center",
                                    marginBottom: "20px",
                                }}
                            >
                                <p
                                    className={
                                        "display-14-bold red d-inline-block ml-2 text-center"
                                    }
                                >
                                    {this.state.errorMsg}
                                </p>
                            </div>
                        </Then>
                        <Else>
                            <div style={{ height: 20 }} />
                        </Else>
                    </If>
                </div>
            </div>
        );
    }
}

OTPCodeWidget.propTypes = {
    useruuid: PropTypes.string,
    username: PropTypes.string,
    initialVerification: PropTypes.bool,
    onSuccess: PropTypes.func,
    history: PropTypes.object,
};

OTPCodeWidget.defaultProps = {
    useruuid: EMPTY_STRING,
    username: EMPTY_STRING,
    initialVerification: false,
    onSuccess: undefined,
};

export default withRouter(OTPCodeWidget);
