import React, { useEffect, useState } from "react";
import "./styles.css";
import PatientShareCardHeader from "./PatientShareCardHeader";
import PatientNotesTab from "./PatientNotesTab";
import PatientShareTab from "./PatientShareTab";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { planStageEnum } from "../RequirementsModal/constants";

function PatientShareCard(props) {
    const [openTab, setOpenTab] = useState("share");
    const currentPlan = props.currentTreatmentPlan || {};
    const savedData = currentPlan.SavedData || {};
    const planCreationStep =
        savedData.PlanCreationStep !== undefined
            ? savedData.PlanCreationStep
            : 0;
    const planNotes =
        currentPlan?.TreatmentPlan?.AdditionalData?.PlanNotes || {};
    const needsReviewState = currentPlan?.MetaData?.PlanStage;

    useEffect(() => {
        // If there is at least one note and the Plan Status is set to "needs review", open the Patient Note Tab
        if (
            planNotes &&
            Object.keys(planNotes).length > 0 &&
            needsReviewState === planStageEnum.NEEDS_REVIEW
        ) {
            setOpenTab("notes");
        }
    }, []);

    function handleTabChange(tab) {
        setOpenTab(tab);
    }

    return (
        <div className="patient-share-card">
            <PatientShareCardHeader
                openTab={openTab}
                onTabChange={handleTabChange}
            />
            {openTab === "share" && <PatientShareTab />}
            {openTab === "notes" && (
                <PatientNotesTab step={planCreationStep + 1} />
            )}
        </div>
    );
}

PatientShareCard.propTypes = {
    currentTreatmentPlan: PropTypes.object,
};

const mapStateToProps = function (state) {
    return {
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

export default connect(mapStateToProps)(PatientShareCard);
