import React, { Component } from "react";
import { SystematicTemplateNames } from "../constants"; // Import or define your CSS file
import { Else, If, Then } from "react-if";
import "./styles.css";
import PropTypes from "prop-types";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";

class BiopsyTemplateFrame extends Component {
    render() {
        let bxTemplate = TreatmentPlan.getBxTemplate(
            this.props.currentTreatmentPlan.TreatmentPlan
        );

        let renderMode;
        if (
            bxTemplate === SystematicTemplateNames.TR_12 ||
            bxTemplate === SystematicTemplateNames.TP_12
        ) {
            renderMode = "column";
        } else {
            renderMode = "row";
        }

        return (
            <div className={`biopsy-interface ${renderMode}`}>
                <div className={`path-view-block-1 ${renderMode}`}>
                    <If condition={renderMode === "row"}>
                        <Then>{this.props.children[0]}</Then>
                        <Else>
                            <div className="path-view-block-1-sub-block-1">
                                {this.props.children[0]}
                            </div>
                            <div className="path-view-block-1-sub-block-2">
                                {this.props.children[1]}
                            </div>
                        </Else>
                    </If>
                </div>
                <div className={`path-view-block-2 ${renderMode}`}>
                    <If condition={renderMode === "row"}>
                        <Then>
                            <div className="path-view-block-2-sub-block-1">
                                {this.props.children[1]}
                            </div>
                            <div className="path-view-block-2-sub-block-2">
                                {this.props.children[2]}
                            </div>
                        </Then>
                        <Else>{this.props.children[2]}</Else>
                    </If>
                </div>
            </div>
        );
    }
}

BiopsyTemplateFrame.propTypes = {
    currentTreatmentPlan: PropTypes.object,
    children: PropTypes.array,
};

BiopsyTemplateFrame.defaultProps = {
    currentTreatmentPlan: {},
};

export default BiopsyTemplateFrame;
