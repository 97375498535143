import { DESCENDING, EMPTY_STRING } from "../../constants";
import { sortCategories } from "./constants";

export const initialState = {
    displayHistoryFlag: false,
    currentSortOrder: DESCENDING,
    currentSortCategory: sortCategories.PLAN_STATUS,
    downloadProperties: {
        selectActive: false,
        patientuuid: EMPTY_STRING,
        planuuid: EMPTY_STRING,
    },
};
