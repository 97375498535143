import {
    createMargin,
    updateTreatmentPlan,
} from "../../../helpers/backend_api";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { clientErrorMessages, refFrames, SUCCESS } from "../../../constants";
import { rootStore } from "../../../redux/store";
import { setErrorState } from "../../../redux/error_banner/actions";

export function legacy_getNewLesionContour(scope, userSelectedCPMThreshold) {
    let plan = scope.props.currentTreatmentPlan;

    if (scope.state.updatingMargin) {
        return;
    }

    scope.setState({ updatingMargin: true });

    createMargin(_getCreateMarginInput(userSelectedCPMThreshold, plan)).then(
        (r) => {
            if (r && r.message === SUCCESS) {
                legacy_packPlanWithPayload(scope, plan, r.payload);
                legacy_updatePlan(scope, plan);
            } else {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.MARGIN_CREATION_FAILED
                    )
                );
            }

            scope.setState({ updatingMargin: false });

            if (scope.props.onMarginCreation) {
                scope.props.onMarginCreation();
            }
        }
    );
}

export function getNewLesionContour({
    currentTreatmentPlan,
    updatingMargin,
    setUpdatingMargin,
    userSelectedCpmThreshold,
    setUserSelectedProstateCoverage,
    setUserSelectedMarksScore,
    setUserSelectedLesionVolume,
    authToken,
    useruuid,
    patientuuid,
    planuuid,
    setCurrentTreatmentPlan,
    onMarginCreation,
}) {
    if (updatingMargin) {
        return;
    }

    setUpdatingMargin(true);

    createMargin(
        _getCreateMarginInput(userSelectedCpmThreshold, currentTreatmentPlan)
    )
        .then((response) => {
            if (response && response.message === SUCCESS) {
                packPlanWithPayload({
                    currentTreatmentPlan,
                    payload: response.payload,
                    setUserSelectedProstateCoverage,
                    setUserSelectedMarksScore,
                    setUserSelectedLesionVolume,
                });
                updatePlan(
                    currentTreatmentPlan,
                    authToken,
                    useruuid,
                    patientuuid,
                    planuuid,
                    setCurrentTreatmentPlan
                );
            }
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.MARGIN_CREATION_FAILED)
            );
        })
        .finally(() => {
            setUpdatingMargin(false);

            if (onMarginCreation) {
                onMarginCreation();
            }
        });
}

function _getCreateMarginInput(userSelectedCPMThreshold, plan) {
    return {
        prostateURI: TreatmentPlan.getStructureInFrame(
            TreatmentPlan.getCoreStructure(plan.TreatmentPlan),
            refFrames.MRI
        ).URI,
        cpmURI: plan.SavedData.CPMURI,
        customCPMThreshold:
            userSelectedCPMThreshold != null
                ? userSelectedCPMThreshold
                : plan.SavedData.OptimalCPMThreshold,
        outputFormat: ".vtk",
    };
}

function legacy_packPlanWithPayload(scope, plan, payload) {
    plan.TreatmentPlan.StructureData.Margins[0].VolumeCC = payload.marginVolume;
    plan.TreatmentPlan.StructureData.CoreStructure.VolumeCC =
        payload.prostateVolume;
    plan.SavedData.UserSelectedCPMThreshold = payload.optimalCPMThreshold;
    plan.SavedData.UserSelectedMarksScore = payload.marksScore;
    plan.SavedData.UserSelectedLesionCoverageOfProstate =
        payload.prostateCoverage;
    plan.SavedData.IsPatientFTEligible = payload.focalTherapyEligible;

    scope.setState({
        userSelectedProstateCoverage: payload.prostateCoverage,
        userSelectedMarksScore: payload.marksScore,
        userSelectedLesionVolume: payload.marginVolume,
    });
}

function packPlanWithPayload({
    currentTreatmentPlan,
    payload,
    setUserSelectedProstateCoverage,
    setUserSelectedMarksScore,
    setUserSelectedLesionVolume,
}) {
    currentTreatmentPlan.TreatmentPlan.StructureData.Margins[0].VolumeCC =
        payload.marginVolume;
    currentTreatmentPlan.TreatmentPlan.StructureData.CoreStructure.VolumeCC =
        payload.prostateVolume;
    currentTreatmentPlan.SavedData.UserSelectedCPMThreshold =
        payload.optimalCPMThreshold;
    currentTreatmentPlan.SavedData.UserSelectedMarksScore = payload.marksScore;
    currentTreatmentPlan.SavedData.UserSelectedLesionCoverageOfProstate =
        payload.prostateCoverage;
    currentTreatmentPlan.SavedData.IsPatientFTEligible =
        payload.focalTherapyEligible;

    setUserSelectedProstateCoverage(payload.prostateCoverage);
    setUserSelectedMarksScore(payload.marksScore);
    setUserSelectedLesionVolume(payload.marginVolume);

    return currentTreatmentPlan;
}

function legacy_updatePlan(scope, plan) {
    updateTreatmentPlan({
        useruuid: scope.props.useruuid,
        patientuuid: scope.props.patientuuid,
        planuuid: scope.props.planuuid,
        token: scope.props.authToken,
        plan: plan,
    })
        .then((payload) => payload.json())
        .then((json) => {
            scope.props.setCurrentTreatmentPlan(json.payload.plan);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
            );
        });
}

function updatePlan(
    plan,
    authToken,
    useruuid,
    patientuuid,
    planuuid,
    setCurrentTreatmentPlan
) {
    updateTreatmentPlan({
        useruuid: useruuid,
        patientuuid: patientuuid,
        planuuid: planuuid,
        token: authToken,
        plan: plan,
    })
        .then((payload) => payload.json())
        .then((json) => {
            setCurrentTreatmentPlan(json.payload.plan);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
            );
        });
}
