import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import backArrow from "../../../../assets/back_arrow.svg";
import AHButton from "../../../__shared__/StyleGuide/AHButton";

class AblationPlacementModeSelectionPanel extends Component {
    constructor(props) {
        super(props);

        this.handleAutoClick = this.handleAutoClick.bind(this);
        this.handleManualClick = this.handleManualClick.bind(this);
        this.handlePanelClick = this.handlePanelClick.bind(this);
    }

    handleAutoClick(e) {
        let scope = this;

        e.stopPropagation();

        scope.props.onAutomaticPlacementClick();
    }

    handleManualClick(e) {
        e.stopPropagation();

        this.props.onManualPlacementClick();
    }

    handlePanelClick(e) {
        e.stopPropagation();
    }

    render() {
        return (
            <div
                className="h-full w-full bg-[#393939]"
                style={this.props.style}
                onClick={this.handlePanelClick}
            >
                <div className="flex h-28 w-full items-center gap-8 px-4 text-white">
                    <div className="w-5" onClick={this.props.onClose}>
                        <img src={backArrow} alt="Back arrow" />
                    </div>
                    <p className="w-3/4 text-center text-lg">
                        Choose Method for Tool Placement:
                    </p>
                </div>
                <div className="placement-button-group">
                    <AHButton
                        id="auto-place-btn"
                        className="tgt-shared-btn auto-tgt-btn"
                        onClick={this.handleAutoClick}
                        isDynamic={true}
                        size="large"
                        text="Automatic Placement"
                    />
                    <AHButton
                        id="manual-place-btn"
                        className="tgt-shared-btn man-tgt-btn"
                        onClick={this.handleManualClick}
                        isSecondary={true}
                        isOutline={true}
                        isDynamic={true}
                        size="large"
                        text="Manual Placement"
                    />
                </div>
            </div>
        );
    }
}

AblationPlacementModeSelectionPanel.propTypes = {
    onClose: PropTypes.func,
    onAutomaticPlacementClick: PropTypes.func,
    onManualPlacementClick: PropTypes.func,
    style: PropTypes.object,
};

AblationPlacementModeSelectionPanel.defaultProps = {
    onClose: () => {},
    onAutomaticPlacementClick: () => {},
    onManualPlacementClick: () => {},
    style: {},
};

export default AblationPlacementModeSelectionPanel;
