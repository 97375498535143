import React, { Component } from "react";
import { connect } from "react-redux";
import SpinLoader from "../../../__shared__/SpinLoader";
import "./styles.css";
import backArrow from "../../../../assets/back_arrow.svg";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import chkBoxOn from "../../../../assets/CheckedSquare.svg";
import chkBoxOff from "../../../../assets/UncheckedSquare.svg";
import greenChk from "../../../../assets/circle_green_check.svg";
import redXCircle from "../../../../assets/red_x.svg";
import * as TreatmentPlan from "../../../../helpers/tplan/tplan";
import {
    getAblationVolumeIsValidForAutoPlacementWrapper,
    getAutoPlacedTargetsWrapper,
    getIconForAblationVolumePrimitiveType,
} from "../../helpers";
import { EMPTY_STRING, specialSelection, SUCCESS } from "../../../../constants";
import { setCurrentTreatmentPlan } from "../../../CreateTPlan_1Upload/actions";
import { getTreatmentPlan } from "../../../CreateTPlan_1Upload/helpers";

class AASPOptionsPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cropToMidline: false,
            selectedAblationVolumeIndex: specialSelection.NONE_SELECTED,
            ablationVolumeCompatibilityArray: [],
            aaspState: aaspStateEnum.CHECKING_ABLATION_VOLUME,
        };

        this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
        this.handleAASPClick = this.handleAASPClick.bind(this);
        this.getAASPConfirmButton = this.getAASPConfirmButton.bind(this);
    }

    async componentDidMount() {
        // for each ablation volume, determine compatibility and update state
        let ablationProfile = this.props.ablationProfile;

        if (!ablationProfile) {
            return;
        }

        let cloudIDs = {
            userUUID: this.props.useruuid,
            patientUUID: this.props.patientuuid,
            visitUUID: this.props.visituuid,
            planUUID: this.props.planuuid,
            scanID: this.props.currentTreatmentPlan.MetaData.MasterScanID,
            token: this.props.authToken,
        };

        let ablationVolumeCompatibilityArray = [];
        for (let ablationVolume of ablationProfile.volumes) {
            let ablationVolumeIsValidResult =
                await getAblationVolumeIsValidForAutoPlacementWrapper(
                    this.props.currentTreatmentPlan.TreatmentPlan,
                    ablationVolume,
                    cloudIDs
                );

            if (
                ablationVolumeIsValidResult &&
                ablationVolumeIsValidResult.payload
            ) {
                ablationVolumeCompatibilityArray.push({
                    ablationVolume: ablationVolume,
                    isValid: ablationVolumeIsValidResult.payload.isValid,
                });
            } else {
                ablationVolumeCompatibilityArray.push({
                    ablationVolume: ablationVolume,
                    isValid: false,
                });
            }
        }

        this.setState({
            aaspState: aaspStateEnum.NO_ABLATION_VOLUME_SELECTED,
            ablationVolumeCompatibilityArray: ablationVolumeCompatibilityArray,
        });
    }

    handleCheckBoxClick() {
        this.setState({ cropToMidline: !this.state.cropToMidline });
    }

    handleAASPClick() {
        let scope = this;

        this.setState({
            aaspState: aaspStateEnum.AASP_IN_PROGRESS,
        });

        let cloudIDs = {
            userUUID: this.props.useruuid,
            patientUUID: this.props.patientuuid,
            visitUUID: this.props.visituuid,
            planUUID: this.props.planuuid,
            scanID: this.props.currentTreatmentPlan.MetaData.MasterScanID,
            token: this.props.authToken,
        };

        getAutoPlacedTargetsWrapper(
            this.props.currentTreatmentPlan.TreatmentPlan,
            this.props.ablationProfile.volumes[
                this.state.selectedAblationVolumeIndex
            ],
            cloudIDs,
            this.state.cropToMidline
        )
            .then((result) => {
                if (!result || result.message !== SUCCESS) {
                    scope.setState({
                        aaspState: aaspStateEnum.AASP_FAILED,
                    });
                    return;
                }

                getTreatmentPlan(
                    cloudIDs.token,
                    cloudIDs.patientUUID,
                    cloudIDs.planUUID
                )
                    .then((payload) => payload.json())
                    .then((result) => {
                        if (!result || result.payload.status !== SUCCESS) {
                            scope.setState({
                                aaspState: aaspStateEnum.AASP_FAILED,
                            });
                            return;
                        }

                        let input = {
                            patientuuid: cloudIDs.patientUUID,
                            planuuid: cloudIDs.planUUID,
                            visituuid: cloudIDs.visitUUID,
                            token: cloudIDs.token,
                        };

                        scope.props.targetSetModel.setTargetsFromTreatmentPlanFormat(
                            TreatmentPlan.getTargets(
                                result.payload.plan.TreatmentPlan
                            ),
                            scope.props.targetSetController
                                .ablationProfileTemplate,
                            input
                        );

                        scope.props.setCurrentTreatmentPlan(
                            result.payload.plan
                        );
                        scope.props.onComplete();
                    })
                    .catch(() => {
                        scope.setState({
                            aaspState: aaspStateEnum.AASP_FAILED,
                        });
                    });
            })
            .catch(() => {
                scope.setState({
                    aaspState: aaspStateEnum.AASP_FAILED,
                });
            });
    }

    getAblationVolumeListing(ablationProfile) {
        let listItems = [];

        if (ablationProfile) {
            ablationProfile.volumes.forEach((ablationVolume, idx) => {
                let shade = idx % 2 === 0 ? "light" : "dark";

                if (this.state.selectedAblationVolumeIndex === idx) {
                    shade = "selected";
                }

                let volumeIcon = getIconForAblationVolumePrimitiveType(
                    ablationVolume.meshPrimitiveType
                );

                let filterStr;
                if (
                    this.state.aaspState ===
                    aaspStateEnum.CHECKING_ABLATION_VOLUME
                ) {
                    filterStr = "brightness(50%)";
                } else if (
                    _isAblationVolumeMarkedCompatible(
                        this.state.ablationVolumeCompatibilityArray,
                        idx
                    )
                ) {
                    filterStr = "brightness(100%)";
                } else {
                    filterStr = "brightness(50%)";
                }

                listItems.push(
                    <div
                        key={`${ablationProfile.name}-${ablationProfile.ablationProfileUUID}-${idx}`}
                        className={`volume-list-item ${shade}`}
                        style={{ filter: filterStr }}
                        onClick={() => {
                            let newAaspState;
                            if (
                                _isAblationVolumeMarkedCompatible(
                                    this.state.ablationVolumeCompatibilityArray,
                                    idx
                                )
                            ) {
                                newAaspState =
                                    aaspStateEnum.ABLATION_VOLUME_VALID;
                            } else {
                                newAaspState =
                                    aaspStateEnum.ABLATION_VOLUME_INVALID;
                            }

                            this.setState({
                                aaspState: newAaspState,
                                selectedAblationVolumeIndex: idx,
                            });
                        }}
                    >
                        <img
                            src={volumeIcon}
                            className={"volume-list-icon"}
                            alt={"Volume img"}
                        />
                        <p className={"volume-list-item-text display-14"}>
                            {ablationVolume.name}
                        </p>
                    </div>
                );
            });
        }

        return listItems;
    }

    getAblationVolumeCompatibility() {
        if (this.state.aaspState !== aaspStateEnum.CHECKING_ABLATION_VOLUME) {
            if (this.state.selectedAblationVolumeIndex < 0) {
                return (
                    <div className={"aasp-ablation-volume-compatibility-msg"}>
                        <div className={"aasp-compatibility-icon"} />
                        <p className={"display-14"} />
                    </div>
                );
            } else if (
                _isAblationVolumeMarkedCompatible(
                    this.state.ablationVolumeCompatibilityArray,
                    this.state.selectedAblationVolumeIndex
                )
            ) {
                return (
                    <div className={"aasp-ablation-volume-compatibility-msg"}>
                        <img
                            className={"aasp-compatibility-icon"}
                            src={greenChk}
                            alt={"OK"}
                        />
                        <p className={"display-14"}>
                            Tool volume is compatible with automatic placement
                        </p>
                    </div>
                );
            } else {
                return (
                    <div className={"aasp-ablation-volume-compatibility-msg"}>
                        <img
                            className={"aasp-compatibility-icon"}
                            src={redXCircle}
                            alt={"X"}
                        />
                        <p className={"display-14"}>
                            Tool volume is NOT compatible with automatic
                            placement
                        </p>
                    </div>
                );
            }
        } else {
            return (
                <div className={"aasp-ablation-volume-compatibility-msg"}>
                    {spinLoader}
                    <p className={"display-14"}>
                        Loading automatic placement compatibility...
                    </p>
                </div>
            );
        }
    }

    getCheckBox() {
        return this.state.cropToMidline ? chkBoxOn : chkBoxOff;
    }

    getAASPConfirmButton() {
        switch (this.state.aaspState) {
            case aaspStateEnum.NO_ABLATION_VOLUME_SELECTED: {
                return (
                    <Button
                        id="select-atp-btn-disabled"
                        className={"confirm-aasp-btn"}
                        variant={"secondary btn-block"}
                        disabled
                    >
                        Please select a tool volume to proceed
                    </Button>
                );
            }
            case aaspStateEnum.CHECKING_ABLATION_VOLUME: {
                return (
                    <Button
                        id="check-atp-btn-disabled"
                        className={"confirm-aasp-btn"}
                        variant={"secondary btn-block"}
                        disabled
                    >
                        <div className={"confirm-aasp-btn-spinner-container"}>
                            {spinLoader}
                            Loading...
                        </div>
                    </Button>
                );
            }
            case aaspStateEnum.AASP_IN_PROGRESS: {
                return (
                    <Button
                        id="in-progress-atp-btn-disabled"
                        className={"confirm-aasp-btn"}
                        variant={"primary btn-block"}
                        disabled
                    >
                        <div className={"confirm-aasp-btn-spinner-container"}>
                            {spinLoader}
                            Loading...
                        </div>
                    </Button>
                );
            }
            case aaspStateEnum.ABLATION_VOLUME_INVALID: {
                return (
                    <Button
                        id="invalid-atp-btn-disabled"
                        className={"confirm-aasp-btn"}
                        variant={"warning btn-block"}
                        disabled
                    >
                        Select a compatible tool volume or place manually
                    </Button>
                );
            }
            case aaspStateEnum.AASP_FAILED: {
                return (
                    <Button
                        id="failed-atp-btn-disabled"
                        className={"confirm-aasp-btn"}
                        variant={"danger btn-block"}
                        disabled
                    >
                        Automatic placement failed
                    </Button>
                );
            }
            case aaspStateEnum.ABLATION_VOLUME_VALID: {
                return (
                    <Button
                        id="confirm-atp-btn-active"
                        className={"confirm-aasp-btn"}
                        variant={"primary btn-block"}
                        onClick={this.handleAASPClick}
                    >
                        Click here to perform automatic placement
                    </Button>
                );
            }
            default:
                return null;
        }
    }

    render() {
        return (
            <div
                id={this.props.id}
                className={"aasp-options-panel h-full bg-[#393939]"}
            >
                <div className={"aasp-options-panel-header"}>
                    <div
                        className={"aasp-options-panel-back-btn"}
                        onClick={this.props.onClose}
                    >
                        <img src={backArrow} alt={"<-"} />
                    </div>
                    <p className={"display-20"}>
                        Configure Automatic Tool Placement
                    </p>
                </div>
                <div className={"aasp-options-panel-body"}>
                    <div className={"aasp-options-ablation-listing-header"}>
                        <p className={"display-14"}>
                            Select tool volume to use for automatic placement
                        </p>
                    </div>
                    <div className={"aasp-options-ablation-listing-container"}>
                        {this.getAblationVolumeListing(
                            this.props.ablationProfile
                        )}
                    </div>
                    <div
                        className={"aasp-options-ablation-volume-compatibility"}
                    >
                        {this.getAblationVolumeCompatibility()}
                    </div>
                    <div className={"aasp-options-midline-crop-container"}>
                        <img
                            id={"crop-midline-chkbox"}
                            src={this.getCheckBox()}
                            alt={""}
                            onClick={this.handleCheckBoxClick}
                        />
                        <p className={"display-14"}>
                            Prevent placement across midline
                        </p>
                    </div>
                </div>
                <div className={"aasp-options-panel-footer"}>
                    {this.getAASPConfirmButton()}
                </div>
            </div>
        );
    }
}

AASPOptionsPanel.propTypes = {
    id: PropTypes.string,
    onClose: PropTypes.func,
    ablationProfile: PropTypes.object,
    currentTreatmentPlan: PropTypes.object,
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
};

AASPOptionsPanel.defaultProps = {
    style: {},
    currentTreatmentPlan: {},
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
};

/* istanbul ignore once */
const mapStateToProps = function (state) {
    return {
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
    };
};

/* istanbul ignore once */
const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AASPOptionsPanel);

function _isAblationVolumeMarkedCompatible(
    ablationVolumeCompatibilityArray,
    selectedAblationVolumeIndex
) {
    if (
        ablationVolumeCompatibilityArray &&
        ablationVolumeCompatibilityArray.length > selectedAblationVolumeIndex
    ) {
        return ablationVolumeCompatibilityArray[
            parseInt(selectedAblationVolumeIndex)
        ].isValid;
    } else {
        return false;
    }
}

const aaspStateEnum = {
    NO_ABLATION_VOLUME_SELECTED: "NO_ABLATION_VOLUME_SELECTED",
    CHECKING_ABLATION_VOLUME: "CHECKING_ABLATION_VOLUME",
    ABLATION_VOLUME_VALID: "ABLATION_VOLUME_VALID",
    ABLATION_VOLUME_INVALID: "ABLATION_VOLUME_INVALID",
    AASP_IN_PROGRESS: "AASP_IN_PROGRESS",
    AASP_FAILED: "AASP_FAILED",
};

const spinLoader = (
    <SpinLoader
        id={"auto-tgt-btn-load-spinner"}
        fgColor={"#ffffff"}
        loaderWidth={"4px"}
        width={"24px"}
        height={"24px"}
    />
);
