export const sortCategories = {
    NONE: -1,
    NAME: 0,
    ID: 1,
    DATE: 2,
    SEARCH_SCORE: 3,
    PLAN_STATUS: 4,
};

export const EMPTY_STRING = "";
export const N_A = "N.A.";

export const LIST_ITEM_HEIGHT = 74;
export const CONTAINER_HEIGHT = 435;

export const patientListHeader = {
    ID: "ID",
    NAME: "Name",
    DATE: "Creation Date",
    PLAN_STATUS: "Plan Status",
};

export const buttonNames = {
    RESUME: "Resume Plan",
    REVIEW: "Review Plan",
};

export const SEARCH_BAR_PLACEHOLDER = "Search for patients...";
export const MAX_SEARCH_RESULTS = 50;

export const lockedErrorMessage =
    "The plan is currently being worked on by another user. Please try again later.";

export const lockedPlanTooltipMsg = (firstName, lastName, email) =>
    `Plan is currently being worked on by ${firstName} ${lastName} (${email}). Please check back later.`;
