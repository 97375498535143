import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import ErrorBanner from "../ErrorBanner";

export default class TopBar extends Component {
    render() {
        return (
            <div
                id={this.props.id}
                className="top-bar-component px-[3%]"
                style={this.props.style}
            >
                {this.props.children}
                <ErrorBanner />
            </div>
        );
    }
}

TopBar.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
};
