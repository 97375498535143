import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import { ReactComponent as CheckBoxOffIcon } from "../../../assets/check_box_off.svg";
import { ReactComponent as CheckBoxOnIcon } from "../../../assets/check_box_on.svg";
import { CHECK_BOX_ALT_TEXT, CHECKED, LOADING, UNCHECKED } from "./constants";

export default class CheckBox extends Component {
    render() {
        if (
            this.props.status !== CHECKED &&
            this.props.status !== UNCHECKED &&
            this.props.status !== LOADING
        ) {
            return null;
        }

        const checkBoxClass =
            this.props.status === CHECKED ? "check-box-on" : "check-box-off";

        let style = {};
        if (this.props.checkBoxColor) {
            style =
                this.props.status === CHECKED
                    ? { fill: this.props.checkBoxColor }
                    : { stroke: this.props.checkBoxColor };
        }

        return (
            <div id={this.props.id} onClick={this.props.onClick}>
                {(this.props.status === UNCHECKED ||
                    this.props.status === LOADING) && (
                    <CheckBoxOffIcon
                        className={`${checkBoxClass} click-outside-ignore ${this.props.className}`}
                        style={style}
                        alt={this.props.alt}
                    />
                )}
                {this.props.status === CHECKED && (
                    <CheckBoxOnIcon
                        className={`${checkBoxClass} click-outside-ignore ${this.props.className}`}
                        style={style}
                        alt={this.props.alt}
                    />
                )}
            </div>
        );
    }
}

CheckBox.propTypes = {
    alt: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.number,
    className: PropTypes.string,
    checkBoxColor: PropTypes.string,
    onClick: PropTypes.func,
};

CheckBox.defaultProps = {
    alt: CHECK_BOX_ALT_TEXT,
    id: EMPTY_STRING,
    status: UNCHECKED,
    className: EMPTY_STRING,
    checkBoxColor: EMPTY_STRING,
    onClick: () => {},
};
