import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../../constants";
import { deleteTargets, saveTargets } from "../../helpers";
import { setCurrentTreatmentPlan } from "../../../CreateTPlan_1Upload/actions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AblationListItem from "../AblationListItem";
import MutedNudgeText from "../../../__shared__/MutedNudgeText";
import greenCheck from "../../../../assets/circle_green_check.svg";
import TargetSetModel from "../../../__shared__/Viewers/Structures/Targets/TargetSetModel";
import TargetSetController from "../../../__shared__/Viewers/Structures/Targets/TargetSetController";
import SpinLoader from "../../../__shared__/SpinLoader";
import { Switch, Case, Default } from "react-if";
import AHButton from "../../../__shared__/StyleGuide/AHButton";

const reorder = (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class AblationSiteListingPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hiddenTargets: [],
            dataSaved: false,
            saveInProgress: false,
            targetsList: [],
        };

        this.getTargetList = this.getTargetList.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.editTargetHandler = this.editTargetHandler.bind(this);
        this.addTargetHandler = this.addTargetHandler.bind(this);
        this.deleteTargetHandler = this.deleteTargetHandler.bind(this);
        this.changeTargetColorHandler =
            this.changeTargetColorHandler.bind(this);
        this.getTargetListPanel = this.getTargetListPanel.bind(this);
        this.resetHandler = this.resetHandler.bind(this);
        this.saveHandler = this.saveHandler.bind(this);
        this.hideTargetHandler = this.hideTargetHandler.bind(this);
    }

    handleClick(e) {
        e.stopPropagation();
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        this.props.targetSetModel.targets = new Map(
            reorder(
                [...this.props.targetSetModel.targets.entries()],
                result.source.index,
                result.destination.index
            )
        );
    }

    getTargetList() {
        let targets =
            this.props.targetSetModel.getTargetsInTreatmentPlanFormat();

        if (targets && targets.length > 0) {
            return targets.map((target, index) => (
                <div key={target.ID} className="target-list-item-container">
                    <Draggable
                        key={target.ID}
                        draggableId={target.ID}
                        index={index}
                    >
                        {(provided) => (
                            <div
                                onMouseEnter={() => {
                                    this.props.targetSetController.setTargetHighlightByID(
                                        target.ID,
                                        true
                                    );
                                }}
                                onMouseLeave={() => {
                                    this.props.targetSetController.setTargetHighlightByID(
                                        target.ID,
                                        false
                                    );
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={"draggable-list-item-container"}
                            >
                                <AblationListItem
                                    targetID={target.ID}
                                    targetIndex={index}
                                    editTargetHandler={this.editTargetHandler}
                                    color={target.Color}
                                    deleteTargetHandler={
                                        this.deleteTargetHandler
                                    }
                                    changeTargetColorHandler={
                                        this.changeTargetColorHandler
                                    }
                                    hideTargetHandler={this.hideTargetHandler}
                                    hideTarget={this.props.targetSetController.getTargetVisibilityByID(
                                        target.ID
                                    )}
                                />
                            </div>
                        )}
                    </Draggable>
                </div>
            ));
        }

        return (
            <div
                id="add-target-nudge-text-item"
                className={"target-list-item-container"}
            >
                <div className={"add-target-nudge-text"}>
                    <MutedNudgeText
                        content={
                            "Add tool placements manually using the button above"
                        }
                        id={"Step4-2"}
                    />
                </div>
            </div>
        );
    }

    editTargetHandler(targetID, targetIndex) {
        this.setState({
            dataSaved: false,
        });

        this.props.onViewDetailsClick({
            adjustmentTargetID: targetID,
            adjustmentTargetIndex: targetIndex,
        });
    }

    deleteTargetHandler(targetID) {
        this.props.targetSetController.deleteTargetByID(targetID);
        this.setState({
            dataSaved: false,
        });
        this.forceUpdate();
    }

    addTargetHandler() {
        this.setState({
            dataSaved: false,
            targetsList:
                this.props.targetSetModel.getTargetsInTreatmentPlanFormat(),
        });

        this.props.onAddAblationSiteClick();
    }

    changeTargetColorHandler(targetID, color) {
        this.props.targetSetController.setTargetColorByID(targetID, color);
        this.setState({
            dataSaved: false,
        });
    }

    hideTargetHandler(targetID) {
        let isVisible =
            this.props.targetSetController.getTargetVisibilityByID(targetID);
        this.props.targetSetController.setTargetVisibilityByID(
            targetID,
            !isVisible
        );
        this.forceUpdate();
    }

    saveHandler() {
        let scope = this;

        this.setState({
            saveInProgress: true,
        });

        let inMemoryTargets;

        inMemoryTargets =
            this.props.targetSetModel.getTargetsInTreatmentPlanFormat();

        saveTargets(this, inMemoryTargets)
            .then(() => {
                scope.setState({
                    dataSaved: true,
                    saveInProgress: false,
                });
                this.props.setStep5OptionalTaskSatisfied();
            })
            .catch(() => {
                scope.setState({
                    dataSaved: false,
                    saveInProgress: false,
                });
            });
    }

    resetHandler() {
        let targetFmt;
        targetFmt = this.props.targetSetModel.getTargetsInTreatmentPlanFormat();
        this.setState({
            dataSaved: false,
            targetsList: targetFmt,
        });
        this.props.onClose();
        deleteTargets(this, targetFmt);
        this.props.targetSetController.deleteAllTargets();
    }

    getSaveText() {
        return (
            <Switch>
                <Case condition={this.state.saveInProgress}>Saving...</Case>
                <Case
                    condition={
                        !this.state.saveInProgress && this.state.dataSaved
                    }
                >
                    Saved
                </Case>
                <Default>Save</Default>
            </Switch>
        );
    }

    getSaveIcon() {
        return (
            <Switch>
                <Case condition={this.state.saveInProgress}>
                    <div className={"save-tgt-btn-content"}>
                        <SpinLoader
                            id={"tgt-panel-save-spinner"}
                            width={"20px"}
                            height={"20px"}
                            loaderWidth={"3px"}
                            animationDuration={"1s"}
                        />
                    </div>
                </Case>
                <Case
                    condition={
                        !this.state.saveInProgress && this.state.dataSaved
                    }
                >
                    <div className={"save-tgt-btn-content"}>
                        <img src={greenCheck} alt={"X"} />
                    </div>
                </Case>
            </Switch>
        );
    }

    getTargetListPanel() {
        return (
            <div
                className="target-workflow active w-full"
                style={this.props.style}
                onClick={this.handleClick}
            >
                <div
                    id="tool-placement-header"
                    className="display-20 flex h-16 items-center"
                >
                    Tool Placement Sites
                </div>
                <div className="add-tgt-btn-contents">
                    <AHButton
                        id="add-tool-site-btn"
                        className="add-tgt-btn"
                        onClick={() => {
                            this.addTargetHandler();
                        }}
                        text="+ Add Tool Site"
                        size="large"
                    />
                </div>
                <div className={"tgt-panel-list-cntr"}>
                    <div className={"target-item-list"}>
                        <DragDropContext
                            onDragEnd={this.onDragEnd}
                            className={"dd-context"}
                        >
                            <Droppable droppableId={"droppable"}>
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className={"droppable"}
                                    >
                                        {this.getTargetList()}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                <div className="mt-3 flex w-full items-center justify-between px-4">
                    <AHButton
                        id="reset-tool-site-btn"
                        className="reset-tgt-btn"
                        onClick={this.resetHandler}
                        isDynamic={true}
                        isSecondary={true}
                        isOutline={true}
                        size="medium"
                        text="Reset"
                    />
                    <AHButton
                        id="save-tool-site-btn"
                        className="save-tgt-btn mt-0"
                        onClick={this.saveHandler}
                        text={this.getSaveText()}
                        size="medium"
                        isDynamic={true}
                    >
                        {this.getSaveIcon()}
                    </AHButton>
                </div>
            </div>
        );
    }

    render() {
        return this.getTargetListPanel();
    }
}

AblationSiteListingPanel.propTypes = {
    targetSetModel: PropTypes.instanceOf(TargetSetModel),
    targetSetController: PropTypes.instanceOf(TargetSetController),
    id: PropTypes.string,
    style: PropTypes.object,
    useruuid: PropTypes.string,
    planuuid: PropTypes.string,
    patientuuid: PropTypes.string,
    visituuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    display: PropTypes.string,
    authToken: PropTypes.string,
    setCurrentTreatmentPlan: PropTypes.func,
    onViewDetailsClick: PropTypes.func,
    onAddAblationSiteClick: PropTypes.func,
    onClose: PropTypes.func,
    setStep5OptionalTaskSatisfied: PropTypes.func,
};

AblationSiteListingPanel.defaultProps = {
    useruuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    currentTreatmentPlan: {},
};

/* istanbul ignore once */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        planuuid: state.CreatePlanReducer.planuuid,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore once */
const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AblationSiteListingPanel);
