import React, { Component } from "react";
import TopBar from "../__shared__/TopBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import "./styles.css";
import { connect } from "react-redux";
import { EMPTY_STRING, stepPath } from "../../constants";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { handleLogout } from "../Login/actions";
import ProfileMenu from "../__shared__/ProfileMenu";
import plusSign from "../../assets/white_plus_sign.svg";
import {
    CREATE_PROFILE_STEPS,
    DEFAULT_DIM_VALUES,
    MAX_TOOL_VOLUME_DIMENSION,
    MODALITY_MENU_ITEMS,
} from "./constants";
import VolumeDimensionMenu from "./VolumeDimensionMenu";
import AblationVolumesListing from "./AblationVolumesListing";
import { VOLUME_SHAPES } from "../CreateTPlan_4EditMargin/constants";
import trashCanWhite from "../../assets/white_trash_can.svg";
import trashCan from "../../assets/trash_can.svg";
import {
    createAblationVolume,
    createNewAblationProfile,
    deleteAblationProfileVolume,
    getAblationProfileWithVolumes,
    updateAblationProfile,
    updateAblationProfileVolume,
} from "./helpers";
import { setAblationProfile, setCreateNewProfile } from "./actions";
import DecisionAlertCard from "../__shared__/DecisionAlertCard";
import { Form } from "react-bootstrap";
import PreviousStepButton from "../__shared__/PreviousStepButton";
import { DIMENSION_LABELS } from "./constants";
import MeshViewer from "../__shared__/Viewers/MeshViewer";
import MRIController from "../__shared__/Viewers/Structures/MRI/MRIController";
import MRIModel from "../__shared__/Viewers/Structures/MRI/MRIModel";
import CustomTargetController from "../__shared__/Viewers/Structures/CustomTargets/CustomTargetController";
import CustomTargetSetModel from "../__shared__/Viewers/Structures/CustomTargets/CustomTargetSetModel";
import { isEmpty } from "../../helpers/helpers";
import { getIconForAblationVolumePrimitiveType } from "../CreateTPlan_4EditMargin/helpers";
import AHDropdown from "../__shared__/StyleGuide/AHDropdown";
import AHDropdownItem from "../__shared__/StyleGuide/AHDropdownItem";
import { themes } from "../__shared__/EditButton/constants";
import EditButton from "../__shared__/EditButton";
import { PROFILE_MENU_ITEMS } from "../Profile/constants";

class CreateAblationProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileCreationStep: CREATE_PROFILE_STEPS.ABLATION_VOLUMES_LIST,
            profileName: EMPTY_STRING,
            startingVolume: null,
            xLen: DEFAULT_DIM_VALUES.X,
            yLen: DEFAULT_DIM_VALUES.Y,
            zLen: DEFAULT_DIM_VALUES.Z,
            zOffset: DEFAULT_DIM_VALUES.OFFSET,
            hideApplicator: false,
            volumeName: EMPTY_STRING,
            profileVolumes: [],
            modalitySelected: null,
            showProfileDialogue: false,
            editingVolume: false,
            editingVolumeID: EMPTY_STRING,
            selectedIdx: null,
        };

        this.meshRef = React.createRef();

        this.mriModel = new MRIModel();
        this.mriController = new MRIController(this.mriModel);
        this.customTargetModel = new CustomTargetSetModel();
        this.customTargetController = new CustomTargetController(
            this.customTargetModel
        );
        this.customTargetController.mouseControlsEnabled = false;

        this.setPlanCreationStep = this.setPlanCreationStep.bind(this);
        this.getCreationStep = this.getCreationStep.bind(this);
        this.setStartingVolume = this.setStartingVolume.bind(this);
        this.handleDimensionChange = this.handleDimensionChange.bind(this);
        this.allowVolumeSave = this.allowVolumeSave.bind(this);
        this.saveVolume = this.saveVolume.bind(this);
        this.getVolumeList = this.getVolumeList.bind(this);
        this.handleProfileNameChange = this.handleProfileNameChange.bind(this);
        this.saveAblationProfile = this.saveAblationProfile.bind(this);
        this.handleModalitySelect = this.handleModalitySelect.bind(this);
        this.getAllowProfileSave = this.getAllowProfileSave.bind(this);
        this.getProfileNameCard = this.getProfileNameCard.bind(this);
        this.editVolumeCallback = this.editVolumeCallback.bind(this);
        this.deleteVolumeCallback = this.deleteVolumeCallback.bind(this);
        this.updateProfileVolumeList = this.updateProfileVolumeList.bind(this);
        this.createNewVolume = this.createNewVolume.bind(this);
        this.viewVolumeCallback = this.viewVolumeCallback.bind(this);
        this.createNewProfile = this.createNewProfile.bind(this);
    }

    componentDidMount() {
        if (this.props.createNewProfile) {
            this.setState({
                showProfileDialogue: true,
            });
        } else {
            let input = {
                userUuid: this.props.useruuid,
                authToken: this.props.authToken,
                ablationProfileId: this.props.ablationProfileUUID,
            };

            getAblationProfileWithVolumes(input).then((profile) => {
                if (profile && !isEmpty(profile)) {
                    this.setState({
                        profileVolumes: profile.volumes,
                        profileName: profile.name,
                        modalitySelected: profile.modality,
                    });
                }
            });
        }
    }

    setPlanCreationStep(profileCreationStep) {
        this.setState({
            profileCreationStep: profileCreationStep,
        });
    }

    setStartingVolume(volumeName) {
        if (this.state.startingVolume === volumeName) {
            return;
        }

        let x, y, z;

        switch (volumeName) {
            case VOLUME_SHAPES.ELLIPSE:
                x = y = z = DEFAULT_DIM_VALUES.ELLIPSE;
                break;
            default:
                x = y = z = DEFAULT_DIM_VALUES.X;
                break;
        }

        this.setState({
            startingVolume: volumeName,
            xLen: x,
            yLen: y,
            zLen: z,
        });

        this.customTargetController.setPrimitive(volumeName);
        this.customTargetModel.updateVolumeDimensions(
            x,
            y,
            z,
            this.state.zOffset
        );
    }

    async saveVolume() {
        let ablationVolumeUUID = EMPTY_STRING;

        if (!this.state.editingVolume) {
            let input = {
                userUuid: this.props.useruuid,
                authToken: this.props.authToken,
                volumeName: this.state.volumeName,
                meshPrimitiveType: this.state.startingVolume,
                ablationProfileId: this.props.ablationProfileUUID,
                faces: this.customTargetModel.currentCustomTarget.faces,
                vertices: this.customTargetModel.currentCustomTarget.vertices,
            };

            await createAblationVolume(input).then((response) => {
                ablationVolumeUUID = response.ablationVolumeUUID;
            });
        }

        let input = {
            userUuid: this.props.useruuid,
            authToken: this.props.authToken,
            volumeName: this.state.volumeName,
            ablationProfileId: this.props.ablationProfileUUID,
            volumeUuid: !this.state.editingVolume
                ? ablationVolumeUUID
                : this.state.editingVolumeID,
            modality: this.state.modalitySelected,
            meshPrimitiveType:
                this.customTargetModel.currentCustomTarget.volumeShape,
            edgeLength: parseFloat(
                this.customTargetModel.currentCustomTarget.xDim
            ),
            scale: {
                X: parseFloat(this.customTargetModel.currentCustomTarget.xDim),
                Y: parseFloat(this.customTargetModel.currentCustomTarget.yDim),
                Z: parseFloat(this.customTargetModel.currentCustomTarget.zDim),
            },
            radius: parseFloat(this.customTargetModel.currentCustomTarget.xDim),
            displayApplicator: this.customTargetModel.laserCatheterVisibility,
            applicatorOffset: parseFloat(
                this.customTargetModel.currentCustomTarget.laserCatheterOffset
            ),
            faces: this.customTargetModel.currentCustomTarget.faces,
            vertices: this.customTargetModel.currentCustomTarget.vertices,
        };

        updateAblationProfileVolume(input).then(() => {
            this.setState({
                startingVolume: null,
                xLen: DEFAULT_DIM_VALUES.X,
                yLen: DEFAULT_DIM_VALUES.Y,
                zLen: DEFAULT_DIM_VALUES.Z,
                zOffset: DEFAULT_DIM_VALUES.OFFSET,
                hideApplicator: false,
                volumeName: EMPTY_STRING,
                profileCreationStep: CREATE_PROFILE_STEPS.ABLATION_VOLUMES_LIST,
                editingVolume: false,
                editingVolumeID: EMPTY_STRING,
            });

            this.updateProfileVolumeList();
        });
    }

    allowVolumeSave() {
        return this.state.volumeName.length > 0;
    }

    handleDimensionChange(axis, event) {
        let value = parseFloat(event.target.value);

        value =
            value > MAX_TOOL_VOLUME_DIMENSION
                ? MAX_TOOL_VOLUME_DIMENSION
                : value;

        switch (axis) {
            case DIMENSION_LABELS.X:
                this.setState({
                    xLen: event.target.value,
                });
                this.customTargetModel.updateVolumeDimensions(
                    value,
                    this.state.yLen,
                    this.state.zLen,
                    this.state.zOffset
                );
                break;
            case DIMENSION_LABELS.Y:
                this.setState({
                    yLen: event.target.value,
                });
                this.customTargetModel.updateVolumeDimensions(
                    this.state.xLen,
                    value,
                    this.state.zLen,
                    this.state.zOffset
                );
                break;
            case DIMENSION_LABELS.Z:
                this.setState({
                    zLen: event.target.value,
                });
                this.customTargetModel.updateVolumeDimensions(
                    this.state.xLen,
                    this.state.yLen,
                    value,
                    this.state.zOffset
                );
                break;
            case DIMENSION_LABELS.OFFSET:
                this.setState({
                    zOffset: event.target.value,
                });
                this.customTargetModel.updateVolumeDimensions(
                    this.state.xLen,
                    this.state.yLen,
                    this.state.zLen,
                    value
                );
                break;
            case DIMENSION_LABELS.APPLICATOR:
                this.setState({
                    hideApplicator: event.target.value,
                });
                this.customTargetController.showApplicator =
                    !this.customTargetController.showApplicator;
                break;
            case DIMENSION_LABELS.NAME:
                this.setState({
                    volumeName: event.target.value,
                });
                break;
            default:
                break;
        }
    }

    getVolumeList(volumeList) {
        let volumeListing = [];
        let idx = 0;

        if (volumeList) {
            volumeList.forEach((volume) => {
                let shade = idx % 2 === 0 ? "light" : "dark";

                if (volume.ablationVolumeUUID === this.state.editingVolumeID) {
                    shade = "selected";
                }

                let volumeIcon = getIconForAblationVolumePrimitiveType(
                    volume.meshPrimitiveType
                );

                volumeListing.push(
                    <div
                        className={`ablation-volume-list-item ${shade}`}
                        onClick={() => {
                            this.viewVolumeCallback(volume);
                        }}
                    >
                        <img
                            src={volumeIcon}
                            className={"vol-btn-img"}
                            alt={"Volume img"}
                        />
                        <p
                            className={
                                "volume-list-item-text display-14 flex-grow"
                            }
                        >
                            {volume.name}
                        </p>
                        <div className={"vol-item-btn-cntr"}>
                            <EditButton
                                alt={"default checkbox"}
                                className={"vol-btn-img clickable"}
                                theme={
                                    shade === "selected"
                                        ? themes.DARK
                                        : themes.LIGHT
                                }
                                onClick={(e) => {
                                    this.editVolumeCallback(volume, e);
                                }}
                            />
                            <img
                                src={
                                    shade === "selected"
                                        ? trashCanWhite
                                        : trashCan
                                }
                                className={"vol-btn-img clickable"}
                                onClick={(e) => {
                                    this.deleteVolumeCallback(
                                        volume.ablationVolumeUUID,
                                        e
                                    );
                                }}
                                alt={"default checkbox"}
                            />
                        </div>
                    </div>
                );

                idx += 1;
            });
        }

        volumeListing.push(
            <div
                className={`ablation-volume-list-item clickable ${
                    idx % 2 === 0 ? "light" : "dark"
                }`}
                onClick={this.createNewVolume}
            >
                <img
                    id={"new-ablation-volume-btn"}
                    className={"vol-btn-img"}
                    src={plusSign}
                    alt={"create volume img"}
                />
                <div className={"create-new-volume-txt display-22"}>
                    Create New Tool Volume
                </div>
            </div>
        );

        return volumeListing;
    }

    handleModalitySelect(e) {
        this.setState({ modalitySelected: e });
    }

    handleProfileNameChange(event) {
        this.setState({
            profileName: event.target.value,
        });
    }

    saveAblationProfile() {
        let input = {
            userUuid: this.props.useruuid,
            authToken: this.props.authToken,
            profileName: this.state.profileName,
            ablationProfileId: this.props.ablationProfileUUID,
            modality: this.state.modalitySelected,
        };

        updateAblationProfile(input).then((response) => {
            this.props.setAblationProfile(
                response.ablationProfile.ablationProfileUUID
            );
            this.setState({
                profileVolumes: response.ablationProfile.volumes,
                profileName: response.ablationProfile.name,
                modalitySelected: response.ablationProfile.modality,
            });

            this.props.history.push(stepPath.PROFILE);
        });
    }

    createNewProfile() {
        let input = {
            userUuid: this.props.useruuid,
            authToken: this.props.authToken,
            profileName: this.state.profileName,
            modality: this.state.modalitySelected,
        };

        createNewAblationProfile(input).then((response) => {
            this.props.setAblationProfile(response.ablationProfileUUID);
        });
    }

    getAllowProfileSave() {
        return !!(
            this.state.modalitySelected &&
            this.state.profileName.length > 1 &&
            !isEmpty(this.state.profileVolumes)
        );
    }

    createNewVolume() {
        if (this.props.createNewProfile) {
            this.createNewProfile();
            this.props.setCreateNewProfile(false);
        }
        this.setState({
            startingVolume: null,
            xLen: DEFAULT_DIM_VALUES.X,
            yLen: DEFAULT_DIM_VALUES.Y,
            zLen: DEFAULT_DIM_VALUES.Z,
            zOffset: DEFAULT_DIM_VALUES.OFFSET,
            hideApplicator: false,
            volumeName: EMPTY_STRING,
            profileCreationStep: CREATE_PROFILE_STEPS.CREATE_ABLATION_VOLUME,
            editingVolume: false,
            editingVolumeID: EMPTY_STRING,
        });
    }

    viewVolumeCallback(volume) {
        if (this.state.editingVolumeID !== volume.ablationVolumeUUID) {
            this.setState({ editingVolumeID: volume.ablationVolumeUUID });
            this.customTargetController.setPrimitive(volume.meshPrimitiveType);
            this.customTargetModel.laserCatheterVisibility =
                volume.displayApplicator;
            this.customTargetModel.updateVolumeDimensions(
                volume.scale.X,
                volume.scale.Y,
                volume.scale.Z,
                volume.applicatorOffset
            );
        } else {
            this.setState({ editingVolumeID: EMPTY_STRING });
            this.customTargetModel.currentCustomTarget.delete();
        }
    }

    editVolumeCallback(volume, e) {
        e.stopPropagation();

        let st = {
            startingVolume: volume.meshPrimitiveType,
            xLen: volume.scale.X,
            yLen: volume.scale.Y,
            zLen: volume.scale.Z,
            zOffset: volume.applicatorOffset,
            hideApplicator: !volume.displayApplicator,
            volumeName: volume.name,
            profileCreationStep: CREATE_PROFILE_STEPS.CREATE_ABLATION_VOLUME,
            editingVolume: true,
            editingVolumeID: volume.ablationVolumeUUID,
        };

        switch (volume.meshPrimitiveType) {
            case VOLUME_SHAPES.SPHERE:
                st.xLen = volume.radius;
                break;
            default:
                break;
        }

        this.setState(st);

        this.customTargetController.setPrimitive(volume.meshPrimitiveType);
        this.customTargetModel.currentCustomTarget.laserCatheterVisibility =
            volume.displayApplicator;
        this.customTargetModel.updateVolumeDimensions(
            volume.scale.X,
            volume.scale.Y,
            volume.scale.Z,
            volume.applicatorOffset
        );
    }

    deleteVolumeCallback(uuid, e) {
        e.stopPropagation();

        let input = {
            userUuid: this.props.useruuid,
            authToken: this.props.authToken,
            profileName: this.state.profileName,
            ablationProfileId: this.props.ablationProfileUUID,
            volumeUuid: uuid,
            modality: this.state.modalitySelected,
        };

        deleteAblationProfileVolume(input).then(() => {
            this.updateProfileVolumeList();
        });
    }

    updateProfileVolumeList() {
        let input = {
            userUuid: this.props.useruuid,
            authToken: this.props.authToken,
            ablationProfileId: this.props.ablationProfileUUID,
        };

        getAblationProfileWithVolumes(input).then((profile) => {
            this.setState({
                profileVolumes: profile.volumes,
                profileName: profile.name,
                modalitySelected: profile.modality,
                editingVolumeID: EMPTY_STRING,
            });

            if (this.customTargetModel.currentCustomTarget) {
                this.customTargetModel.currentCustomTarget.delete();
            }
        });
    }

    getCreationStep(profileCreationStep) {
        switch (profileCreationStep) {
            case CREATE_PROFILE_STEPS.ABLATION_VOLUMES_LIST:
                return (
                    <AblationVolumesListing
                        volumeList={this.getVolumeList(
                            this.state.profileVolumes
                        )}
                        selectedModality={this.state.modalitySelected}
                        handleModalitySelect={this.handleModalitySelect}
                        allowSave={this.getAllowProfileSave}
                        handleProfileNameChange={this.handleProfileNameChange}
                        saveAblationProfile={this.saveAblationProfile}
                        profileName={this.state.profileName}
                    />
                );

            case CREATE_PROFILE_STEPS.CREATE_ABLATION_VOLUME:
                return (
                    <VolumeDimensionMenu
                        xVal={this.state.xLen}
                        yVal={this.state.yLen}
                        zVal={this.state.zLen}
                        handleDimensionChange={this.handleDimensionChange}
                        zOffset={this.state.zOffset}
                        volumeName={this.state.volumeName}
                        allowSave={this.allowVolumeSave()}
                        hideApplicator={this.state.hideApplicator}
                        startingVolume={this.state.startingVolume}
                        setStartingVolume={this.setStartingVolume}
                        saveVolume={this.saveVolume}
                    />
                );
            default:
                return null;
        }
    }

    getProfileNameCard(display) {
        if (display) {
            return (
                <DecisionAlertCard
                    id={"profile-name-alert"}
                    noBtnCallback={() => {
                        this.setState({ showProfileDialogue: false });
                        this.props.setCreateNewProfile(false);
                        this.props.history.push({
                            pathname: stepPath.PROFILE,
                            state: {
                                selectedMenuItem:
                                    PROFILE_MENU_ITEMS.ABLATION_PROFILES,
                            },
                        });
                    }}
                    noBtnTxt={"Go Back"}
                    yesBtnTxt={"Confirm"}
                    yesBtnCallback={() => {
                        this.setState({ showProfileDialogue: false });
                    }}
                    disableConfirm={
                        !(
                            this.state.modalitySelected &&
                            this.state.profileName.length > 1
                        )
                    }
                >
                    <div className={"summary-alert-card-header"}>
                        <p className={"display-26"}>
                            Enter details for new tool profile
                        </p>
                    </div>
                    <div className={"summary-alert-card-body"}>
                        <div
                            className={
                                "profile-details-item profile-card-element-spacing"
                            }
                        >
                            <div className={"profile-details-item-label"}>
                                Tool Modality:
                            </div>
                            <div className={"modality-dropdown-cntr"}>
                                <AHDropdown
                                    title={
                                        this.state.modalitySelected
                                            ? this.state.modalitySelected
                                            : "Select Tool Modality..."
                                    }
                                    id="modality-dropdown-menu"
                                    isSecondary={true}
                                    onSelect={this.handleModalitySelect}
                                >
                                    <AHDropdownItem
                                        eventKey={MODALITY_MENU_ITEMS.LASER}
                                        id="modality-laser"
                                    >
                                        {MODALITY_MENU_ITEMS.LASER}
                                    </AHDropdownItem>
                                    <AHDropdownItem
                                        eventKey={MODALITY_MENU_ITEMS.CRYO}
                                        id="modality-cryo"
                                    >
                                        {MODALITY_MENU_ITEMS.CRYO}
                                    </AHDropdownItem>
                                    <AHDropdownItem
                                        eventKey={MODALITY_MENU_ITEMS.HIFU}
                                        id="modality-hifu"
                                    >
                                        {MODALITY_MENU_ITEMS.HIFU}
                                    </AHDropdownItem>
                                    <AHDropdownItem
                                        eventKey={MODALITY_MENU_ITEMS.OTHER}
                                        id="modality-other"
                                    >
                                        {MODALITY_MENU_ITEMS.OTHER}
                                    </AHDropdownItem>
                                </AHDropdown>
                            </div>
                        </div>
                        <div
                            className={
                                "profile-details-item profile-card-element-spacing"
                            }
                        >
                            <div className={"profile-details-item-label"}>
                                Tool Profile Name:
                            </div>
                            <Form className={"ablation-volume-name-form"}>
                                <Form.Control
                                    type={"text"}
                                    className={
                                        "ablation-volume-name h-100 w-100 d-block"
                                    }
                                    size={"md"}
                                    value={this.state.profileName}
                                    onChange={(event) => {
                                        this.handleProfileNameChange(event);
                                    }}
                                    placeholder={"Enter Tool Profile Name"}
                                />
                            </Form>
                        </div>
                    </div>
                </DecisionAlertCard>
            );
        }

        return null;
    }

    render() {
        return (
            <div className={"ablation-profile-screen"}>
                <TopBar>
                    <AvendaLogo
                        id="create-plan-avenda-logo"
                        to={stepPath.HOME}
                    />
                    <div className={"create-plan-step-bar-container"}>
                        <PreviousStepButton
                            to={stepPath.PROFILE}
                            buttonText={"Return to Profile"}
                        />
                    </div>
                    <ProfileMenu id={"profile-info-profile-menu"} />
                </TopBar>
                <div className={"create-cutom-profile-worklow"}>
                    {this.getCreationStep(this.state.profileCreationStep)}
                    <div className={"custom-tool-profile-workflow-3d-cntr"}>
                        <MeshViewer
                            ref={this.meshRef}
                            staticViewer={true}
                            displaySettings={{
                                mriEnabled: false,
                                composerEnabled: false,
                            }}
                            customTargetModel={this.customTargetModel}
                            customTargetController={this.customTargetController}
                            mriModel={this.mriModel}
                            mriController={this.mriController}
                        />
                    </div>
                </div>
                {this.getProfileNameCard(this.state.showProfileDialogue)}
            </div>
        );
    }
}

CreateAblationProfile.propTypes = {
    authToken: PropTypes.string,
    history: PropTypes.object,
    handleLogout: PropTypes.func,
    onClick: PropTypes.func,
    userPicture: PropTypes.string,
    children: PropTypes.any,
    id: PropTypes.string,
    useruuid: PropTypes.string,
    ablationProfileUUID: PropTypes.string,
    setAblationProfile: PropTypes.func,
    setCreateNewProfile: PropTypes.func,
    createNewProfile: PropTypes.bool,
};

CreateAblationProfile.defaultProps = {
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
};

const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        flags: state.LoginReducer.flags,
        ablationProfileUUID: state.CreateAblationReducer.ablationProfileUUID,
        createNewProfile: state.CreateAblationReducer.createNewProfile,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        handleLogout: () => dispatch(handleLogout()),
        setAblationProfile: (uuid) => dispatch(setAblationProfile(uuid)),
        setCreateNewProfile: (isOn) => dispatch(setCreateNewProfile(isOn)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateAblationProfile)
);
