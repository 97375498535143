import React, { Component } from "react";
import "./styles.css";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

class AHButton extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
    }

    handleClick(e) {
        if (!this.props.isDisabled) {
            if (this.props.onClick) {
                this.props.onClick(e);
            }
        }
    }

    onMouseDown(e) {
        if (this.props.onMouseDown) {
            this.props.onMouseDown(e);
        } else {
            // Preserving default case to prevent unexpected breakage of buttons
            // But I am not sure if these need to be here
            e.preventDefault();
            e.stopPropagation();
        }
    }

    render() {
        let btnVariantBootstrap = this.props.isDisabled
            ? "disabled"
            : this.props.isSecondary
              ? "secondary"
              : "primary";

        let btnVariantStylesheet = this.props.isDisabled
            ? "disabled"
            : this.props.isSecondary
              ? "secondary"
              : "primary";

        btnVariantBootstrap = this.props.isOutline
            ? "outline-" + btnVariantBootstrap
            : btnVariantBootstrap;

        btnVariantStylesheet = this.props.isOutline
            ? "outline-" + btnVariantStylesheet
            : btnVariantStylesheet;

        let btnParamSuffix;
        btnParamSuffix = this.props.size
            ? `${btnParamSuffix} ${this.props.size}`
            : btnParamSuffix;
        btnParamSuffix = this.props.isDynamic
            ? `${btnParamSuffix} dynamic-size`
            : `${btnParamSuffix} fixed-size`;

        return (
            <Button
                id={this.props.id}
                className={`avenda-style-guide-button ${btnVariantBootstrap} ${this.props.className} ${btnParamSuffix}`}
                onClick={this.handleClick}
                onMouseDown={this.onMouseDown}
                bsPrefix={"avenda-custom-"}
                disabled={this.props.isDisabled}
                variant={`${btnVariantBootstrap} btn-block `}
                style={{
                    cursor: this.props.isDisabled ? "not-allowed" : "pointer",
                    ...this.props.style,
                }}
            >
                <p
                    className={`avenda-style-guide-button-text unselectable ${btnVariantStylesheet}`}
                >
                    {this.props.text}
                </p>
                {this.props.children}
            </Button>
        );
    }
}

AHButton.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
    isOutline: PropTypes.bool,
    isSecondary: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isDynamic: PropTypes.bool,
    size: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onMouseDown: PropTypes.func,
};

export default AHButton;
