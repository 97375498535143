import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import SpinLoader from "../SpinLoader";
import { avendaComponentColors } from "../../../constants";

class LoadingScreen extends React.Component {
    render() {
        return (
            <div id="loading-screen" style={this.props.style}>
                <SpinLoader
                    width="50px"
                    height="50px"
                    loaderWidth="4px"
                    fgColor={avendaComponentColors.BLUE}
                    animationDuration="1s"
                />
            </div>
        );
    }
}

LoadingScreen.propTypes = {
    style: PropTypes.object,
    id: PropTypes.string,
};

export default LoadingScreen;
