import TopBar from "../__shared__/TopBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import {
    setCurrentTreatmentPlan,
    setStepActive,
    setStepComplete,
} from "../CreateTPlan_1Upload/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RequirementsModal from "../__shared__/RequirementsModal";
import ProfileMenu from "../__shared__/ProfileMenu";
import {
    EMPTY_STRING,
    pageLoadStates,
    stepBarStepNames,
    stepPath,
    USER_MANUAL_URL,
} from "../../constants";
import StepMarkerBar from "../__shared__/StepMarkerBar";
import PreviousStepButton from "../__shared__/PreviousStepButton";
import NextStepButton from "../__shared__/NextStepButton";
import React, { useEffect, useRef, useState } from "react";
import { getTreatmentPlan } from "../CreateTPlan_1Upload/helpers";
import { getCemWarningMsg } from "../CreateTPlan_4EditMargin/helpers";
import {
    loadBxCores,
    loadMriStep4,
    loadProstate,
    loadRois,
    loadMargin,
    loadOtherStructures,
    resetViewer,
} from "../../helpers/load_helpers";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import ReviewCancerLesion from "./ReviewCancerLesion";
import MRIModel from "../__shared__/Viewers/Structures/MRI/MRIModel";
import TargetSetModel from "../__shared__/Viewers/Structures/Targets/TargetSetModel";
import TargetSetController from "../__shared__/Viewers/Structures/Targets/TargetSetController";
import PatientAnatomyController from "../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyController";
import PatientAnatomyModel from "../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyModel";
import AblationWorkflow from "../CreateTPlan_4EditMargin/AblationWorkflow";
import DecisionAlertCard from "../__shared__/DecisionAlertCard";
import {
    BIOPSY_STEP,
    EDIT_MARGIN_STEP,
    SUMMARY_STEP,
    TOOL_PLACEMENT_STEP,
} from "../CreateTPlan_1Upload/constants";
import { updateTreatmentPlan } from "../../helpers/backend_api";
import MRIViewer from "../__shared__/Viewers/MRIViewer";
import MRIController from "../__shared__/Viewers/Structures/MRI/MRIController";
import MeshViewer from "../__shared__/Viewers/MeshViewer";
import MRIViewerButton from "../__shared__/MRIViewerButton";
import {
    mriViewerButtonTooltip,
    RESET_VIEW,
} from "../CreateTPlan_2SegMRI/constants";
import cubeBlue from "../../assets/nav_cube_blue.svg";
import cubeLight from "../../assets/nav_cube_light.svg";
import cubeDark from "../../assets/nav_cube_dark.svg";
import filterBlue from "../../assets/filter_blue.svg";
import filterLight from "../../assets/filter_light.svg";
import filterDark from "../../assets/filter_dark.svg";
import NavCubeModal from "../__shared__/NavCubeModal";
import FilterToggle from "../__shared__/FilterToggle";
import SplitPane from "react-split-pane";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import QuestionMark from "../__shared__/IconComponents/QuestionMark";
import Tooltip from "react-bootstrap/Tooltip";
import {
    ChooseLesionSizeTooltip,
    STEP4_BUTTON_TEXT,
    STEP4_CEM_WARNING,
    STEP4_MODAL,
    STEP4_TEXT,
    STEP5_BUTTON_TEXT,
} from "./constants";
import AblationStatisticsConfirmPrompt from "../CreateTPlan_4EditMargin/AblationStatisticsConfirmPrompt";
import { getCancerLesionCoverage } from "../__shared__/Viewers/helpers";
import ButtonGroup from "../__shared__/ButtonGroup";
import SliceControl from "../__shared__/SliceControl";
import ResetViewerButton from "../__shared__/ResetViewerButton";
import WarningTriangle from "../__shared__/IconComponents/WarningTriangle";
import { getLoadingScreen, isEmpty } from "../../helpers/helpers";
import { setDebugHooks } from "../../helpers/debug/debug_hooks";

function CreateTPlan_4_5_Workflow(props) {
    // TODO: Upgrade React Router to v5.1
    // TODO: Switch out currentStepFromProps from React Router with useLocation() hook in v5.1
    const {
        // currentStepFromProps,
        authToken,
        useruuid,
        patientuuid,
        planuuid,
        visituuid,
        setCurrentTreatmentPlan,
    } = props;

    // States
    const [loadState, setLoadState] = useState(pageLoadStates.LOADING);
    const [cemWarningMsg, setCemWarningMsg] = useState(null);
    const [showCemWarningModal, setShowCemWarningModal] = useState(false);
    const [currentStep, setCurrentStep] = useState(stepBarStepNames.CANCER_AI);
    const [displayStep4Modal, setDisplayStep4Modal] = useState(false);
    const [displayStep5Modal, setDisplayStep5Modal] = useState(false);
    const [step4Requirements, setStep4Requirements] = useState({
        required: [
            {
                id: "margin-req",
                satisfied: false,
                reqText:
                    "Confirm a cancer lesion contour if Gleason Grade Group > 1",
                listIndex: 1,
            },
        ],
    });
    const [step5Requirements, setStep5Requirements] = useState({
        optionals: [
            {
                id: "target-req",
                satisfied: false,
                reqText: "Plan 0 or more tool volume placement sites",
                listIndex: 1,
            },
        ],
    });
    const [ablationSitesLoaded, setAblationSitesLoaded] = useState(false);

    // Refs
    const mriRef = useRef(null);
    const meshRef = useRef(null);
    const sliceControlRef = useRef(null);
    const targetMngrRef = useRef(null);
    const requirementsModalRef = useRef(null);

    // Models and Controllers
    const mriModel = useRef(new MRIModel(true));
    const mriController = useRef(new MRIController(mriModel.current));
    const targetSetModel = useRef(new TargetSetModel());
    const patientAnatomyModel = useRef(new PatientAnatomyModel());
    const targetSetController = useRef(
        new TargetSetController(targetSetModel.current, mriModel.current)
    );
    const patientAnatomyController = useRef(
        new PatientAnatomyController(
            patientAnatomyModel.current,
            targetSetModel.current
        )
    );

    function handleCemWarnings(plan) {
        const cemWarnings = plan.SavedData.CEMWarnings;
        if (cemWarnings) {
            const warningString = getCemWarningMsg(cemWarnings);
            if (warningString !== EMPTY_STRING) {
                setCemWarningMsg(warningString);
                setShowCemWarningModal(true);
            }
        }
    }

    function getMriUrls(plan) {
        const ids = {
            token: authToken,
            useruuid: useruuid,
            patientuuid: patientuuid,
            visituuid: visituuid,
        };

        loadMriStep4({
            ids,
            currentTreatmentPlan: plan,
            mriModel: mriModel.current,
            targetSetModel: targetSetModel.current,
            targetSetController: targetSetController.current,
            patientAnatomyController: patientAnatomyController.current,
            setAblationSitesLoaded: setAblationSitesLoaded,
            loadState: loadState,
            setLoadState: setLoadState,
        });
    }

    function getMeshUrls(plan) {
        const ids = {
            token: authToken,
            useruuid: useruuid,
            patientuuid: patientuuid,
            visituuid: visituuid,
        };

        loadBxCores({
            plan,
            patientAnatomyModel: patientAnatomyModel.current,
        });
        loadProstate({
            plan,
            ids,
            patientAnatomyModel: patientAnatomyModel.current,
            targetSetController: targetSetController.current,
            createBoundingCone: true,
            createAxes: true,
            setLoadState,
        });
        loadRois({
            plan,
            ids,
            patientAnatomyModel: patientAnatomyModel.current,
            patientAnatomyController: patientAnatomyController.current,
        });
        loadMargin({
            plan,
            ids,
            patientAnatomyModel: patientAnatomyModel.current,
            setLoadState,
        });
        loadOtherStructures({
            plan,
            ids,
            patientAnatomyModel: patientAnatomyModel.current,
        });
    }

    useEffect(() => {
        const currentPath = props.history.location.pathname;
        if (currentPath === stepPath.STEP4) {
            setCurrentStep(stepBarStepNames.CANCER_AI);
            setStepActive(TOOL_PLACEMENT_STEP, false);
            setStepComplete(TOOL_PLACEMENT_STEP, false);
            setStepActive(SUMMARY_STEP, false);
            setStepComplete(SUMMARY_STEP, false);
        } else if (currentPath === stepPath.STEP5) {
            setCurrentStep(stepBarStepNames.TOOL_PLACEMENT);
            setStepActive(SUMMARY_STEP, false);
            setStepComplete(SUMMARY_STEP, false);
        }

        if (!props.currentTreatmentPlan.SavedData.CPMURI) {
            setLoadState(pageLoadStates.CEM_FAILED);
            return;
        }

        if (loadState === pageLoadStates.COMPLETE) {
            return;
        }

        getTreatmentPlan(authToken, patientuuid, planuuid)
            .then((payload) => payload.json())
            .then((json) => {
                let plan = json.payload.plan;
                setCurrentTreatmentPlan(plan);
                handleCemWarnings(plan);
                getMriUrls(plan);
                getMeshUrls(plan);
            })
            .catch(() => {
                setLoadState(pageLoadStates.FAILED);
            });

        setDebugHooks({
            mriModel: mriModel.current,
            patientAnatomyModel: patientAnatomyModel.current,
            targetSetModel: targetSetModel.current,
        });

        // Required Tasks feature plan to be deprecated eventually, if not,
        // TODO: Fix old implementation to avoid side effects in useEffect
        verifyStep4Requirements();
        verifyStep5Requirements();
    }, [
        authToken,
        patientuuid,
        planuuid,
        setCurrentTreatmentPlan,
        props.history.location.pathname,
    ]);

    function handleMarginCreated() {
        const tPlan = props.currentTreatmentPlan.TreatmentPlan;
        if (isEmpty(tPlan) || !TreatmentPlan.hasMargins(tPlan)) {
            return;
        }

        const ids = {
            token: props.authToken,
            useruuid: props.useruuid,
            patientuuid: props.patientuuid,
            visituuid: props.visituuid,
        };

        patientAnatomyModel.current.margin = null;

        loadMargin({
            plan: props.currentTreatmentPlan,
            ids,
            patientAnatomyModel: patientAnatomyModel.current,
            setLoadState,
        });
    }

    function renderCemWarningModal() {
        if (
            cemWarningMsg &&
            showCemWarningModal &&
            currentStep === stepBarStepNames.CANCER_AI
        ) {
            return (
                <div className="fixed z-50 h-full w-full bg-black/[0.5]">
                    <DecisionAlertCard
                        id="cem-warning-modal"
                        singleBtn={true}
                        yesBtnTxt={STEP4_BUTTON_TEXT.CONFIRM}
                        yesBtnCallback={() => setShowCemWarningModal(false)}
                    >
                        <div className="p-6 text-white">
                            <div className="mb-4 flex items-center gap-2">
                                <WarningTriangle width="32" height="27" />
                                <div className="text-2xl font-bold">
                                    {STEP4_CEM_WARNING.TITLE}
                                </div>
                            </div>
                            <p className="mb-4">{cemWarningMsg}</p>
                            <p>
                                {STEP4_CEM_WARNING.FOOTER_PREFIX}{" "}
                                <a
                                    className="text-blue-600 hover:text-blue-500"
                                    href={USER_MANUAL_URL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {STEP4_CEM_WARNING.USER_MANUAL}
                                </a>
                                .
                            </p>
                        </div>
                    </DecisionAlertCard>
                </div>
            );
        }
    }

    function verifyStep4Requirements() {
        const gleasonGradeGroup = TreatmentPlan.getGleasonGradeGroup(
            props.currentTreatmentPlan.TreatmentPlan
        );
        const isMarginReqSatisfied =
            gleasonGradeGroup < 2 ||
            TreatmentPlan.hasMargins(props.currentTreatmentPlan.TreatmentPlan);

        setStep4Requirements({
            required: [
                {
                    id: "margin-req",
                    satisfied: isMarginReqSatisfied,
                    reqText:
                        "Confirm a cancer lesion contour if Gleason Grade Group > 1",
                    listIndex: 1,
                },
            ],
        });

        return isMarginReqSatisfied;
    }

    function verifyStep5Requirements() {
        const hasToolPlacementSites = TreatmentPlan.hasTargets(
            props.currentTreatmentPlan.TreatmentPlan
        );

        if (hasToolPlacementSites) {
            setStep5OptionalTaskSatisfied();
        }

        return hasToolPlacementSites;
    }

    function setStep5OptionalTaskSatisfied() {
        setStep5Requirements({
            optionals: [
                {
                    id: "target-req",
                    satisfied: true,
                    reqText: "Plan 0 or more tool volume placement sites",
                    listIndex: 1,
                },
            ],
        });
    }

    // Proceed to the next step or show RequirementsModal if requirements are not met
    function handleNextStepBtnClick() {
        switch (currentStep) {
            case stepBarStepNames.CANCER_AI:
                verifyStep4Requirements()
                    ? setDisplayStep4Modal(true)
                    : requirementsModalRef.current.openModal();
                break;
            case stepBarStepNames.TOOL_PLACEMENT:
                setDisplayStep5Modal(true);
                break;
        }
    }

    function renderCurrentStepHeader() {
        const renderTooltip = (props) => (
            <Tooltip id="rev-cancer-lesion-qm" {...props}>
                {ChooseLesionSizeTooltip}
            </Tooltip>
        );

        return (
            <div className="flex h-16 w-full items-center justify-between bg-[#262626] px-4">
                <div className="flex flex-col text-white">
                    <div className="text-xl font-semibold">
                        {currentStep === stepBarStepNames.CANCER_AI
                            ? STEP4_TEXT.REVIEW_CANCER_LESION
                            : STEP4_TEXT.VERIFY_TOOL_PLACEMENTS}
                    </div>
                    <div className="text-sm">
                        {currentStep === stepBarStepNames.CANCER_AI &&
                            STEP4_TEXT.SUB_HEADER}
                    </div>
                </div>
                {currentStep === stepBarStepNames.CANCER_AI && (
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <div>
                            <QuestionMark color="#ffffff" />
                        </div>
                    </OverlayTrigger>
                )}
            </div>
        );
    }

    function renderCurrentStep() {
        if (currentStep === stepBarStepNames.CANCER_AI) {
            return (
                <ReviewCancerLesion onMarginCreation={handleMarginCreated} />
            );
        }

        if (currentStep === stepBarStepNames.TOOL_PLACEMENT) {
            return (
                <AblationWorkflow
                    ref={targetMngrRef}
                    ablationSitesLoaded={ablationSitesLoaded}
                    mriModel={mriModel.current}
                    targetSetModel={targetSetModel.current}
                    targetSetController={targetSetController.current}
                    setStep5OptionalTaskSatisfied={
                        setStep5OptionalTaskSatisfied
                    }
                />
            );
        }
    }

    function renderStep4Modal() {
        if (displayStep4Modal) {
            return (
                // TODO: Rename DecisionAlertCard to legacy_DecisionAlertCard
                // TODO: Start new style guide component for modals with Typescript
                <div className="fixed z-50 h-full w-full bg-black/[0.5]">
                    <DecisionAlertCard
                        id="step4-modal"
                        className="click-outside-ignore"
                        yesBtnTxt={STEP4_MODAL.YES_BTN}
                        yesBtnCallback={handleProceedToStep6}
                        noBtnTxt={STEP4_MODAL.NO_BTN}
                        noBtnCallback={handleProceedToStep5}
                        clickOutsideCallbackOverride={() =>
                            setDisplayStep4Modal(false)
                        }
                    >
                        <div className="p-6 text-white">
                            <div className="mb-8 text-center text-xl font-bold text-white">
                                {STEP4_MODAL.TITLE}
                            </div>
                            <div className="text-white">{STEP4_MODAL.TEXT}</div>
                        </div>
                    </DecisionAlertCard>
                </div>
            );
        }
    }

    function renderStep5Modal() {
        const cancerLesionCoverageInstance = getCancerLesionCoverage(
            mriModel.current.meshViewer
        );
        if (displayStep5Modal) {
            return (
                <AblationStatisticsConfirmPrompt
                    noCallback={() => setDisplayStep5Modal(false)}
                    yesCallback={handleProceedToStep6}
                    targetSetModel={targetSetModel.current}
                    cancerLesionCoverageInstance={cancerLesionCoverageInstance}
                />
            );
        }
    }

    function renderViewerControlBar() {
        return (
            <div className="right-0 z-10 flex h-16 w-full items-center bg-[#262626]">
                <ButtonGroup>
                    <MRIViewerButton
                        toolTipText={mriViewerButtonTooltip.SCENE_ORIENTATION}
                        imgBlue={cubeBlue}
                        imgDark={cubeDark}
                        imgLight={cubeLight}
                        name="nav-cube"
                        alt="change MRI viewer orientation button"
                    >
                        <NavCubeModal
                            mriModel={mriModel.current}
                            resetTick={() => sliceControlRef.current.reset()}
                        />
                    </MRIViewerButton>
                    <MRIViewerButton
                        toolTipText={mriViewerButtonTooltip.FILTER}
                        imgBlue={filterBlue}
                        imgDark={filterDark}
                        imgLight={filterLight}
                        name="filter"
                        alt={mriViewerButtonTooltip.FILTER}
                    >
                        <FilterToggle
                            mriModel={mriModel.current}
                            patientAnatomyModel={patientAnatomyModel.current}
                            targetSetModel={targetSetModel.current}
                            displaySettings={{
                                axesEnabled: true,
                                mriEnabled: true,
                                noMRIOption: true,
                            }}
                        />
                    </MRIViewerButton>
                </ButtonGroup>
                <SliceControl
                    ref={sliceControlRef}
                    mriModel={mriModel.current}
                    mriController={mriController.current}
                />
                <div className="absolute right-4">
                    <ResetViewerButton
                        toolTipText={RESET_VIEW}
                        resetViewer={() =>
                            resetViewer(
                                meshRef,
                                mriRef,
                                sliceControlRef,
                                mriModel.current
                            )
                        }
                    />
                </div>
            </div>
        );
    }

    function handleProceedToPrevStep() {
        let plan = props.currentTreatmentPlan;

        switch (currentStep) {
            case stepBarStepNames.CANCER_AI:
                props.history.push(stepPath.STEP3);
                props.setStepComplete(EDIT_MARGIN_STEP, false);
                props.setStepActive(EDIT_MARGIN_STEP, false);
                props.setStepActive(BIOPSY_STEP, true);
                plan.SavedData.PlanCreationStep = BIOPSY_STEP;
                setShowCemWarningModal(false);

                break;
            case stepBarStepNames.TOOL_PLACEMENT:
                props.history.push(stepPath.STEP4);
                setCurrentStep(stepBarStepNames.CANCER_AI);
                setShowCemWarningModal(true);
                props.setStepComplete(TOOL_PLACEMENT_STEP, false);
                props.setStepActive(TOOL_PLACEMENT_STEP, false);
                props.setStepActive(EDIT_MARGIN_STEP, true);
                plan.SavedData.PlanCreationStep = EDIT_MARGIN_STEP;

                break;
        }

        // TODO: Double check if any Step 4 updates need to be added to currentTreatmentPlan object
        props.setCurrentTreatmentPlan(plan);
        updateTreatmentPlan({
            useruuid: props.useruuid,
            patientuuid: props.patientuuid,
            planuuid: props.planuuid,
            token: props.authToken,
            plan: plan,
        });
    }

    function handleProceedToStep5() {
        props.history.push(stepPath.STEP5);
        setCurrentStep(stepBarStepNames.TOOL_PLACEMENT);
        setDisplayStep4Modal(false);

        let plan = props.currentTreatmentPlan;
        props.setStepComplete(EDIT_MARGIN_STEP, true);
        props.setStepActive(EDIT_MARGIN_STEP, false);
        props.setStepActive(TOOL_PLACEMENT_STEP, true);
        plan.SavedData.PlanCreationStep = TOOL_PLACEMENT_STEP;

        props.setCurrentTreatmentPlan(plan);
        updateTreatmentPlan({
            useruuid: props.useruuid,
            patientuuid: props.patientuuid,
            planuuid: props.planuuid,
            token: props.authToken,
            plan: plan,
        });
    }

    function handleProceedToStep6() {
        props.history.push(stepPath.STEP6);
        setDisplayStep5Modal(false);

        props.setStepComplete(EDIT_MARGIN_STEP, true);
        props.setStepActive(EDIT_MARGIN_STEP, false);
        props.setStepComplete(TOOL_PLACEMENT_STEP, true);
        props.setStepActive(TOOL_PLACEMENT_STEP, false);
        props.setStepActive(SUMMARY_STEP, true);

        let plan = props.currentTreatmentPlan;
        plan.SavedData.PlanCreationStep = SUMMARY_STEP;
        props.setCurrentTreatmentPlan(plan);
        updateTreatmentPlan({
            useruuid: props.useruuid,
            patientuuid: props.patientuuid,
            planuuid: props.planuuid,
            token: props.authToken,
            plan: plan,
        });
    }

    return (
        <div className="create-plan-edit-margin-screen h-screen w-screen">
            <TopBar>
                <AvendaLogo id="create-plan-avenda-logo" to="/home" />
                <div className="create-plan-step-bar-container">
                    <div className="step-bar-prev-btn-group">
                        {currentStep === stepBarStepNames.TOOL_PLACEMENT && (
                            <PreviousStepButton
                                to={stepPath.STEP4}
                                callback={handleProceedToPrevStep}
                                isReady={loadState !== pageLoadStates.LOADING}
                            />
                        )}
                        {currentStep === stepBarStepNames.CANCER_AI && (
                            <PreviousStepButton
                                to={stepPath.STEP3}
                                callback={handleProceedToPrevStep}
                            />
                        )}
                        <StepMarkerBar />
                    </div>
                    {currentStep === stepBarStepNames.CANCER_AI && (
                        <NextStepButton
                            reqsComplete={verifyStep4Requirements}
                            reqsCompleteTxt={STEP4_BUTTON_TEXT.NEXT_STEP}
                            noLink={true}
                            to={stepPath.STEP5}
                            callback={handleNextStepBtnClick}
                        />
                    )}
                    {currentStep === stepBarStepNames.TOOL_PLACEMENT && (
                        <NextStepButton
                            reqsComplete={verifyStep5Requirements}
                            reqsCompleteTxt={STEP5_BUTTON_TEXT.NEXT_STEP}
                            noLink={true}
                            to={stepPath.STEP6}
                            callback={handleNextStepBtnClick}
                        />
                    )}
                </div>
                <ProfileMenu />
            </TopBar>
            <RequirementsModal
                id="edit-margin-pull-tab"
                ref={requirementsModalRef}
                requirements={
                    currentStep === stepBarStepNames.CANCER_AI
                        ? step4Requirements
                        : step5Requirements
                }
            />
            {getLoadingScreen(
                loadState,
                undefined,
                currentStep === stepBarStepNames.CANCER_AI
                    ? handleProceedToPrevStep
                    : undefined
            )}
            {renderCemWarningModal()}
            {renderStep4Modal()}
            {renderStep5Modal()}
            <div className="absolute top-20 flex h-full w-full">
                <div className="max-w-[30vw] basis-[30vw]">
                    {renderCurrentStepHeader()}
                    {renderCurrentStep()}
                </div>
                <div className="max-w-[70vw] basis-[70vw]">
                    {renderViewerControlBar()}
                    <SplitPane
                        className="relative"
                        defaultSize="60%"
                        minSize="0px"
                        split="vertical"
                        pane1ClassName="mri-pane"
                        pane2ClassName="mesh-pane"
                        onDragFinished={() => {
                            mriRef.current.onWindowResize();
                            meshRef.current.onWindowResize();
                        }}
                    >
                        <MRIViewer
                            ref={mriRef}
                            targetSetModel={targetSetModel.current}
                            targetSetController={targetSetController.current}
                            mriModel={mriModel.current}
                            mriController={mriController.current}
                            patientAnatomyModel={patientAnatomyModel.current}
                        />
                        <MeshViewer
                            ref={meshRef}
                            staticViewer={true}
                            displaySettings={{
                                mriEnabled: false,
                                composerEnabled: true,
                            }}
                            targetSetController={targetSetController.current}
                            targetSetModel={targetSetModel.current}
                            mriModel={mriModel.current}
                            mriController={mriController.current}
                            patientAnatomyModel={patientAnatomyModel.current}
                            patientAnatomyController={
                                patientAnatomyController.current
                            }
                            activateCoreClick={false}
                        />
                    </SplitPane>
                </div>
            </div>
        </div>
    );
}

CreateTPlan_4_5_Workflow.propTypes = {
    authToken: PropTypes.string,
    useruuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    visituuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    setCurrentTreatmentPlan: PropTypes.func,
    history: PropTypes.object,
    setStepActive: PropTypes.func,
    setStepComplete: PropTypes.func,
    currentStepFromProps: PropTypes.string,
};

const mapStateToProps = function (state) {
    return {
        authToken: state.LoginReducer.authToken,
        useruuid: state.LoginReducer.useruuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        visituuid: state.CreatePlanReducer.visituuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};
const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CreateTPlan_4_5_Workflow));
