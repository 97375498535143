import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { curveCatmullRom } from "d3-shape";
import {
    FlexibleXYPlot,
    MarkSeries,
    LineSeries,
    HorizontalGridLines,
    VerticalGridLines,
} from "react-vis";
import "../../../../node_modules/react-vis/dist/style.css";
import {
    getCrossHairs,
    getMarksScoreGradient,
    getPlotXAxis,
    getPlotYAxis,
} from "../../CreateTPlan_4EditMargin/LesionContourWorkflow/MarksScorePlot/plot_helpers";
import MarksScoreLegend from "../MarksScoreLegend";
import { EPS } from "../constants";
import { isInsideDiv } from "../helpers";

// TODO:
//  - There is overlap in structure and data in `curveTuple` ("global") state and `lesionParamsRef` ("local")
//  - Consider streamlining this by removing `lesionParamsRef` and recalculate from `curveTuple` on demand
function MarksScoreGraph(props) {
    const {
        currentTreatmentPlan,
        getNewLesionContourHandler,
        handleSetLesionParams,
        defaultProstateCoverage,
        defaultMarksScore,
        userSelectedProstateCoverage,
        userSelectedMarksScore,
    } = props;

    const [defaultMarkerSize, setDefaultMarkerSize] = useState(8);
    const [legendVisible, setLegendVisible] = useState(true);
    const [mouseDown, setMouseDown] = useState(false);
    const [plotPoints, setPlotPoints] = useState([]);
    const [curveTuple, setCurveTuple] = useState({
        marksScore: [],
        prostateCoverage: [],
        cpmThreshold: [],
        lesionVolume: [],
    });
    const [marksScoreGradient, setMarksScoreGradient] = useState(null);

    const containerRef = useRef(null);
    const lesionParamsRef = useRef({
        marksScore: 0,
        prostateCoverage: 0,
        cpmThreshold: 0,
        lesionVolume: 0,
    });

    useEffect(() => {
        const savedData = currentTreatmentPlan.SavedData;
        const newCurveTuple = {
            marksScore: savedData.MarksScoreCurve || [],
            prostateCoverage: savedData.ProstateCoverageCurve || [],
            cpmThreshold: savedData.CPMThresholdCurve || [],
            lesionVolume: savedData.LesionVolumeCurve || [],
        };
        setCurveTuple(newCurveTuple);
        setMarksScoreGradient(getMarksScoreGradient(newCurveTuple));
        setPlotPoints(getPlotPoints(newCurveTuple));

        // Attach event listeners
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("mousedown", handleMouseDown);

        // Clean up event listeners
        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [mouseDown, currentTreatmentPlan.SavedData]);

    function handleMouseUp(e) {
        if (mouseDown) {
            const divRect = containerRef.current.getBoundingClientRect();
            if (!isInsideDiv(e, divRect)) {
                getNewLesionContourHandler(
                    lesionParamsRef.current.cpmThreshold
                );
            }
            setMouseDown(false);
        }
    }

    function handleMouseDown(e) {
        const divRect = containerRef.current.getBoundingClientRect();
        if (isInsideDiv(e, divRect)) {
            setMouseDown(true);
        }
    }

    function getPlotPoints(curveTuple) {
        const points = [];
        for (let i = curveTuple.marksScore.length - 1; i >= 0; --i) {
            points.push({
                x: 100 * curveTuple.prostateCoverage[i],
                y: 100 * curveTuple.marksScore[i],
            });
        }
        return points;
    }

    function handleMouseMove(e) {
        if (!mouseDown) {
            return;
        }
        handleCurveUserInput(e);
        handleSetLesionParams(lesionParamsRef.current);
    }

    function handlePlotClick(e) {
        handleCurveUserInput(e);
        handleSetLesionParams(lesionParamsRef.current);
        getNewLesionContourHandler(lesionParamsRef.current.cpmThreshold);
    }

    function handleDefaultPointClick(e) {
        e.event.stopPropagation();
        e.event.preventDefault();

        const newLesionParams = getCurveTupleAtXValue(
            defaultProstateCoverage,
            curveTuple
        );
        lesionParamsRef.current = newLesionParams;
        handleSetLesionParams(newLesionParams);
        getNewLesionContourHandler(newLesionParams.cpmThreshold);
    }

    function handleDefaultPointMouseOver() {
        setDefaultMarkerSize(10);
    }

    function handleDefaultPointMouseOut() {
        setDefaultMarkerSize(8);
    }

    function handleCurveUserInput(e) {
        const fixedYAxisWidth = 39;
        const plotRightPaddingOffset = 8;

        const currentTargetRect = e.currentTarget.getBoundingClientRect();

        const correctedWidth =
            currentTargetRect.width - fixedYAxisWidth - plotRightPaddingOffset;

        const correctedLeftPosition = Math.min(
            Math.max(e.pageX - currentTargetRect.left - fixedYAxisWidth, 0),
            correctedWidth
        );

        const xPercent = correctedLeftPosition / correctedWidth;

        const newLesionParams = getCurveTupleAtXValue(xPercent, curveTuple);
        lesionParamsRef.current = newLesionParams;
    }

    function getCurveTupleAtXValue(x, curveTuple) {
        let tuple = {};

        if (x >= curveTuple.prostateCoverage[0]) {
            tuple = {
                marksScore: curveTuple.marksScore[0],
                prostateCoverage: curveTuple.prostateCoverage[0],
                lesionVolume: curveTuple.lesionVolume[0],
                cpmThreshold: curveTuple.cpmThreshold[0],
            };
        } else {
            for (let i = curveTuple.prostateCoverage.length - 1; i >= 0; --i) {
                if (curveTuple.prostateCoverage[parseInt(i)] >= x) {
                    const firstIdx =
                        i === curveTuple.prostateCoverage.length - 1;
                    const x0 = firstIdx
                        ? 0
                        : curveTuple.prostateCoverage[parseInt(i + 1)];
                    const x1 = curveTuple.prostateCoverage[parseInt(i)];

                    const y0cpm = firstIdx
                        ? EPS
                        : curveTuple.cpmThreshold[parseInt(i + 1)];
                    const y1cpm = curveTuple.cpmThreshold[parseInt(i)];

                    const y0ms = firstIdx
                        ? 0
                        : curveTuple.marksScore[parseInt(i + 1)];
                    const y1ms = curveTuple.marksScore[parseInt(i)];

                    const y0lv = firstIdx
                        ? 0
                        : curveTuple.lesionVolume[parseInt(i + 1)];
                    const y1lv = curveTuple.lesionVolume[parseInt(i)];

                    const xDif = x1 - x0;
                    const yCpm = !xDif
                        ? y1cpm
                        : y0cpm + ((x - x0) * (y1cpm - y0cpm)) / (x1 - x0);
                    const yMs = !xDif
                        ? y1ms
                        : y0ms + ((x - x0) * (y1ms - y0ms)) / (x1 - x0);
                    const yLv = !xDif
                        ? y1lv
                        : y0lv + ((x - x0) * (y1lv - y0lv)) / (x1 - x0);

                    tuple.prostateCoverage = x;
                    tuple.cpmThreshold = yCpm;
                    tuple.marksScore = yMs;
                    tuple.lesionVolume = yLv;

                    break;
                }
            }
        }

        return tuple;
    }

    function closeLegend() {
        setLegendVisible(false);
    }

    const defaultXPos = 100 * defaultProstateCoverage;
    const defaultYPos = 100 * defaultMarksScore;
    const userSelectedXPos = 100 * userSelectedProstateCoverage;
    const userSelectedYPos = 100 * userSelectedMarksScore;

    const configuredCurve = curveCatmullRom.alpha(0.5);

    return (
        <div
            ref={containerRef}
            id="marks-score-plot-container"
            className="r-step4-plot relative w-full pt-8"
        >
            <div className="absolute left-10 top-10 h-[calc(100%-80px)] w-[calc(100%-50px)] bg-[#262626]" />
            <FlexibleXYPlot
                onClick={handlePlotClick}
                onMouseMove={handleMouseMove}
                xDomain={[0, 100]}
                yDomain={[0, 100]}
            >
                {legendVisible && (
                    <MarksScoreLegend closeLegend={closeLegend} />
                )}
                <VerticalGridLines
                    tickValues={[25, 50, 75]}
                    style={{ stroke: "#4a4a4a" }}
                />
                <HorizontalGridLines
                    tickValues={[25, 50, 75]}
                    style={{ stroke: "#4a4a4a" }}
                />
                {marksScoreGradient}
                <LineSeries
                    color={"url(#marks-score-turbo-color-map-gradient)"}
                    curve={configuredCurve}
                    data={plotPoints}
                    strokeWidth={3}
                />
                {getCrossHairs(defaultXPos, defaultYPos, "#ffffff")}
                {getCrossHairs(userSelectedXPos, userSelectedYPos, "#f542e9")}
                <MarkSeries
                    onSeriesClick={handleDefaultPointClick}
                    onSeriesMouseOver={handleDefaultPointMouseOver}
                    onSeriesMouseOut={handleDefaultPointMouseOut}
                    data={[
                        {
                            x: defaultXPos,
                            y: defaultYPos,
                        },
                    ]}
                    fill="#ffffff"
                    size={defaultMarkerSize}
                    stroke="#b1b1b1"
                    strokeWidth={2}
                />
                <MarkSeries
                    className="cursor-grab"
                    data={[
                        {
                            x: userSelectedXPos,
                            y: userSelectedYPos,
                        },
                    ]}
                    fill="#f542e9"
                    stroke="#a82ca0"
                    strokeWidth={2}
                    size={8}
                />
                {getPlotXAxis()}
                {getPlotYAxis()}
            </FlexibleXYPlot>
        </div>
    );
}

MarksScoreGraph.propTypes = {
    currentTreatmentPlan: PropTypes.object,
    getNewLesionContourHandler: PropTypes.func,
    handleSetLesionParams: PropTypes.func,
    defaultProstateCoverage: PropTypes.number,
    defaultMarksScore: PropTypes.number,
    userSelectedProstateCoverage: PropTypes.number,
    userSelectedMarksScore: PropTypes.number,
};

export default MarksScoreGraph;
