import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import { setSortCategory, setSortOrder } from "../actions";
import ListToggle from "../../__shared__/ListToggle";
import { ASCENDING, DESCENDING } from "../../../constants";
import { patientListHeader, sortCategories } from "../constants";
import xbtn from "../../../assets/gray_x.svg";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import PatientList from "../PatientList";
import ClickOutside from "../../__shared__/ClickOutside";

class PatientHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleCloseAll: false,
        };
    }

    handleToggle(sortCategory) {
        this.setState({ toggleCloseAll: !this.state.toggleCloseAll });

        if (sortCategory === this.props.currentSortCategory) {
            this.props.setSortOrder(!this.props.currentSortOrder);
        } else {
            this.props.setSortCategory(sortCategory);
            this.props.setSortOrder(ASCENDING);
        }
    }
    render() {
        return (
            <ClickOutside onClickOutsideCallback={this.props.onClickClose}>
                <div id="patient-history-container">
                    <div className="patient-history-title">
                        <p className="display-36 black-text">Patients</p>
                        <img
                            id="patient-history-x-btn"
                            src={xbtn}
                            alt="Exit"
                            onClick={this.props.onClickClose}
                        />
                    </div>
                    <div className="patient-history-toggles">
                        <ListToggle
                            id="history-id-toggle"
                            text={patientListHeader.ID}
                            callback={() =>
                                this.handleToggle(sortCategories.ID)
                            }
                            order={this.props.currentSortOrder}
                            selected={
                                this.props.currentSortCategory ===
                                sortCategories.ID
                            }
                        />
                        <ListToggle
                            id="history-name-toggle"
                            text={patientListHeader.NAME}
                            callback={() =>
                                this.handleToggle(sortCategories.NAME)
                            }
                            order={this.props.currentSortOrder}
                            selected={
                                this.props.currentSortCategory ===
                                sortCategories.NAME
                            }
                        />
                        <ListToggle
                            id="history-date-toggle"
                            text={patientListHeader.DATE}
                            callback={() =>
                                this.handleToggle(sortCategories.DATE)
                            }
                            order={this.props.currentSortOrder}
                            selected={
                                this.props.currentSortCategory ===
                                sortCategories.DATE
                            }
                        />
                        <ListToggle
                            id="history-plan-stage-toggle"
                            text={patientListHeader.PLAN_STATUS}
                            callback={() =>
                                this.handleToggle(sortCategories.PLAN_STATUS)
                            }
                            order={this.props.currentSortOrder}
                            selected={
                                this.props.currentSortCategory ===
                                sortCategories.PLAN_STATUS
                            }
                        />
                    </div>
                    <PatientList
                        style={{ height: "calc(100% - 130px)" }}
                        patientData={this.props.patientsFetchedData}
                        loadComplete={this.props.loadComplete}
                        sortOrder={this.props.currentSortOrder}
                        sortCategory={this.props.currentSortCategory}
                        noItemsText={noItemsText}
                        toggleCloseAll={this.state.toggleCloseAll}
                        checkNotification={this.props.checkNotification}
                    />
                </div>
            </ClickOutside>
        );
    }
}

PatientHistory.propTypes = {
    //Sort Category for patients
    currentSortCategory: PropTypes.number,
    //Sort order for Category
    currentSortOrder: PropTypes.bool,
    patientsFetchedData: PropTypes.array,
    setSortOrder: PropTypes.func,
    setSortCategory: PropTypes.func,
    loadComplete: PropTypes.bool,
    onClickClose: PropTypes.func,
    checkNotification: PropTypes.func,
};

PatientHistory.defaultProps = {
    displayHistoryFlag: false,
    currentSortCategory: sortCategories.DATE,
    currentSortOrder: DESCENDING,
    patientsFetchedData: [],
};

const mapStateToProps = function (state) {
    return {
        currentSortCategory: state.HomeReducer.currentSortCategory,
        currentSortOrder: state.HomeReducer.currentSortOrder,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setSortOrder: (order) => dispatch(setSortOrder(order)),
        setSortCategory: (category) => dispatch(setSortCategory(category)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PatientHistory)
);

const noItemsText = "No patients were found in cloud storage";
