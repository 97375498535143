import React, { Component } from "react";
import "./styles.css";
import backArrow from "../../../../assets/back_arrow.svg";
import trashCan from "../../../../assets/trash_can.svg";
import trashCanBlue from "../../../../assets/trash_can_blue.svg";
import EditButton from "../../../__shared__/EditButton";
import PropTypes from "prop-types";
import { Else, If, Then, When } from "react-if";
import CheckBox from "../../../__shared__/CheckBox";
import { CHECKED, UNCHECKED } from "../../../__shared__/CheckBox/constants";
import { EMPTY_STRING } from "../../../../constants";

export default class AblationCollapsibleButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    onCollapsibleClick = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    render() {
        const { toolUUID } = this.props;

        return (
            <div className="ablation-collapsible-tool-list-alignment">
                <button
                    id="tool-profile-accordion"
                    className={`ablation-collapsible-button ${this.state.isOpen ? "button-open" : ""}`}
                    onClick={(e) => e.preventDefault()}
                >
                    <div
                        className="ablation-collapsible-tool-name"
                        onClick={this.onCollapsibleClick}
                    >
                        {this.props.toolName}
                    </div>
                    <If condition={this.props.selectedAsDefault}>
                        <Then>
                            <div className="ablation-collapsible-default-tag">
                                default
                            </div>
                            <CheckBox
                                status={CHECKED}
                                alt="default tool checkbox"
                                className="ablation-collapsible-checked-box"
                            />
                        </Then>
                        <Else>
                            <div className="ablation-collapsible-default-tag">
                                {EMPTY_STRING}
                            </div>
                            <CheckBox
                                status={UNCHECKED}
                                alt="default tool checkbox"
                                className="ablation-collapsible-checked-box"
                                onClick={() => {
                                    this.props.handleChangeDefaultTool(
                                        toolUUID
                                    );
                                }}
                            />
                        </Else>
                    </If>
                    <When condition={this.props.isCustom}>
                        <EditButton
                            id="tool-profile-edit-btn"
                            alt="edit tool button"
                            className="ablation-collapsible-edit-pencil"
                            onClick={() => {
                                this.props.handleEditTool(toolUUID);
                            }}
                        />
                        <If condition={this.props.selectedAsDefault}>
                            <Then>
                                <img
                                    src={trashCan}
                                    alt="delete button"
                                    className="ablation-collapsible-trash-can-disabled"
                                />
                            </Then>
                            <Else>
                                <img
                                    src={trashCanBlue}
                                    alt="delete button"
                                    className="ablation-collapsible-trash-can"
                                    onClick={() => {
                                        this.props.handleInitiateDelete(
                                            toolUUID
                                        );
                                    }}
                                />
                            </Else>
                        </If>
                    </When>
                    <div
                        className="ablation-collapsible-click-area"
                        onClick={this.onCollapsibleClick}
                    />
                    <img
                        src={backArrow}
                        alt="down arrow"
                        className={`ablation-collapsible-click-icon ${
                            this.state.isOpen ? "arrow-open" : "arrow-closed"
                        }`}
                        onClick={this.onCollapsibleClick}
                    />
                </button>
                {this.state.isOpen && (
                    <div className="ablation-collapsible-button-description">
                        <div>
                            Modality:
                            <br />
                            Custom:
                        </div>
                        <div>
                            {this.props.modality}
                            <br />
                            {this.props.isCustom ? "Yes" : "No"}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

AblationCollapsibleButton.propTypes = {
    toolUUID: PropTypes.string,
    toolName: PropTypes.string,
    modality: PropTypes.string,
    isCustom: PropTypes.bool,
    selectedAsDefault: PropTypes.bool,
    handleInitiateDelete: PropTypes.func,
    handleChangeDefaultTool: PropTypes.func,
    handleEditTool: PropTypes.func,
};
